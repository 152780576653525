// import React, { useState } from "react";
// import { Box, Button, TextField, Typography, Container, Avatar, useTheme } from "@mui/material";
// import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
// import userService from "../services/AuthService"; // Import the registerUser function
// import { toast, ToastContainer } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
// import { tokens } from "../theme";

// const Register = () => {
//   const theme = useTheme();
//   const colors = tokens(theme.palette.mode);

//   const [formData, setFormData] = useState({
//     username: "",
//     email: "",
//     password: "",
//     access: "client",
//   });

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({ ...formData, [name]: value });
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     try {
//       const response = await userService.registerUser(formData);
//       // console.error("Registration failed", response.message);

//       toast.error(response.message, { position: toast.POSITION.TOP_CENTER });
//       // Optionally, redirect to another page or show success message
//     } catch (error) {
//       // console.error("Registration failed", error);
//       // console.error("Registration failed", error);
//       toast.warning(error.message, { position: toast.POSITION.TOP_CENTER });
//     }
//   };

//   return (
//     <Container component="main" maxWidth="xs">
//       <ToastContainer />
//       <Box
//         sx={{
//           marginTop: 8,
//           display: "flex",
//           flexDirection: "column",
//           alignItems: "center",
//           backgroundColor: colors.primary[400],
//           padding: theme.spacing(4),
//           borderRadius: 2,
//           boxShadow: 3,
//         }}
//       >
//         <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
//           <LockOutlinedIcon />
//         </Avatar>
//         <Typography component="h1" variant="h5" color={colors.grey[100]}>
//           Register
//         </Typography>
//         <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
//           <TextField
//             margin="normal"
//             required
//             fullWidth
//             id="username"
//             label="Username"
//             name="username"
//             autoComplete="username"
//             autoFocus
//             value={formData.username}
//             onChange={handleChange}
//             InputLabelProps={{
//               style: { color: colors.grey[100] },
//             }}
//             InputProps={{
//               style: { color: colors.grey[100] },
//             }}
//           />
//           <TextField
//             margin="normal"
//             required
//             fullWidth
//             id="email"
//             label="Email Address"
//             name="email"
//             autoComplete="email"
//             value={formData.email}
//             onChange={handleChange}
//             InputLabelProps={{
//               style: { color: colors.grey[100] },
//             }}
//             InputProps={{
//               style: { color: colors.grey[100] },
//             }}
//           />
//           <TextField
//             margin="normal"
//             required
//             fullWidth
//             name="password"
//             label="Password"
//             type="password"
//             id="password"
//             autoComplete="current-password"
//             value={formData.password}
//             onChange={handleChange}
//             InputLabelProps={{
//               style: { color: colors.grey[100] },
//             }}
//             InputProps={{
//               style: { color: colors.grey[100] },
//             }}
//           />
//           <TextField
//             margin="normal"
//             required
//             fullWidth
//             name="access"
//             label="Access"
//             id="access"
//             value={formData.access}
//             onChange={handleChange}
//             InputLabelProps={{
//               style: { color: colors.grey[100] },
//             }}
//             InputProps={{
//               style: { color: colors.grey[100] },
//             }}
//           />
//           <Button
//             type="submit"
//             fullWidth
//             variant="contained"
//             sx={{
//               mt: 3,
//               mb: 2,
//               backgroundColor: colors.blueAccent[700],
//               color: colors.grey[100],
//             }}
//           >
//             Register
//           </Button>
//         </Box>
//       </Box>
//     </Container>
//   );
// };

// export default Register;
import React, { useState } from "react";
import { Box, Button, TextField, Typography, Container, Avatar, useTheme } from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import userService from "../services/AuthService"; // Import the registerUser function
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { tokens } from "../theme";

const Register = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [formData, setFormData] = useState({
    username: "",
    email: "",
    password: "",
    access: "client",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await userService.registerUser(formData);
      // Show a success toast notification
      toast.success(response.message, { position: "top-center"});
      // Optionally, redirect to another page or show success message
    } catch (error) {

      // Show an error toast notification
      toast.error(error.data, { position: "top-center" });
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <ToastContainer />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          backgroundColor: colors.primary[400],
          padding: theme.spacing(4),
          borderRadius: 2,
          boxShadow: 3,
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5" color={colors.grey[100]}>
          Register
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="username"
            label="Username"
            name="username"
            autoComplete="username"
            autoFocus
            value={formData.username}
            onChange={handleChange}
            InputLabelProps={{
              style: { color: colors.grey[100] },
            }}
            InputProps={{
              style: { color: colors.grey[100] },
            }}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            value={formData.email}
            onChange={handleChange}
            InputLabelProps={{
              style: { color: colors.grey[100] },
            }}
            InputProps={{
              style: { color: colors.grey[100] },
            }}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            value={formData.password}
            onChange={handleChange}
            InputLabelProps={{
              style: { color: colors.grey[100] },
            }}
            InputProps={{
              style: { color: colors.grey[100] },
            }}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="access"
            label="Access"
            id="access"
            value={formData.access}
            onChange={handleChange}
            InputLabelProps={{
              style: { color: colors.grey[100] },
            }}
            InputProps={{
              style: { color: colors.grey[100] },
            }}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{
              mt: 3,
              mb: 2,
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
            }}
          >
            Register
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default Register;
