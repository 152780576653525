// import React from 'react';

import axios from "axios";

const studyapi = axios.create({
  // baseURL: process.env.REACT_APP_API_URL_APISSWMR || "http://localhost:9000/api/"
  baseURL: process.env.REACT_APP_API_URL_APISSWMR || "https://api-surface-study.windmyroof.com/api/"
  ,
  withCredentials: true,
});

studyapi.interceptors.request.use((request) => {
  const token = localStorage.getItem("access_token");
  if (token) {
    request.headers["Authorization"] = `Bearer ${token}`;
  }
  return request;
});

studyapi.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    if (
      error.response &&
      error.response.status === 401 &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true;
      try {
        const response = await studyapi.get("users/auth/refresh");
        const { access_token } = response.data;
        localStorage.setItem("access_token", access_token);
        return studyapi(originalRequest);
      } catch (refreshError) {
        localStorage.removeItem("access_token");
        window.location.href = "/";
        return Promise.reject(refreshError);
      }
    }
    return Promise.reject(error);
  }
);

export default studyapi;

// import axios from "axios";

// const studyapi = axios.create({
//   baseURL: process.env.REACT_APP_API_URL_STUDY || "http://localhost:9000/api/", 
//   withCredentials: true,
// });

// studyapi.interceptors.request.use((request) => {
//   const token = localStorage.getItem("access_token");
//   if (token) {
//     request.headers["Authorization"] = `Bearer ${token}`;
//   }
//   return request;
// });

// studyapi.interceptors.response.use(
//   (response) => response,
//   async (error) => {
//     const originalRequest = error.config;
//     if (
//       error.response &&
//       error.response.status === 401 &&
//       !originalRequest._retry
//     ) {
//       originalRequest._retry = true;
//       try {
//         const response = await studyapi.get("users/auth/refresh");
//         const { access_token } = response.data;
//         localStorage.setItem("access_token", access_token);
//         originalRequest.headers["Authorization"] = `Bearer ${access_token}`;
//         return studyapi(originalRequest);
//       } catch (refreshError) {
//         localStorage.removeItem("access_token");
//         window.location.href = "/";
//         return Promise.reject(refreshError);
//       }
//     }
//     // Handle other errors
//     return Promise.reject(error);
//   }
// );

// export default studyapi;
