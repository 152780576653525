// import React from 'react';
import { toast } from "react-toastify";
// const API_BASE_URL = "http://localhost:8000/api/";
// const API_BASE_URL = "https://backendstudy.windmyroof.com/api/";
import api from "./api.jsx"; // Adjust the import path based on your project structure

// const api = axios.create({
//   baseURL: API_BASE_URL,
// });

const commonArgs = {
  nom_du_site: "",
  nom_du_cient: "",
  adresse: "",
  latitude: "",
  longitude: "",
  start_year: "",
  end_year: "",
  firsts_name: "",
  last_name: "",
  access: "",
  user_id: "",
};

export const createPost = async (customArgs = {}) => {
  const args = { ...commonArgs, ...customArgs };
  // teak
  try {
    const response = await api.post("data/", args);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const createPostEvalution = async ({
  bat_hght,
  z_zero,
  bat_orientatn,
  coeff,
  compny,
  locatn,
  etudes_id,
  isevlution,
  isNewEtude,
  ...customArgs
}) => {
  const evalutionArgs = {
    bat_hght,
    z_zero,
    bat_orientatn,
    coeff,
    compny,
    locatn,
    etudes_id,
    isNewEtude,
    isevlution,
  };

  const args = { ...commonArgs, ...evalutionArgs, ...customArgs };
  try {
    const response = await api.post("data-culcule/", args);
    return response.data;
  } catch (error) {
    // console.error("Error creating post evaluation:", error);
    toast.warning(error.response.data.message, {
      position: toast.POSITION.TOP_CENTER,
    });
    throw error;
  }
};

export const SaveAllEvaluation = async ({
  nom_du_site,
  nom_du_cient,
  adresse,
  latitude,
  longitude,
  start_year,
  end_year,
  facade_de_batiment_01_e,
  facade_de_batiment_02_e,
  facade_de_batiment_03_e,
  facade_de_batiment_04_e,
  bat_hght,
  z_zero,
  bat_orientatn,
  coeff,
  compny,
  locatn,
  etudes_id,
  isSaveEvlution,
  EvauationdDEtails,
  isAsNewEtude,
}) => {
  try {
    const response = await api.post("data-culcule/", {
      nom_du_site,
      nom_du_cient,
      adresse,
      latitude,
      longitude,
      start_year,
      end_year,
      facade_de_batiment_01_e,
      facade_de_batiment_02_e,
      facade_de_batiment_03_e,
      facade_de_batiment_04_e,
      bat_hght,
      z_zero,
      bat_orientatn,
      coeff,
      compny,
      locatn,
      etudes_id,
      isSaveEvlution,
      EvauationdDEtails,
      isAsNewEtude,
    });
    return response.data;
  } catch (error) {
    toast.warning(error.response.data.message, {
      position: toast.POSITION.TOP_CENTER,
    });

    throw error;
  }
};

// Start the analysis
export const StartTheAnalysis = async ({
  nom_du_site,
  nom_du_cient,
  adresse,
  latitude,
  longitude,
  start_year,
  end_year,
  facade_de_batiment_01_e,
  facade_de_batiment_02_e,
  facade_de_batiment_03_e,
  facade_de_batiment_04_e,
  bat_hght,
  z_zero,
  bat_orientatn,
  coeff,
  compny,
  locatn,
  etudes_id,
  isAnalysis,
  process_year,
  process_month,
  process_hour,
  isAsNewEtude,
}) => {
  try {
    const response = await api.post("data-culcule/", {
      nom_du_site,
      nom_du_cient,
      adresse,
      latitude,
      longitude,
      start_year,
      end_year,
      facade_de_batiment_01_e,
      facade_de_batiment_02_e,
      facade_de_batiment_03_e,
      facade_de_batiment_04_e,
      bat_hght,
      z_zero,
      bat_orientatn,
      coeff,
      compny,
      locatn,
      etudes_id,
      isAnalysis,
      process_year,
      process_month,
      process_hour,
      isAsNewEtude,
    });
    return response.data;
  } catch (error) {
    toast.warning(error.response.data.message, {
      position: toast.POSITION.TOP_CENTER,
    });

    throw error;
  }
};

export const createEvalution = async (id) => {
  try {
    const response = await api.get(`data-evaluation/${id}`);
    return response.data;
  } catch (error) {
    throw error; // Rethrow the error or handle it as needed in the calling code
  }
};

export const getPostsPaginated = async (page) => {
  try {
    const response = await api.get("get-data/", {
      params: { _page: page, _sort: "title", _limit: 2 },
    });

    const hasNext = page * 2 <= parseInt(response.headers["x-total-count"], 10);
    return {
      nextPage: hasNext ? page + 1 : undefined,
      previousPage: page > 1 ? page - 1 : undefined,
      posts: response.data.data,
    };
  } catch (error) {
    throw error;
  }
};

export const deleteEtude = async (etudeId) => {
  try {
    const response = await api.delete(`etudes/${etudeId}/delete/`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    console.error("Delete error:", error.response.data);
    throw error.response.data;
  }
};

export const fetchPostDetails = async (etudeId) => {
  try {
    const response = await api.get(`etudes/${etudeId}/`);
    const data = response.data; // Access JSON data using response.data
    return data;
  } catch (error) {
    return null;
  }
};

export const downloadZipFile = async (etudeId) => {
  try {
    const response = await api.get(`etudes/${etudeId}/download-zip/`, {
      responseType: "blob",
    });

    if (response.status === 200) {
      const url = window.URL.createObjectURL(response.data);
      const link = document.createElement("a");
      link.href = url;
      link.download = `etude_${etudeId}.zip`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
      return response;
    } else {
      console.error("Download failed with status:", response.status);
      return null;
    }
  } catch (error) {
    console.error("An error occurred:", error);
    if (error.response) {
      console.error("Response data:", error.response.data);
      console.error("Response status:", error.response.status);
    } else if (error.request) {
      console.error("No response received from server");
    } else {
      console.error("Error setting up the request:", error.message);
    }
    throw error;
  }
};

export const getUserFromLocalStorage = () => {
  const userString = localStorage.getItem("user");
  return userString ? JSON.parse(userString) : null;
};

export const getEtudByUserId = async (userId) => {
  try {
    const response = await api.get(`/users/${userId}/etudes/`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateArchivageStatus = async ({ etudeId, archivageValue }) => {
  try {
    const response = await api.post(`etudes/${etudeId}/update-archivage/`, {
      archivage: archivageValue,
    });
    toast.success(response.data.message);
    return response.data;
  } catch (error) {
    throw error.response.data; // Rethrow the error response data for handling in onError
  }
};


export const uploadCsvFile = async (file, additionalParams) => {
  // console.log("this is the file, additionalParams ",file, additionalParams)

  const formData = new FormData();
  formData.append('file', file);

  Object.keys(additionalParams).forEach(key => {
    formData.append(key, additionalParams[key]);
  });

  return await api.post('upload-etudes-csv/', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};




