import { Box, Button, Grid, Typography, Paper } from "@mui/material";
import { styled } from "@mui/material/styles";
import motomo from "../../assets/matomo.png";
import admintrackmyroof from "../../assets/admintrackmyroof.png";
import clienttrakmyroof from "../../assets/clienttrakmyroof.png";
import proxmox from "../../assets/proxmox.png";
import studywindmyroof from "../../assets/studywindmyroof.png";
import portainer from "../../assets/portainer.png";
import api_trackmyroof from "../../assets/api_trackmyroof.png";
import { useTheme } from "@emotion/react";
// import { useContext } from "react";
import { tokens } from "../../theme";

const CENTRAL = () => {
  const handleButtonClick = (url) => {
    window.location.href = url;
  };
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  // const colorMode = useContext(ColorModeContext);

  const StyledPaper = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(1), // Reduced padding
    textAlign: "center",
    color: theme.palette.text.secondary,
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[3],
    backgroundColor: colors.primary[400],
    border: '2px solid #0087CB',
    transition: 'box-shadow 0.3s ease-in-out, transform 0.3s ease-in-out',
    '&:hover': {
      boxShadow: theme.shadows[6],
      transform: 'translateY(-3px)',
      backgroundColor: '#f5f5f5',
      border: '2px solid #4CAF50',
    },
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
  }));

  const StyledButton = styled(Button)(({ theme }) => ({
    margin: theme.spacing(0.5), // Reduced margin
    width: "100%",
    padding: theme.spacing(0.5), // Reduced padding
    justifyContent: 'flex-start',
    border: '2px solid #2559A5',
  }));

  const Image = styled('img')({
    height: '40px', // Reduced height
    marginRight: '8px', // Reduced space between image and text
  });

  const authOptions = [
    {
      label: "surveillance.windmyroof.com",
      iconSrc: motomo,
      url: "https://surveillance.windmyroof.com",
      description: "Platform pour surveillance and monitoring.",
      documentationUrl: "https://matomo.org/faq/on-premise/installing-matomo/",
    },
    {
      label: "study.windmyroof.com",
      iconSrc: studywindmyroof,
      url: "https://study.windmyroof.com/",
      description: "Platform pour surveillance and monitoring.",
      // documentationUrl: "https://matomo.org/faq/on-premise/installing-matomo/",
    },
    {
      label: "admin.trackmyroof.com",
      iconSrc: admintrackmyroof,
      url: "https://admin.trackmyroof.com",
      description: "Platform pour surveillance and monitoring.",
      // documentationUrl: "https://matomo.org/faq/on-premise/installing-matomo/",
    },
    {
      label: "trackmyroof.com",
      iconSrc: clienttrakmyroof,
      url: "https://trackmyroof.com",
      description: "Platform pour surveillance and monitoring.",
      // documentationUrl: "https://matomo.org/faq/on-premise/installing-matomo/",
    },
  ];

  const techplatform = [
    {
      label: "monitoring.windmyroof.com",
      iconSrc: portainer,
      url: "https://monitoring.windmyroof.com",
      description:
        "Platform pour créer et de gérer facilement des conteneurs dans Docker, Docker Swarm, Kubernetes et Azure ACI.",
      documentationUrl: "https://docs.portainer.io/",
    },
    {
      label: "prox.windmyroof.com",
      iconSrc: proxmox,
      url: "https://prox.windmyroof.com:8006/",
      description:
        "Platform pour Proxmox Virtual Environnement est une solution de virtualisation libre basée sur l'hyperviseur Linux KVM.",
      documentationUrl:
        "https://www.proxmox.com/en/services/support#support-resources/",
    },
  ];

  const apiServices = [
    {
      label: "api.trackmyroof.com",
      iconSrc: api_trackmyroof,
      url: "https://api.trackmyroof.com/docs.trackmyroof.com",
      description: "Platform pour surveillance and monitoring.",
      documentationUrl: "https://swagger.io/solutions/api-development/",
    },
    {
      label: "backendstudy.windmyroof.com",
      iconSrc: api_trackmyroof,
      url: "https://backendstudy.windmyroof.com/admin/login/?next=/admin/",
      description: "Platform pour surveillance and monitoring.",
      documentationUrl: "https://swagger.io/solutions/api-development/",
    },
  ];

  const microServices = [
    {
      label: "apidata.trackmyroof.com",
      iconSrc: motomo,
      url: "http://apidata.trackmyroof.com",
      description: "Platform pour surveillance and monitoring.",
      documentationUrl: "https://swagger.io/solutions/api-development/",
    },
    {
      label: "gateway.windmyroof.com",
      iconSrc: motomo,
      url: "https://gateway.windmyroof.com/api/docs/",
      description: "Platform pour surveillance and monitoring.",
      documentationUrl: "https://matomo.org/faq/on-premise/installing-matomo/",
    },
    {
      label: "gateway.windmyroof.com",
      iconSrc: motomo,
      url: "https://gateway.windmyroof.com/api/docs/",
      description: "Platform pour surveillance and monitoring.",
      documentationUrl: "https://matomo.org/faq/on-premise/installing-matomo/",
    },
  ];

  return (
    <Box m="20px">
      <Typography variant="h3">Users Services</Typography>
      <Grid container spacing={2}>
        {authOptions.map((service, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <StyledPaper>
              <Typography variant="subtitle1">{service.label}</Typography>
              <StyledButton
                variant="outlined"
                onClick={() => handleButtonClick(service.url)}
              >
                <Image src={service.iconSrc} alt={service.label} />
              </StyledButton>
              <Typography variant="body2">{service.description}</Typography>
              <a
                href={service.documentationUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                Documentation
              </a>
            </StyledPaper>
          </Grid>
        ))}
      </Grid>

      <Typography variant="h3">DevOps Services</Typography>
      <Grid container spacing={2}>
        {techplatform.map((service, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <StyledPaper>
              <Typography variant="subtitle1">{service.label}</Typography>
              <StyledButton
                variant="outlined"
                onClick={() => handleButtonClick(service.url)}
              >
                <Image src={service.iconSrc} alt={service.label} />
              </StyledButton>
              <Typography variant="body2">{service.description}</Typography>
              <a
                href={service.documentationUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                Documentation
              </a>
            </StyledPaper>
          </Grid>
        ))}
      </Grid>

      <Typography variant="h3">API Services</Typography>
      <Grid container spacing={2}>
        {apiServices.map((service, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <StyledPaper>
              <Typography variant="subtitle1">{service.label}</Typography>
              <StyledButton
                variant="outlined"
                onClick={() => handleButtonClick(service.url)}
              >
                <Image src={service.iconSrc} alt={service.label} />
              </StyledButton>
              <Typography variant="body2">{service.description}</Typography>
              <a
                href={service.documentationUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                Documentation
              </a>
            </StyledPaper>
          </Grid>
        ))}
      </Grid>

      <Typography variant="h3" style={{ marginTop: "20px" }}>
        Microservices
      </Typography>
      <Grid container spacing={2}>
        {microServices.map((service, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <StyledPaper>
              <Typography variant="subtitle1">{service.label}</Typography>
              <StyledButton
                variant="outlined"
                onClick={() => handleButtonClick(service.url)}
              >
                <Image src={service.iconSrc} alt={service.label} />
              </StyledButton>
              <Typography variant="body2">{service.description}</Typography>
              <a
                href={service.documentationUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                Documentation
              </a>
            </StyledPaper>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default CENTRAL;
