// import React, { useState } from "react";
// import Avatar from "@mui/material/Avatar";
// import Button from "@mui/material/Button";
// import { Box, Container, CssBaseline, TextField, FormControlLabel, Checkbox, Grid, Link, Typography } from "@mui/material";
// import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
// import userService from "../../services/AuthService";
// import { ToastContainer,  Slide } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
// import { useAuth } from "../../services/AuthContext";
// import { useTheme } from "@emotion/react";
// import { tokens } from "../../theme";
// import { toast } from 'react-toastify';

// const SignIn = () => {
//   const [email, setEmail] = useState("");
//   const [password, setPassword] = useState("");
//   const { login } = useAuth();
//   const theme = useTheme();
//   const colors = tokens(theme.palette.mode);

//   const handleClick = async (event) => {
//     event.preventDefault();
//     try {
//       const response = await userService.login(email, password);
//       localStorage.setItem("access_token", response.data.access_token);
//       localStorage.setItem("refresh_token", response.data.refresh_token);
//       localStorage.setItem("user", JSON.stringify(response.data.user));

//       login(response.data.access_token);

//       toast.success(response.data.message, {
//         position: toast.POSITION.TOP_CENTER,
//         autoClose: 5000,
//         transition: Slide,
//       });
//       window.location.href = "/";
//     } catch (error) {
//       toast.error(error.message, {
//         position: toast.POSITION.TOP_CENTER,
//         autoClose: 5000,
//         transition: Slide,
//       });
//     }
//   };

//   return (
//     <Box>
//       <Container component="main" maxWidth="xs">
//         <CssBaseline />
//         <ToastContainer />
//         <Box
//           sx={{
//             marginTop: 8,
//             display: "flex",
//             flexDirection: "column",
//             alignItems: "center",
//           }}
//         >
//           <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
//             <LockOutlinedIcon />
//           </Avatar>
//           <Typography component="h1" variant="h5">
//             Sign in
//           </Typography>
//           <Box
//             component="form"
//             onSubmit={handleClick}
//             noValidate
//             sx={{ mt: 1 }}
//           >
//             <TextField
//               margin="normal"
//               required
//               fullWidth
//               id="email"
//               label="Email Address"
//               name="email"
//               autoComplete="email"
//               autoFocus
//               value={email}
//               onChange={(e) => setEmail(e.target.value)}
//             />
//             <TextField
//               margin="normal"
//               required
//               fullWidth
//               name="password"
//               label="Password"
//               type="password"
//               id="password"
//               autoComplete="current-password"
//               value={password}
//               onChange={(e) => setPassword(e.target.value)}
//             />
//             <FormControlLabel
//               control={<Checkbox value="remember" color="primary" />}
//               label="Remember me"
//             />
//             <Button
//               type="submit"
//               fullWidth
//               variant="contained"
//               sx={{ mt: 3, mb: 2 }}
//               color="secondary"
//             >
//               Sign In
//             </Button>
//             <Grid container>
//               <Grid item xs>
//                 <Link variant="body3" fontWeight="bold" sx={{ color: colors.grey[100] }} href="#">
//                   Forgot password?
//                 </Link>
//               </Grid>
//               <Grid item>
//                 <Link variant="body3" fontWeight="bold" sx={{ color: colors.grey[100] }} href="/contact">
//                   {"Don't have an account? Sign Up"}
//                 </Link>
//               </Grid>
//             </Grid>
//           </Box>
//         </Box>
//       </Container>
//     </Box>
//   );
// };

// export default SignIn;

import React, { useState } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import { Box, Container, CssBaseline, TextField, FormControlLabel, Checkbox, Grid, Link, Typography } from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import userService from "../../services/AuthService";
import { ToastContainer, Slide, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import { useAuth } from "../../services/AuthContext";
import { useTheme } from "@emotion/react";
import { tokens } from "../../theme";

// import '../../assets/toastStyles.css';

const SignIn = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  // const { login } = useAuth();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const handleClick = async (event) => {
    event.preventDefault();
    try {
      const response = await userService.login(email, password);
      localStorage.setItem("access_token", response.data.access_token);
      localStorage.setItem("refresh_token", response.data.refresh_token);
      localStorage.setItem("user", JSON.stringify(response.data.user));


     toast.success(response.data.message, {
      // position: toast.POSITION.TOP_CENTER,
      position: "top-left",
      // autoClose: 5000,
      transition: Slide,
    });

    toast.success(response.data.message, {
      position: "top-left",
      autoClose: 5000,//   transition: Slide,
    });
      window.location.href = "/";
    } catch (error) {
      console.error(error, "Tat is the rerro")
     toast.error(error.message, {
        position: "top-center",
        autoClose: 5000,
        transition: Slide,

});

    }
  };

  return (
    <Box>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover 
        />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Box
            component="form"
            onSubmit={handleClick}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              color="secondary"
            >
              Sign In
            </Button>
            <Grid container>
              <Grid item xs>
                <Link variant="body3" fontWeight="bold" sx={{ color: colors.grey[100] }} href="#">
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <Link variant="body3" fontWeight="bold" sx={{ color: colors.grey[100] }} href="/contact">
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default SignIn;

