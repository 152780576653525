// // import React from 'react';
// import React, { useState } from "react";
// import { useMutation, useQuery } from "react-query";
// import { ToastContainer, toast } from "react-toastify";
// import {
//   Box,
//   Button,
//   CircularProgress,
//   Typography,
//   useTheme,
//   Modal,
//   Alert,
// } from "@mui/material";
// import CloudUploadIcon from "@mui/icons-material/CloudUpload";
// import { tokens } from "../../theme";
// import Header from "../../components/Header";
// import { uploadgeoJsonFileTerritorial, getCities } from "../../services/cityService";
// import { Formik, Form } from "formik";
// import * as yup from "yup";
// import CityTable from "../../components/CityTable";
// import AddCity from "../../components/AddCity";
// import UpdateUserCity from "../../components/UpdateUserCity";

// const Territorial = () => {
//   const theme = useTheme();
//   const colors = tokens(theme.palette.mode);

//   const [selectedFile, setSelectedFile] = useState(null);
//   const [isUploading, setIsUploading] = useState(false);
//   const [progress, setProgress] = useState(0);
//   const [open, setOpen] = useState(false);
//   const [addCityOpen, setAddCityOpen] = useState(false);
//   const [updateUserCityOpen, setUpdateUserCityOpen] = useState(false);
//   const [selectedCityId, setSelectedCityId] = useState('');

//   const {  error, isLoading, refetch } = useQuery(["cities"], getCities);

//   const mutation = useMutation(({ file, city_id }) => uploadgeoJsonFileTerritorial(file, city_id), {
    
//     onSuccess: () => {
//       toast.success("File uploaded successfully.");
//       setSelectedFile(null);
//       setIsUploading(false);
//       setProgress(0);
//       setOpen(false);
//     },
//     onError: (error) => {
//       toast.error(`Upload failed: ${error.response.data.error}`);
//       setIsUploading(false);
//       setProgress(0);
//     },
//   });

//   const handleFileChange = (event) => {
//     setSelectedFile(event.target.files[0]);
//   };

//   // const handleUpload = () => {
//   //   console.log(`Upload`, selectedCityId)
//   //   if (!selectedFile) {
//   //     toast.warning("Please select a file to upload.");
//   //     return;
//   //   }
//   //   console.log("selectedCityId", selectedCityId);
//   //   if (selectedCityId) {
//   //     toast.warning("Please select a city.");
//   //     return;
//   //   }
//   const handleUpload = () => {
//     console.log(`Upload`, selectedCityId);
//     if (!selectedFile) {
//       toast.warning("Please select a file to upload.");
//       return;
//     }
//     if (!selectedCityId) {
//       toast.warning("Please select a city.");
//       return;
//     }
  
//     setIsUploading(true);
//     const interval = setInterval(() => {
//       setProgress((oldProgress) => {
//         if (oldProgress === 100) {
//           clearInterval(interval);
//           return 100;
//         }
//         return Math.min(oldProgress + 10, 100);
//       });
//     }, 200);
//     console.log(`Mutating with file: ${selectedFile.name} and city_id: ${selectedCityId}`);
    
//     mutation.mutate({ file: selectedFile, city_id: selectedCityId });
//   };
  
  

//   const handleOpen = () => setOpen(true);
//   const handleClose = () => setOpen(false);
//   const handleAddCityOpen = () => setAddCityOpen(true);
//   const handleAddCityClose = () => setAddCityOpen(false);
//   const handleUpdateUserCityOpen = () => setUpdateUserCityOpen(true);
//   const handleUpdateUserCityClose = () => setUpdateUserCityOpen(false);
//   // const handleSelectCity = (city_id) => setSelectedCityId(city_id);

//   const handleSelectCity = (city_id) => {
//     console.log("Selected city_id:", city_id); // Log the selected city ID
//     setSelectedCityId(city_id);
//   };


//   return (
//     <Box m="20px">
//       <ToastContainer />
//       <Header title="Launch Territorial Etudes" subtitle="Launch Territorial News Etudes" />
//       <Box 
//         display="flex" 
//         flexDirection={{ xs: 'column', sm: 'row' }} 
//         justifyContent="space-between" 
//         alignItems="center" 
//         height="10vh" 
//         gap={2}
//         mb={2}
//       >
//         <Button
//           variant="contained"
//           color="primary"
//           onClick={handleOpen}
//           startIcon={<CloudUploadIcon />}
//           sx={{
//             backgroundColor: colors.blueAccent[700],
//             color: colors.grey[100],
//             fontSize: "14px",
//             fontWeight: "bold",
//             padding: "10px 20px",
//             width: { xs: '100%', sm: 'auto' },
//           }}
//         >
//           Launch Territorial Etudes
//         </Button>
//         <Button
//           variant="contained"
//           color="secondary"
//           onClick={handleAddCityOpen}
//           startIcon={<CloudUploadIcon />}
//           sx={{
//             backgroundColor: colors.blueAccent[700],
//             color: colors.grey[100],
//             fontSize: "14px",
//             fontWeight: "bold",
//             padding: "10px 20px",
//             width: { xs: '100%', sm: 'auto' },
//           }}
//         >
//           Add City
//         </Button>
//         <Button
//           variant="contained"
//           color="secondary"
//           onClick={handleUpdateUserCityOpen}
//           startIcon={<CloudUploadIcon />}
//           sx={{
//             backgroundColor: colors.blueAccent[700],
//             color: colors.grey[100],
//             fontSize: "14px",
//             fontWeight: "bold",
//             padding: "10px 20px",
//             width: { xs: '100%', sm: 'auto' },
//           }}
//         >
//           Update User City
//         </Button>
//       </Box>
      
//       <Modal open={open} onClose={handleClose}>
//         <Box
//           sx={{
//             position: 'absolute',
//             top: '50%',
//             left: '50%',
//             transform: 'translate(-50%, -50%)',
//             width: 400,
//             bgcolor: 'background.paper',
//             boxShadow: 24,
//             p: 4,
//             borderRadius: 2,
//           }}
//         >
//           <Typography variant="h6" mb={2}>
//             Upload GeoJSON File
//           </Typography>
//           <Formik
//             initialValues={{ file: null }}
//             validationSchema={yup.object().shape({
//               file: yup.mixed().required("A file is required"),
//             })}
//             onSubmit={handleUpload}
//           >
//             {({ setFieldValue }) => (
//               <Form>
//                 <Box
//                   display="flex"
//                   flexDirection="column"
//                   alignItems="center"
//                   justifyContent="center"
//                   border={`2px dashed ${colors.primary[400]}`}
//                   borderRadius="8px"
//                   p={2}
//                   textAlign="center"
//                   mb={3}
//                 >
//                   <CloudUploadIcon sx={{ fontSize: 48, color: colors.primary[400] }} /> 
//                   <Typography variant="body1" mt={2}>
//                     Drag files here or <label htmlFor="file-input" style={{ color: colors.grey[100], cursor: "pointer" }}>browse</label>
//                   </Typography>
//                   <input
//                     type="file"
//                     onChange={(event) => {
//                       handleFileChange(event);
//                       setFieldValue("file", event.currentTarget.files[0]);
//                     }}
//                     style={{ display: "none" }}
//                     id="file-input"
//                   />
//                   {selectedFile && (
//                     <Typography variant="body2" mt={2}>
//                       Selected file: {selectedFile.name}
//                     </Typography>
//                   )}
//                 </Box>
//                 <Box display="flex" justifyContent="center" mt={2}>
//                   <Button
//                     variant="contained"
//                     color="primary"
//                     onClick={handleUpload}
//                     disabled={!selectedFile || isUploading}
//                     startIcon={isUploading ? <CircularProgress size={20} /> : null}
//                   >
//                     {isUploading ? "Uploading..." : "Upload File"}
//                   </Button>
//                 </Box>
//                 {isUploading && (
//                   <Box width="100%" mt={2}>
//                     <Typography variant="body2" color="textSecondary">
//                       Uploading {progress}%...
//                     </Typography>
//                     <Box height={10} bgcolor={colors.primary[200]} borderRadius="5px">
//                       <Box
//                         height="100%"
//                         bgcolor={colors.primary[600]}
//                         width={`${progress}%`}
//                         borderRadius="5px"
//                       />
//                     </Box>
//                   </Box>
//                 )}
//               </Form>
//             )}
//           </Formik>
//         </Box>
//       </Modal>

      // <Modal open={addCityOpen} onClose={handleAddCityClose}>
      //   <Box
      //     sx={{
      //       position: 'absolute',
      //       top: '50%',
      //       left: '50%',
      //       transform: 'translate(-50%, -50%)',
      //       width: 400,
      //       bgcolor: 'background.paper',
      //       boxShadow: 24,
      //       p: 4,
      //       borderRadius: 2,
      //     }}
      //   >
      //     <AddCity onClose={handleAddCityClose} refetch={refetch} />
      //   </Box>
      // </Modal>

//       <Modal open={updateUserCityOpen} onClose={handleUpdateUserCityClose}>
//         <Box
//           sx={{
//             position: 'absolute',
//             top: '50%',
//             left: '50%',
//             transform: 'translate(-50%, -50%)',
//             width: 800,  // Adjusted width for the UpdateUserCity modal
//             bgcolor: 'background.paper',
//             boxShadow: 24,
//             p: 4,
//             borderRadius: 2,
//           }}
//         >
//           <UpdateUserCity />
//         </Box>
//       </Modal>

//       {isLoading ? (
//         <Box display="flex" justifyContent="center">
//           <CircularProgress />
//         </Box>
//       ) : error ? (
//         <Alert severity="error">An error occurred: {error.message}</Alert>
//       ) : (
//         <Box
//           m="40px 0 0 0"
//           height="75vh"
//           sx={{
//             "& .MuiDataGrid-root": {
//               border: "none",
//             },
//             "& .MuiDataGrid-cell": {
//               borderBottom: "none",
//             },
//             "& .name-column--cell": {
//               color: colors.greenAccent[300],
//             },
//             "& .MuiDataGrid-columnHeaders": {
//               backgroundColor: colors.blueAccent[700],
//               borderBottom: "none",
//             },
//             "& .MuiDataGrid-virtualScroller": {
//               backgroundColor: colors.primary[400],
//             },
//             "& .MuiDataGrid-footerContainer": {
//               borderTop: "none",
//               backgroundColor: colors.blueAccent[700],
//             },
//             "& .MuiCheckbox-root": {
//               color: `${colors.greenAccent[200]} !important`,
//             },
//           }}
//         >
//           <CityTable onSelectCity={handleSelectCity} />
//         </Box>
//       )}
//     </Box>
//   );
// };

// Territorial.jsx
import React, { useState } from "react";
import { useMutation, useQuery } from "react-query";
import { ToastContainer, toast } from "react-toastify";
import {
  Box,
  Button,
  CircularProgress,
  Typography,
  useTheme,
  Modal,
  Alert,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { uploadgeoJsonFileTerritorial, getCities } from "../../services/cityService";
import { Formik, Form } from "formik";
import * as yup from "yup";
// import CityTable from "../../components/CityTable";
import AddCity from "../../components/AddCity";
import UpdateUserCity from "../../components/UpdateUserCity";

const Territorial = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [selectedFile, setSelectedFile] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [open, setOpen] = useState(false);
  const [selectedCityId, setSelectedCityId] = useState('');
  const [addCityOpen, setAddCityOpen] = useState(false);
  const [updateUserCityOpen, setUpdateUserCityOpen] = useState(false);


  const {refetch, data: cities, error, isLoading } = useQuery(["cities"], getCities);

  const mutation = useMutation(({ file, city_id }) => uploadgeoJsonFileTerritorial(file, city_id), {
    onSuccess: () => {
      toast.success("File uploaded successfully.");
      setSelectedFile(null);
      setIsUploading(false);
      setProgress(0);
      setOpen(false);
    },
    onError: (error) => {
      toast.error(`Upload failed: ${error.response.data.error}`);
      setIsUploading(false);
      setProgress(0);
    },
  });

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleUpload = () => {
    if (!selectedFile) {
      toast.warning("Please select a file to upload.");
      return;
    }
    if (!selectedCityId) {
      toast.warning("Please select a city.");
      return;
    }

    setIsUploading(true);
    const interval = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 100) {
          clearInterval(interval);
          return 100;
        }
        return Math.min(oldProgress + 10, 100);
      });
    }, 200);
    mutation.mutate({ file: selectedFile, city_id: selectedCityId });
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleAddCityOpen = () => setAddCityOpen(true);
  const handleAddCityClose = () => setAddCityOpen(false);
  const handleUpdateUserCityOpen = () => setUpdateUserCityOpen(true);
  const handleUpdateUserCityClose = () => setUpdateUserCityOpen(false);
  return (
    <Box m="20px">
      <ToastContainer />
      <Header title="Launch Territorial Etudes" subtitle="Launch Territorial News Etudes" />
      <Box 
        display="flex" 
        flexDirection={{ xs: 'column', sm: 'row' }} 
        justifyContent="space-between" 
        alignItems="center" 
        height="10vh" 
        gap={2}
        mb={2}
      >
        <Button
          variant="contained"
          color="secondary"
          onClick={handleOpen}
          startIcon={<CloudUploadIcon />}
          sx={{
            backgroundColor: colors.blueAccent[700],
            color: colors.grey[100],
            fontSize: "14px",
            fontWeight: "bold",
            padding: "10px 20px",
            width: { xs: '100%', sm: 'auto' },
          }}
        >
          Launch Territorial Etudes
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleAddCityOpen}
          startIcon={<CloudUploadIcon />}
          sx={{
            backgroundColor: colors.blueAccent[700],
            color: colors.grey[100],
            fontSize: "14px",
            fontWeight: "bold",
            padding: "10px 20px",
            width: { xs: '100%', sm: 'auto' },
          }}
        >
          Add City
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleUpdateUserCityOpen}
          startIcon={<CloudUploadIcon />}
          sx={{
            backgroundColor: colors.blueAccent[700],
            color: colors.grey[100],
            fontSize: "14px",
            fontWeight: "bold",
            padding: "10px 20px",
            width: { xs: '100%', sm: 'auto' },
          }}
        >
          Update User City
        </Button>
      </Box>
      
      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
          }}
        >
          <Typography variant="h6" mb={2}>
            Upload GeoJSON File
          </Typography>
          {isLoading ? (
            <CircularProgress />
          ) : error ? (
            <Alert severity="error">Failed to fetch cities: {error.message}</Alert>
          ) : (
            <Formik
              initialValues={{ file: null }}
              validationSchema={yup.object().shape({
                file: yup.mixed().required("A file is required"),
                city: yup.string().required("A city is required"),
              })}
              onSubmit={handleUpload}
            >
              {({ setFieldValue }) => (
                <Form>
                  <FormControl fullWidth margin="normal">
                    <InputLabel id="city-select-label">Select City</InputLabel>
                    <Select
                      labelId="city-select-label"
                      value={selectedCityId}
                      onChange={(e) => setSelectedCityId(e.target.value)}
                    >
                      {cities.map((city) => (
                        <MenuItem key={city.city_id} value={city.city_id}>
                          {city.city_name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                    border={`2px dashed ${colors.primary[400]}`}
                    borderRadius="8px"
                    p={2}
                    textAlign="center"
                    mb={3}
                  >
                    <CloudUploadIcon sx={{ fontSize: 48, color: colors.primary[400] }} /> 
                    <Typography variant="body1" mt={2}>
                      Drag files here or <label htmlFor="file-input" style={{ color: colors.grey[100], cursor: "pointer" }}>browse</label>
                    </Typography>
                    <input
                      type="file"
                      onChange={(event) => {
                        handleFileChange(event);
                        setFieldValue("file", event.currentTarget.files[0]);
                      }}
                      style={{ display: "none" }}
                      id="file-input"
                    />
                    {selectedFile && (
                      <Typography variant="body2" mt={2}>
                        Selected file: {selectedFile.name}
                      </Typography>
                    )}
                  </Box>
                  <Box display="flex" justifyContent="center" mt={2}>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={handleUpload}
                      disabled={!selectedFile || isUploading}
                      startIcon={isUploading ? <CircularProgress size={20} /> : null}
                    >
                      {isUploading ? "Uploading..." : "Upload File"}
                    </Button>
                  </Box>
                  {isUploading && (
                    <Box width="100%" mt={2}>
                      <Typography variant="body2" color="textSecondary">
                        Uploading {progress}%...
                      </Typography>
                      <Box height={10} bgcolor={colors.primary[200]} borderRadius="5px">
                        <Box
                          height="100%"
                          bgcolor={colors.primary[600]}
                          width={`${progress}%`}
                          borderRadius="5px"
                        />
                      </Box>
                    </Box>
                  )}
                </Form>
              )}
            </Formik>
          )}
        </Box>
      </Modal>
      <Modal open={addCityOpen} onClose={handleAddCityClose}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
          }}
        >
          <AddCity onClose={handleAddCityClose} refetch={refetch} />
        </Box>
      </Modal>
      <Modal open={updateUserCityOpen} onClose={handleUpdateUserCityClose}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 800, 
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
          }}
        >
          <UpdateUserCity />
        </Box>
      </Modal>
    </Box>
  );
};

export default Territorial;
