import React from 'react';
import { useState } from "react";
import { Box, Button, useMediaQuery, useTheme } from "@mui/material";
import { useParams } from "react-router-dom";
import { tokens } from "../../theme";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import Header from "../../components/Header";
import { getAllUsers } from "../../services/user";
import StudiesMap from "../../components/StudiesMap";
import { useInfiniteQuery, useQuery } from "react-query";
import AdditionalDatas from "../../components/additionalDatas";
import AirIcon from "@mui/icons-material/Air";
import {
  getEtudByUserId,
  getUserFromLocalStorage,
} from "../../services/etude";
import LastGoodEtudes from "../../components/LastGoodEtudes";

const Dashboard = () => {
  const theme = useTheme();
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const colors = tokens(theme.palette.mode);
  const { etudeId } = useParams();
  const user = getUserFromLocalStorage();
  const [selectedUserId, setSelectedUserId] = useState(null);

  const {
    status,
    error,
    data,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    refetch: refetchEtudes,
  } = useInfiniteQuery({
    queryKey: ["posts", "infinite"],
    getNextPageParam: (prevData) => prevData.nextPage,
    queryFn: ({ pageParam = 1 }) => getAllUsers(pageParam),
  });

  const { data: etudes } = useQuery(
    ["etudesByUserId", selectedUserId || user?.id],
    () => getEtudByUserId(selectedUserId || user?.id),
    { enabled: !!selectedUserId || !!user?.id }
  );

  if (status === "loading") return <h1>Loading...</h1>;
  if (status === "error") return <h1>{JSON.stringify(error)}</h1>;
  if (!data) {
    return <div>No data available.</div>;
  }

  const BoundingBoxesData =
    etudes?.boundingBoxes?.map((box) => ({
      id: box.bbox_id, // Assuming bbox_id is a unique identifier for each box
      data: {
        lat_max: box.details.lat_max,
        lat_min: box.details.lat_min,
        lon_max: box.details.lon_max,
        lon_min: box.details.lon_min,
        total_etudes: box.total_etudes,
        adresse: box.details.adresse,
      },
    })) || [];

  return (
    <Box m="20px">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="DASHBOARD" subtitle="Welcome to your dashboard" />
        <Box>
          <Button
            sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
            }}
          >
            <DownloadOutlinedIcon sx={{ mr: "10px" }} />
            Download Reports
          </Button>
        </Box>
      </Box>

      <Box
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gridAutoRows="140px"
        gap="20px"
      >
        {/* ROW 1 */}
        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <AdditionalDatas
            title="Total Etudes Today"
            subtitle="Total Etudes Today"
            progress={etudes?.aggregates?.total_etudes_today || 0}
            aggregateValue={etudes?.aggregates?.total_etudes_today || 0} // Pass the value here
            icon={
              <AirIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>

        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <AdditionalDatas
            title="Total Etudes Week"
            subtitle="Total Etudes Week"
            progress={etudes?.aggregates?.total_etudes_week || 0}
            aggregateValue={etudes?.aggregates?.total_etudes_week || 0}
            icon={
              <AirIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>

        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <AdditionalDatas
            title={"Total Etudes Month"}
            subtitle="Total Etudes Month"
            progress={etudes?.aggregates?.total_etudes_month || 0}
            aggregateValue={etudes?.aggregates?.total_etudes_month || 0}
            icon={
              <AirIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <AdditionalDatas
            title="Total Etudes Today"
            subtitle="Total Etudes Today"
            progress={etudes?.aggregates?.total_etudes_today || 0}
            aggregateValue={etudes?.aggregates?.total_etudes_today || 0} // Pass the value here
            icon={
              <AirIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        {/* ROW 2 */}
        <Box
          gridColumn="span 4"
          gridRow="span 4"
          backgroundColor={colors.primary[400]}
          padding="6px"
          overflow="auto"
        >
          <LastGoodEtudes lastGoodEtudes={etudes?.last_good_etudes || []} /> 
          {/* const lastGoodEtudes = etudes?.last_good_etudes || []; */}
        </Box>

        <Box
          gridColumn="span 4"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
          padding="0px"
        >
          <StudiesMap boundingBoxes={BoundingBoxesData} />
        </Box>
        {/*  */}
      </Box>
    </Box>
  );
};

export default Dashboard;

