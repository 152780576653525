import React from 'react';
// import React from "react";
import { useQuery } from "react-query";
import { fetchPostDetails } from "../services/etude"; // Import your fetchPostDetails function
import { Box, useTheme, Typography } from "@mui/material";
import ProgressCircle from "./ProgressCircle";
import { tokens } from "../theme";

function Average({ etudeId, icon, progress }) {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const { data, isLoading, isError, error } = useQuery(
      ["averageDetails", etudeId],
      () => fetchPostDetails(etudeId)
    );
    if (isLoading) {
      return <p>Loading..</p>;
    }
  
    if (isError) {
      return <p>Error: {error.message}</p>;
    }
  
    if (!data) {
      return null; // Return null or some other fallback if data is not available yet
    }
    let totalSum = 0;
    // Iterate through the annual results and calculate the total sum
    for (const annualResult of data.annual_results) {
      for (let i = 0; i < 5; i++) {
        totalSum += annualResult[`year${i}`];
      }
    }
  return (
    <Box width="100%" m="0 30px">
    <Box display="flex" 
    justifyContent="space-between"
    >
      <Box>
        {icon}
        <Typography
          variant="h4"
          fontWeight="bold"
          sx={{ color: colors.grey[100] }}
        >
          {data.etudeId}
        </Typography>
      </Box>
      <Box>
        <ProgressCircle progress={progress} />
      </Box>
    </Box>
    <Box display="flex" justifyContent="space-between" mt="2px">
      <Typography variant="h5" sx={{ color: colors.greenAccent[500] }}>
      Average Wind
      </Typography>
      <Typography
        variant="h5"
        fontStyle="italic"
        sx={{ color: colors.greenAccent[600] }}
      >
        {totalSum} %
      </Typography>
    </Box>      
  </Box>
  )
}

export default Average