import React from 'react';
import { ResponsiveLine } from "@nivo/line";
import { useTheme } from "@mui/material";
import { tokens } from "../theme";
import { useState } from "react";

const LineChart = ({ monthlyData, yearlyData, studiesOver5Years, isCustomLineColors = false, isDashboard = false }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isMonthlyData, setIsMonthlyData] = useState(true); // Local state to toggle between monthly and yearly data

  function processMonthlyData(monthYear, monthlyData) {
    const [month, year] = monthYear.split('/').map(Number);
    const currentYear = new Date().getFullYear(); // Get the current year

    if (year === currentYear && !isNaN(month) && !isNaN(year)) {
      return {
        x: monthYear,
        y: monthlyData[monthYear] || 0, // Set default value to 0 if data is not available
      };
    } else {
      return null;
    }
  }
  function generateCompleteMonthsData(monthlyData) {
    const currentYear = new Date().getFullYear();
    const completeMonthsData = Array.from({ length: 12 }, (_, index) => {
      const month = index + 1;
      const monthYear = `${month}/${currentYear}`;
      return {
        x: monthYear,
        y: monthlyData[monthYear] || 0, // Set default value to 0 if data is not available
      };
    });
    return completeMonthsData;
  }

  function processYearlyData(monthYear, yearlyData) {
    if (!isNaN(monthYear)) {
      return {
        x: monthYear,
        y: yearlyData[monthYear] || 0, // Set default value to 0 if data is not available
      };
    } else {
      return null;
    }
  }


  let data = Object.keys(isMonthlyData ? monthlyData : yearlyData)
    .map((monthYear) => {
      if (isMonthlyData) {
        return processMonthlyData(monthYear, monthlyData);
      } else {
        const [year] = monthYear.split('/').map(Number);
        return processYearlyData(year, yearlyData);
      }
    })
    .filter((dataPoint) => dataPoint !== null);
  if (isMonthlyData) {
    data = generateCompleteMonthsData(monthlyData);
  }

  return (

    <>
      <div>
        <button onClick={() => setIsMonthlyData(!isMonthlyData)}>
          {isMonthlyData ? "Yearly" : "Monthly"}
        </button>
      </div>

      <ResponsiveLine
        data={[{ id: isMonthlyData ? 'monthly' : 'yearly', data }]}
        theme={{
          axis: {
            domain: {
              line: {
                stroke: colors.grey[100],
              },
            },
            legend: {
              text: {
                fill: colors.grey[100],
              },
            },
            ticks: {
              line: {
                stroke: colors.grey[100],
                strokeWidth: 1,
              },
              text: {
                fill: colors.grey[100],
              },
            },
          },
          legends: {
            text: {
              fill: colors.grey[100],
            },
          },
          tooltip: {
            container: {
              color: colors.primary[500],
            },
          },
        }}
        colors={isDashboard ? { datum: "color" } : { scheme: "nivo" }} // added
        margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
        xScale={{ type: "point" }}
        yScale={{
          type: "linear",
          min: "auto",
          max: "auto",
          stacked: true,
          reverse: false,
        }}
        yFormat=" >-.2f"
        curve="catmullRom"
        axisTop={null}
        axisRight={null}
        axisBottom={{
          orient: "bottom",
          tickSize: 0,
          tickPadding: 5,
          tickRotation: 0,
          legend: isDashboard ? undefined : "dates /year",
          legendOffset: 36,
          legendPosition: "middle",
        }}
        axisLeft={{
          orient: "left",
          tickValues: 5,
          tickSize: 3,
          tickPadding: 5,
          tickRotation: 0,
          legend: isDashboard ? undefined : "Etudes efectué",
          legendOffset: -40,
          legendPosition: "middle",
        }}
        enableGridX={false}
        enableGridY={false}
        pointSize={8}
        pointColor={{ theme: "background" }}
        pointBorderWidth={2}
        pointBorderColor={{ from: "serieColor" }}
        pointLabelYOffset={-12}
        useMesh={true}
        legends={[
          {
            anchor: "bottom-right",
            direction: "column",
            justify: false,
            translateX: 100,
            translateY: 0,
            itemsSpacing: 0,
            itemDirection: "left-to-right",
            itemWidth: 80,
            itemHeight: 20,
            itemOpacity: 0.75,
            symbolSize: 12,
            symbolShape: "circle",
            symbolBorderColor: "rgba(0, 0, 0, .5)",
            effects: [
              {
                on: "hover",
                style: {
                  itemBackground: "rgba(0, 0, 0, .03)",
                  itemOpacity: 1,
                },
              },
            ],
          },
        ]} />

    </>


  );
};

export default LineChart;
