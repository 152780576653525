import React from 'react';
import { Box, Button, Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import PostDetails from "../../components/PostDetails";
import Average from "../../components/Average";
import AverageSolar from "../../components/AverageSolar";
import SolarPowerIcon from "@mui/icons-material/SolarPower";
import EmailIcon from "@mui/icons-material/Email";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import { Link, useParams } from "react-router-dom";
import AirIcon from "@mui/icons-material/Air";
import { fetchPostDetails, downloadZipFile } from "../../services/etude";
import { useQuery } from "react-query";
import { useEffect, useState } from "react";
import ImagePopup from "../../components/ImagePopup";
import "./index.css";
import useMediaQuery from "@mui/material/useMediaQuery";

// import EnergyProductionTable from "../../components/EnergyProductionTable";
// import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
// import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
// import SecurityOutlinedIcon from "@mui/icons-material/SecurityOutlined";
import { DataGrid } from "@mui/x-data-grid";
import { ResponsiveBar } from "@nivo/bar";
// ... inside your component's return statement:

const Invoices = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const { etudeId } = useParams();
  const [selectedImageUrl, setSelectedImageUrl] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [zipUrl, setZipUrl] = useState(null);
  const [isGeneratingZIP, setIsGeneratingZIP] = useState(false);
  const [tableData, setTableData] = useState([]);

  const { data, isLoading, isError, error } = useQuery(
    ["postDetails", etudeId],
    () => fetchPostDetails(etudeId)
  );

  // console.log(data)
  useEffect(() => {
    // Example: Access data.annual_results and perform the desired logic
    if (data && data.annual_results) {
      const tableReadyData = data.annual_results.map((result) => {
        const totalProduction = Object.keys(result)
          .filter((key) => key.startsWith("year"))
          .reduce((sum, yearKey) => sum + result[yearKey], 0);

        return {
          facade: `Facade ${result.facade_id}`,
          total: totalProduction,
        };
      });

      // Now you can use tableReadyData as needed
      setTableData(tableReadyData);
    }
  }, [data]); // Ensure that the dependency array includes 'data' and nothing else

  const openImageModal = (imageUrl) => {
    setSelectedImageUrl(imageUrl);
    setIsModalOpen(true);
  };

  const closeImageModal = () => {
    setIsModalOpen(false);
  };
  const handleDownloadClick = async () => {
    setIsGeneratingZIP(true);

    const response = await downloadZipFile(etudeId);
    if (response) {
      // Create a URL for the Blob object
      const blobUrl = URL.createObjectURL(response.data);
      // Set the URL in state
      setZipUrl(blobUrl);

      // Create a temporary anchor element for downloading
      const a = document.createElement("a");
      a.href = blobUrl;
      a.download = "download.zip";
      a.click();

      // Clean up the URL object after the download
      URL.revokeObjectURL(blobUrl);
    }
    setIsGeneratingZIP(false);
  };
  if (isLoading) {
    return <p>Loading...</p>;
  }

  if (isError) {
    return <p>Error: {error.message}</p>;
  }

  if (!data) {
    return null;
  }
  const tealeDataEolle = [
    {
      facade: "Facade 1",
      eolien: 15.22868,
    },
    {
      facade: "Facade 3",
      eolien: 20.043868,
    },
    {
      facade: "Facade 1",
      eolien: 25.22708,
    },
  ];
  // Combining tableData and tealeDataEolle arrays
  const combinedData = tableData.map((item, index) => {
    const matchingEolien = tealeDataEolle.find(
      (eItem) => eItem.facade === item.facade
    );
    const eolienSum = matchingEolien ? matchingEolien.eolien : 0;
    return {
      id: index,
      facade: item.facade,
      total: item.total,
      eolien: eolienSum,
      combinedData: item.total + eolienSum,
    };
  });

  const rowsWithIds = combinedData.map((item) => ({
    id: item.id,
    ...item,
  }));

  const columns = [
    { field: "id", headerName: "ID", width: 70 },
    { field: "facade", headerName: "Facade", width: 150 },
    { field: "total", headerName: "Eolien", width: 150 },
    { field: "eolien", headerName: " Solaire", width: 150 },
    { field: "combinedData", headerName: "Combined Data", width: 200 },
  ];


  const windData = [
    {
      year0: 372.4586364625549,
      year1: 485.8125414734012,
      year2: 445.841465397143,
      year3: 645.753613879512,
      year4: 492.0213531086119,
      year5: 492.0213531086119,
      year6: 492.0213531086119,
      year7: 492.0213531086119,
      year8: 492.0213531086119,
      year9: 492.0213531086119,
      year10: 492.0213531086119,
      year11: 492.0213531086119,
      year12: 492.0213531086119,
  
  
    }
  ];
  
  const solarData = [
    {
      year0: 372.4586364625549,
      year1: 485.8125414734012,
      year2: 445.841465397143,
      year3: 645.753613879512,
      year4: 492.0213531086119,
      year5: 492.0213531086119,
      year6: 492.0213531086119,
      year7: 492.0213531086119,
  
      year8: 492.0213531086119,
  
      year9: 492.0213531086119,
      year10: 492.0213531086119,
      year11: 492.0213531086119,
      year12: 492.0213531086119,
    }
    
  ];

  const combinedDataFeak = windData.map((windYearData, index) => {
    const solarYearData = solarData[index];
    const yearKey = `year${index}`;
    
    return {
      year: yearKey, // This will be used for the x-axis
      wind: windYearData[yearKey],
      solar: solarYearData[yearKey]
    };
  });


  return (
    <Box m="20px">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="Etude Details" subtitle="Details" />

        <Box>
          <Button
            sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
            }}
            // color="secondary"

            onClick={handleDownloadClick}
            disabled={isGeneratingZIP}
          >
            <DownloadOutlinedIcon sx={{ marginRight: "5px" }} />
            {isGeneratingZIP ? (
              <span className="loading-text">Loading... </span>
            ) : (
              "Download ZIP"
            )}
          </Button>
        </Box>
      </Box>

      {/*  */}
      <Box
        display="grid"
        gridTemplateColumns="repeat(4, 1fr)"
        gridAutoRows="50px"
        gap="20px"
      >
        {data.etude_data.facade_de_batiment_01 && (
          <Link
            to={`/facade01/${data.etude_data.id}`}
            style={{ textDecoration: "none" }}
          >
            <Box
              gridColumn="span 3"
              backgroundColor={colors.primary[400]}
              display="flex"
              alignItems="center"
              justifyContent="center"
              sx={{
                height: "40px",
                color: colors.greenAccent[600],
                textDecoration: "none",
                cursor: "pointer",
              }}
            >
              <Typography variant="h5" fontStyle="italic">
                facade01
              </Typography>
            </Box>
          </Link>
        )}
        {data.etude_data.facade_de_batiment_02 && (
          <Link
            to={`/facade02/${data.etude_data.id}`}
            style={{ textDecoration: "none" }}
          >
            <Box
              gridColumn="span 3"
              backgroundColor={colors.primary[400]}
              display="flex"
              alignItems="center"
              justifyContent="center"
              sx={{
                height: "40px",
                color: colors.greenAccent[600],
                textDecoration: "none",
                cursor: "pointer",
              }}
            >
              <Typography variant="h5" fontStyle="italic">
                facade02
              </Typography>
            </Box>
          </Link>
        )}
        {data.etude_data.facade_de_batiment_03 && (
          <Link
            to={`/facade03/${data.etude_data.id}`}
            style={{ textDecoration: "none" }}
          >
            <Box
              gridColumn="span 3"
              backgroundColor={colors.primary[400]}
              display="flex"
              alignItems="center"
              justifyContent="center"
              sx={{
                height: "40px",
                color: colors.greenAccent[600],
                textDecoration: "none",
                cursor: "pointer",
              }}
            >
              <Typography variant="h5" fontStyle="italic">
                facade03
              </Typography>
            </Box>
          </Link>
        )}
        {data.etude_data.facade_de_batiment_04 && (
          <Link
            to={`/facade04/${data.etude_data.id}`}
            style={{ textDecoration: "none" }}
          >
            <Box
              gridColumn="span 3"
              backgroundColor={colors.primary[400]}
              display="flex"
              alignItems="center"
              justifyContent="center"
              sx={{
                height: "40px",
                color: colors.greenAccent[600],
                textDecoration: "none",
                cursor: "pointer",
              }}
            >
              <Typography variant="h5" fontStyle="italic">
                facade04
              </Typography>
            </Box>
          </Link>
        )}
      </Box>

      {/*  */}

      <Box
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gridAutoRows="140px"
        gap="20px"
      >
        {/* ROW 1 */}
        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <PostDetails
            title="12,361"
            subtitle="Emails Sent"
            progress="0.75"
            increase="+14%"
            etudeId={etudeId}
            icon={
              <EmailIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        {/* ROW 2 */}
        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Average
            title="12,361"
            subtitle="Emails Sent"
            progress="0.75"
            increase="+14%"
            etudeId={etudeId}
            icon={
              <AirIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        {/* ROW 3 */}
        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <AverageSolar
            title="12,361"
            subtitle="Emails Sent"
            progress="0.75"
            increase="+14%"
            etudeId={etudeId}
            icon={
              <SolarPowerIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        {/* ROW 4 */}
        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <PostDetails
            title="12,361"
            subtitle="Emails Sent"
            progress="0.75"
            increase="+14%"
            etudeId={etudeId}
            icon={
              <EmailIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
      </Box>

      <Box
        mt="10px"
        // p="0 30px"
        display="flex "
        justifyContent="space-between"
        alignItems="center"
        gridTemplateColumns="repeat(4, minmax(0, 1fr))"
        sx={{
          "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
        }}
      >
        <Box
          m="1px 0 0 0"
          height="250px"
          width="130vh"
          sx={{
            "& .MuiDataGrid-root": {
              border: "none",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            },
            "& .name-column--cell": {
              color: colors.greenAccent[300],
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: colors.blueAccent[700],
              borderBottom: "none",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: colors.primary[400],
            },
            "& .MuiDataGrid-footerContainer": {
              borderTop: "none",
              backgroundColor: colors.blueAccent[700],
            },
            "& .MuiCheckbox-root": {
              color: `${colors.greenAccent[200]} !important`,
            },
          }}
        >
          <DataGrid
            rowsPerPageOptions={[]}
            rows={rowsWithIds}
            columns={columns}
          />
        </Box>

        {/* <EnergyProductionTable data={tableData} /> */}
        {/*  */}
        <Box
          height="250px"
          display="grid"
          gap="1px"
          gridTemplateColumns="repeat(2, minmax(0, 1fr))"
          sx={{
            "& > div": { gridColumn: isNonMobile ? undefined : "span 2" },
          }}
          backgroundColor={colors.primary[400]}
      
        >
          {data.general_image_urls.map(
            (imageUrl, index) =>
              // Check if imageUrl is truthy before rendering the image
              imageUrl && (
                <Box
                  display="grid"
                  sx={{
                    "& > div": {
                      gridColumn: isNonMobile ? undefined : "span 2",
                    },
                  }}
                  key={index}
                  className="image-container"
                >
                  <img
                    src={imageUrl}
                    alt={`Images ${index}`}
                    className="image"
                    onClick={() => openImageModal(imageUrl)}
                  />
                </Box>
              )
          )}
          <ImagePopup
            isOpen={isModalOpen}
            imageUrl={selectedImageUrl}
            closeModal={closeImageModal}
          />
        </Box>
      </Box>
      <div>test</div>
      <ResponsiveBar
  data={combinedDataFeak}
  keys={['wind', 'solar']}
  indexBy="year"
  groupMode="stacked"
  margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
  padding={0.3}
  colors={{ scheme: 'nivo' }}
  borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
  // ... other props
/>

    </Box>
  );
};

export default Invoices;
