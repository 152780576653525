// import React from 'react';
import React, { useState } from "react";
import Header from "../../components/Header";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { fetchPostDetails } from "../../services/etude";
import { Box, Button, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import {  ResponsiveLine } from "@nivo/line";
import { ResponsiveBar } from "@nivo/bar";

import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { useNavigate } from "react-router-dom";
import ImagePopup from "../../components/ImagePopup";

function FACADE01({ isCustomLineColors = false, isDashboard = true }) {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [selectedImageUrl, setSelectedImageUrl] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { etudeId } = useParams();
  const navigate = useNavigate();

  const { data, isLoading, isError, error } = useQuery(
    ["postDetails", etudeId],
    () => fetchPostDetails(etudeId)
  );
  const openImageModal = (imageUrl) => {
    setSelectedImageUrl(imageUrl);
    setIsModalOpen(true);
  };

  const closeImageModal = () => {
    setIsModalOpen(false);
  };
  const handleGoBack = () => {
    navigate(-1); // Navigate back to the previous page
  };

  if (isLoading) {
    return <p>Loading...</p>;
  }

  if (isError) {
    return <p>Error: {error.message}</p>;
  }

  if (!data) {
    return null;
  }

  const facadeId1MonthlyResults =
    data?.monthly_results?.filter((result) => result.facade_id === 1) ?? [];

  const facadeId1AnnualResults =
    data?.annual_results?.filter((result) => result.facade_id === 1) ?? [];

  const facadeId1HourlyResults =
    data?.hourly_results?.filter((result) => result.facade_id === 1) ?? [];

  const facadeData = facadeId1AnnualResults[0];
  const dataPoints = facadeData
    ? Object.keys(facadeData)
        .filter((key) => key.startsWith("year"))
        .map((key) => ({
          x: key,
          y: facadeData[key],
        }))
        .filter((dataPoint) => dataPoint.y !== null && !isNaN(dataPoint.y))
    : [];

  const facadeDataMonthly = facadeId1MonthlyResults[0];
  const dataPointsMonthly = facadeDataMonthly
    ? Object.keys(facadeDataMonthly)
        .filter(
          (key) =>
            key !== "id" &&
            key !== "facade_id" &&
            key !== "etudes" &&
            key !== "createdAt" &&
            key !== "updatedAt"
        )
        .map((key) => ({
          x: key,
          y: facadeDataMonthly[key],
        }))
        .filter((dataPoint) => dataPoint.y !== null && !isNaN(dataPoint.y))
    : [];

  const facadeDataHourly = facadeId1HourlyResults[0];
  const dataPointsHourly = facadeDataHourly
    ? Object.keys(facadeDataHourly)
        .filter(
          (key) =>
            key !== "id" &&
            key !== "facade_id" &&
            key !== "etudes" &&
            key !== "createdAt" &&
            key !== "updatedAt"
        )
        .map((key) => ({
          x: key,
          y: facadeDataHourly[key],
        }))
        .filter((dataPoint) => dataPoint.y !== null && !isNaN(dataPoint.y))
    : [];

  const imageKey = "image_facade_de_batiment_01";
  const filteredImageUrls = data.image_urls[imageKey];

  const downloadZipFile = (data) => {
    return data.map((row) => Object.values(row).join(",")).join("\n");
  };

  const handleDownload = async () => {
    try {
      // etudeId should be available from useParams() if it's part of the URL
      await downloadZipFile(etudeId);
    } catch (error) {
      console.error("Error during file download:", error);
    }
  };

  ////// c'est pour le fucher
  const windData = [
    {
      year0: 372.4586364625549,
      year1: 485.8125414734012,
      year2: 445.841465397143,
      year3: 645.753613879512,
      year4: 492.0213531086119,
      year5: 492.0213531086119,
      year6: 492.0213531086119,
      year7: 492.0213531086119,
      year8: 492.0213531086119,
      year9: 492.0213531086119,
      year10: 492.0213531086119,
      year11: 492.0213531086119,
      year12: 492.0213531086119,
    },
  ];

  const solarData = [
    {
      year0: 372.4586364625549,
      year1: 485.8125414734012,
      year2: 445.841465397143,
      year3: 645.753613879512,
      year4: 492.0213531086119,
      year5: 492.0213531086119,
      year6: 492.0213531086119,
      year7: 492.0213531086119,
      year8: 492.0213531086119,
      year9: 492.0213531086119,
      year10: 492.0213531086119,
      year11: 492.0213531086119,
      year12: 492.0213531086119,
    },
  ];
  const transformedData = [];

  // We assume all objects have the same year keys, so we can just reference the first one
  const years = Object.keys(windData[0]);

  // Loop over each year to create our new data structure
  years.forEach((year) => {
    let yearData = { year: year };

    windData.forEach((dataSet, index) => {
      // Here 'wind' is prefixed to the index to differentiate between different wind data sets
      yearData[`wind${index}`] = dataSet[year];
    });

    solarData.forEach((dataSet, index) => {
      // Same for 'solar'
      yearData[`solar${index}`] = dataSet[year];
    });

    transformedData.push(yearData);
  });

  const combinedData = windData.map((windYearData, index) => {
    const solarYearData = solarData[index];
    const yearKey = `year${index}`;

    return {
      year: yearKey, // This will be used for the x-axis
      wind: windYearData[yearKey],
      solar: solarYearData[yearKey],
    };
  });

  // Now transformedData is in the correct format for the ResponsiveBar component

// ici

const CustomLineStyle = ({ series, lineGenerator, xScale, yScale }) => {
  return series.map(({ id, data, color }) => {
    // Adjust strokeDasharray for each line
    let strokeDasharray = "none";
    if (id === "Average Deviation") {
      strokeDasharray = "6, 6"; // Dashed line
    } else if (id === "Yearly Deviation") {
      strokeDasharray = "2, 4"; // Dotted line
    }

    return (
      <path
        key={id}
        d={lineGenerator(
          data.map((d) => ({
            x: xScale(d.data.x),
            y: yScale(d.data.y),
          }))
        )}
        fill="none"
        stroke={color}
        style={{
          strokeDasharray,
          strokeWidth: id === "Yearly Production" ? 3 : 2,
        }}
      />
    );
  });
};


  const combinedDataByYear = years.map((year) => {
    return {
      year: year.replace("year", "20"), // replacing 'year0' with '2011', 'year1' with '2012', etc.
      wind: windData[0][year],
      solar: solarData[0][year],
    };
  });

  // Calculate the average production for wind and solar

  
  const totalProductionValues = combinedDataByYear.reduce(
    (acc, curr) => acc + (curr.wind + curr.solar),
    0
  );
  const averageValue = totalProductionValues / (combinedDataByYear.length * 2); // Average across all years and both wind+solar

  // 2. Calculate the Yearly Deviations
  const yearlyDeviations = combinedDataByYear.map((data) => ({
    year: data.year,
    deviation: Math.abs((data.wind + data.solar) / 2 - averageValue), // Absolute deviation from the average
  }));

  // 3. Calculate the Average Deviation (if needed)
  const totalDeviation = yearlyDeviations.reduce(
    (acc, curr) => acc + curr.deviation,
    0
  );
  const averageDeviation = totalDeviation / yearlyDeviations.length; // Mean of deviations

  // 4. Prepare Data for Nivo Line Chart
  const lineData = [
    {
      id: "Yearly Production",
      data: combinedDataByYear.map((data) => ({
        x: data.year,
        y: (data.wind + data.solar) / 2,
      })),
      color: "#468df3", // A blue color for yearly production
      style: { lineWidth: 2, strokeDasharray: "none" },

    },
    {
      id: "Average Deviation",
      data: yearlyDeviations.map((data) => ({
        x: data.year,
        y: averageDeviation,
      })), // This will be a flat line if you use averageDeviation
      color: "#a1cfff",
      style: { lineWidth: 2, strokeDasharray: "4 4" }, // This will create a dashed line



    },
    // Optionally, if you want to show actual deviations for each year as a separate line:
    {
      id: "Yearly Deviation",
      data: yearlyDeviations.map((data) => ({
        x: data.year,
        y: data.deviation,
      })),
      color: "#f1e15b", // A yellow color for yearly deviation
      style: { lineWidth: 2, strokeDasharray: "2 2" }, // This will create a dotted line



    },
  ];


// // If everything with the data seems correct, check the chart configuration for scale settings



  return (
    <Box m="20px">
      <Header title="Etude Details" subtitle="Details" />
      <div>FACADE01</div>

   
      <Box className="three_element">
        <Box sx={{ display: "flex", gap: "10px" }}>
          <img
            className="image02"
            src={filteredImageUrls}
            alt={imageKey}
            onClick={() => openImageModal(filteredImageUrls)}
          />

          <ImagePopup
            isOpen={isModalOpen}
            imageUrl={selectedImageUrl}
            closeModal={closeImageModal}
          />

          <Button
            onClick={handleDownload}
            // additional styling or props for your button
          >
            Download ZIP
          </Button>
        </Box>

        <Box className="image-container close-button">
          <Button
            sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
            }}
            onClick={handleGoBack}
          >
            <ArrowBackIosNewIcon />
          </Button>
        </Box>
      </Box>

      {data?.annual_results && dataPoints.length > 0 && (
        <Box height="40vh">
          <ResponsiveLine
            data={[
              {
                id: "Yearly Data",
                color: "#00a7d3", // Specify the color you want here
                // Specify a color for the series
                data: dataPoints,
              },
            ]}
            theme={{
              axis: {
                domain: {
                  line: {
                    stroke: colors.grey[100],
                  },
                },
                legend: {
                  text: {
                    fill: colors.grey[100],
                  },
                },
                ticks: {
                  line: {
                    stroke: colors.grey[100],
                    strokeWidth: 1,
                  },
                  text: {
                    fill: colors.grey[100],
                  },
                },
              },
              legends: {
                text: {
                  fill: colors.grey[100],
                },
              },
              tooltip: {
                container: {
                  color: colors.primary[500],
                },
              },
            }}
            colors={isDashboard ? { datum: "color" } : { scheme: "nivo" }} // added
            margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
            xScale={{ type: "point" }}
            yScale={{
              type: "linear",
              min: "auto",
              max: "auto",
              stacked: true,
              reverse: false,
            }}
            yFormat=" >-.2f"
            curve="catmullRom"
            axisTop={null}
            axisRight={null}
            axisBottom={{
              orient: "bottom", // Make sure this is "bottom"
              tickSize: 0,
              tickPadding: 5,
              tickRotation: 0,
              legend: isDashboard ? undefined : "x", // Check legend label
              legendOffset: 36,
              legendPosition: "middle",
            }}
            axisLeft={{
              orient: "left", // Make sure this is "left"
              tickValues: 5,
              tickSize: 3,
              tickPadding: 5,
              tickRotation: 0,
              legend: isDashboard ? undefined : "y", // Check legend label
              legendOffset: -40,
              legendPosition: "middle",
            }}
            enableGridX={false}
            enableGridY={false}
            pointSize={8}
            pointColor={{ theme: "background" }}
            pointBorderWidth={2}
            pointBorderColor={{ from: "serieColor" }}
            pointLabelYOffset={-12}
            useMesh={true}
            legends={[
              {
                anchor: "bottom-right",
                direction: "column",
                justify: false,
                translateX: 100,
                translateY: 0,
                itemsSpacing: 0,
                itemDirection: "left-to-right",
                itemWidth: 80,
                itemHeight: 20,
                itemOpacity: 0.75,
                symbolSize: 12,
                symbolShape: "circle",
                symbolBorderColor: "rgba(0, 0, 0, .5)",
                effects: [
                  {
                    on: "hover",
                    style: {
                      itemBackground: "rgba(0, 0, 0, .03)",
                      itemOpacity: 1,
                    },
                  },
                ],
              },
            ]}
          />
        </Box>
      )}

      {/*  */}
      {data?.monthly_results && dataPointsMonthly.length > 0 && (
        <Box height="40vh">
          <ResponsiveLine
            data={[
              {
                id: "Monthly Data", // Unique ID for the chart
                color: "#00a8ac", // Specify the color you want here

                data: dataPointsMonthly, // Your data array
              },
            ]}
            theme={{
              axis: {
                domain: {
                  line: {
                    stroke: colors.grey[100],
                  },
                },
                legend: {
                  text: {
                    fill: colors.grey[100],
                  },
                },
                ticks: {
                  line: {
                    stroke: colors.grey[100],
                    strokeWidth: 1,
                  },
                  text: {
                    fill: colors.grey[100],
                  },
                },
              },
              legends: {
                text: {
                  fill: colors.grey[100],
                },
              },
              tooltip: {
                container: {
                  color: colors.primary[500],
                },
              },
            }}
            colors={isDashboard ? { datum: "color" } : { scheme: "nivo" }} // added
            margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
            xScale={{ type: "point" }}
            yScale={{
              type: "linear",
              min: "auto",
              max: "auto",
              stacked: true,
              reverse: false,
            }}
            yFormat=" >-.2f"
            curve="catmullRom"
            axisTop={null}
            axisRight={null}
            axisBottom={{
              orient: "bottom",
              tickSize: 0,
              tickPadding: 5,
              tickRotation: 0,
              legend: isDashboard ? undefined : "month", // added
              legendOffset: 36,
              legendPosition: "middle",
            }}
            axisLeft={{
              orient: "left",
              tickValues: 5, // added
              tickSize: 3,
              tickPadding: 5,
              tickRotation: 0,
              legend: isDashboard ? undefined : "value", // added
              legendOffset: -40,
              legendPosition: "middle",
            }}
            enableGridX={false}
            enableGridY={false}
            pointSize={8}
            pointColor={{ theme: "background" }}
            pointBorderWidth={2}
            pointBorderColor={{ from: "serieColor" }}
            pointLabelYOffset={-12}
            useMesh={true}
            legends={[
              {
                anchor: "bottom-right",
                direction: "column",
                justify: false,
                translateX: 100,
                translateY: 0,
                itemsSpacing: 0,
                itemDirection: "left-to-right",
                itemWidth: 80,
                itemHeight: 20,
                itemOpacity: 0.75,
                symbolSize: 12,
                symbolShape: "circle",
                symbolBorderColor: "rgba(0, 0, 0, .5)",
                effects: [
                  {
                    on: "hover",
                    style: {
                      itemBackground: "rgba(0, 0, 0, .03)",
                      itemOpacity: 1,
                    },
                  },
                ],
              },
            ]}
          />
        </Box>
      )}

      {/*  */}
      {data?.hourly_results && dataPointsHourly.length > 0 && (
        <Box height="40vh">
          <ResponsiveLine
            data={[
              {
                id: "Hourly Data", // Unique ID for the chart
                color: "#2559a5", // Specify the color you want here

                data: dataPointsHourly, // Your data array
              },
            ]}
            theme={{
              axis: {
                domain: {
                  line: {
                    stroke: colors.grey[100],
                  },
                },
                legend: {
                  text: {
                    fill: colors.grey[100],
                  },
                },
                ticks: {
                  line: {
                    stroke: colors.grey[100],
                    strokeWidth: 1,
                  },
                  text: {
                    fill: colors.grey[100],
                  },
                },
              },
              legends: {
                text: {
                  fill: colors.grey[100],
                },
              },
              tooltip: {
                container: {
                  color: colors.primary[500],
                },
              },
            }}
            colors={isDashboard ? { datum: "color" } : { scheme: "nivo" }} // added
            margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
            xScale={{ type: "point" }}
            yScale={{
              type: "linear",
              min: "auto",
              max: "auto",
              stacked: true,
              reverse: false,
            }}
            yFormat=" >-.2f"
            curve="catmullRom"
            axisTop={null}
            axisRight={null}
            axisBottom={{
              orient: "bottom",
              tickSize: 0,
              tickPadding: 5,
              tickRotation: 0,
              legend: isDashboard ? undefined : "month", // added
              legendOffset: 36,
              legendPosition: "middle",
            }}
            axisLeft={{
              orient: "left",
              tickValues: 5, // added
              tickSize: 3,
              tickPadding: 5,
              tickRotation: 0,
              legend: isDashboard ? undefined : "value", // added
              legendOffset: -40,
              legendPosition: "middle",
            }}
            enableGridX={false}
            enableGridY={false}
            pointSize={8}
            pointColor={{ theme: "background" }}
            pointBorderWidth={2}
            pointBorderColor={{ from: "serieColor" }}
            pointLabelYOffset={-12}
            useMesh={true}
            legends={[
              {
                anchor: "bottom-right",
                direction: "column",
                justify: false,
                translateX: 100,
                translateY: 0,
                itemsSpacing: 0,
                itemDirection: "left-to-right",
                itemWidth: 80,
                itemHeight: 20,
                itemOpacity: 0.75,
                symbolSize: 12,
                symbolShape: "circle",
                symbolBorderColor: "rgba(0, 0, 0, .5)",
                effects: [
                  {
                    on: "hover",
                    style: {
                      itemBackground: "rgba(0, 0, 0, .03)",
                      itemOpacity: 1,
                    },
                  },
                ],
              },
            ]}
          />
        </Box>
      )}

      {/*    */}
      {transformedData && transformedData.length > 0 && (
        <Box height="40vh">
          <ResponsiveBar
            data={transformedData}
            keys={["wind0", "solar0"]}
            indexBy="year"
            margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
            padding={0.3}
            groupMode="grouped"
            colors={{ scheme: "nivo" }}
            borderColor={{ from: "color", modifiers: [["darker", 1.6]] }}
            axisTop={null}
            axisRight={null}
            axisBottom={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: "year",
              legendPosition: "middle",
              legendOffset: 32,
            }}
            axisLeft={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: "production",
              legendPosition: "middle",
              legendOffset: -40,
            }}
            labelSkipWidth={12}
            labelSkipHeight={12}
            labelTextColor={{ from: "color", modifiers: [["darker", 1.6]] }}
            legends={[
              {
                dataFrom: "keys",
                anchor: "bottom-right",
                direction: "column",
                justify: false,
                translateX: 120,
                translateY: 0,
                itemsSpacing: 2,
                itemWidth: 100,
                itemHeight: 20,
                itemDirection: "left-to-right",
                itemOpacity: 0.85,
                symbolSize: 20,
                effects: [
                  {
                    on: "hover",
                    style: {
                      itemOpacity: 1,
                    },
                  },
                ],
              },
            ]}
            animate={true}
            motionStiffness={90}
            motionDamping={15}
          />
        </Box>
      )}

      {/* //////: */}

      {lineData[0].data.length > 0 && (
        <Box height="40vh">
          <ResponsiveLine
            data={lineData} 
            theme={{
              lineWidth: 2,
              axis: {
                domain: {
                  line: {
                    stroke: colors.grey[100],
                  },
                },
                legend: {
                  text: {
                    fill: colors.grey[100],
                  },
                },
                ticks: {
                  line: {
                    stroke: colors.grey[100],
                    strokeWidth: 1,
                  },
                  text: {
                    fill: colors.grey[100],
                  },
                },
              },
              legends: {
                text: {
                  fill: colors.grey[100],
                },
              },
              tooltip: {
                container: {
                  color: colors.primary[500],
                },
              },
            }}
            // colors={isDashboard ? { datum: "color" } : { scheme: "nivo" }} // You may need to adjust this based on how you want to color your lines
            margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
            xScale={{ type: "point" }}
            yScale={{
              type: "linear",
              min: "auto",
              max: "auto",
              stacked: true,
              reverse: false,
            }}
            yFormat=" >-.2f"
            curve="catmullRom"
            axisTop={null}
            axisRight={null}
            axisBottom={{
              orient: "bottom",
              tickSize: 0,
              tickPadding: 5,
              tickRotation: 0,
              legend:  "year",
              legendOffset: 36,
              legendPosition: "middle",
            }}
            axisLeft={{
              orient: "left",
              tickValues: 5,
              tickSize: 3,
              tickPadding: 5,
              tickRotation: 0,
              legend: "production",
              legendOffset: -40,
              legendPosition: "middle",
            }}
            enableGridX={false}
            enableGridY={true} // Grid lines might be helpful for deviation visualization
            pointSize={8}
            pointColor={{ theme: "background" }}
            pointBorderWidth={2}
            pointBorderColor={{ from: "serieColor" }}
            pointLabelYOffset={-12}
            useMesh={true}
            legends={[
              {
                anchor: "bottom-right",
                direction: "column",
                justify: false,
                translateX: 100,
                translateY: 0,
                itemsSpacing: 0,
                itemDirection: "left-to-right",
                itemWidth: 80,
                itemHeight: 20,
                itemOpacity: 0.75,
                symbolSize: 12,
                symbolShape: "circle",
                symbolBorderColor: "rgba(0, 0, 0, .5)",
                effects: [
                  {
                    on: "hover",
                    style: {
                      itemBackground: "rgba(0, 0, 0, .03)",
                      itemOpacity: 1,
                    },
                  },
                ],
              },
            ]}
            layers={[
              "grid",
              "markers",
              "axes",
              "areas",
              "crosshair",
              "lines",
              "slices",
              "points",
              "mesh",
              "legends",
              CustomLineStyle // add the custom layer here
            ]}
            colors={lineData.map(ld => ld.color)} // This maps the line colors as specified above
          />
        </Box>
      )}
      {/* ////////// */}
    </Box>
  );
}
export default FACADE01;
