// import React from 'react';
// LastGoodEtudes component
import React from "react";
import { Box, Typography, useTheme } from "@mui/material";
import { tokens } from "../theme";

const LastGoodEtudes = ({ lastGoodEtudes }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Box m="20px">
      <Box
        gridColumn="span 6"
        gridRow="span 3"
        backgroundColor={colors.primary[400]}
        // overflow="auto"
      >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          borderBottom={`4px solid ${colors.primary[500]}`}
          colors={colors.grey[100]}
          p="15px"
          // overflow="auto"
        >
          <Typography color={colors.grey[100]} variant="h5" fontWeight="600">
            Recent Statuses
          </Typography>
        </Box>
        {lastGoodEtudes.map((transaction, i) => (
          <Box
            key={`${transaction.txId}-${i}`}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            borderBottom={`4px solid ${colors.primary[500]}`}
            p="15px"
          >
            <Box>
              <Typography
                color={colors.greenAccent[500]}
                variant="h5"
                fontWeight="600"
              >
                {transaction.txId}
              </Typography>
              <Typography color={colors.grey[100]}>
                {transaction.nom_du_cient}
              </Typography>
            </Box>
            <Box color={colors.grey[100]}>{transaction.createdAt}</Box>
            <Box
                 color={colors.greenAccent[500]}
                 variant="h5"
                 fontWeight="600"
            >
              {transaction.nom_du_site}
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default LastGoodEtudes;
