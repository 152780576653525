// cityService.js
import studyapi from "./studyapi";

export const getCitiesUpdateUserCity = async () => {
  try {
    const response = await studyapi.get("cities/");
    return response.data;
  } catch (error) {
    console.error("Failed to fetch cities:", error);
    throw error;
  }
};
export const getCities = async () => {
  try {
    const response = await studyapi.get("get-cities/");
    const cities = response.data.data.map((city) => ({
      city_id: city.id,
      city_name: city.city_name,
      region: city.region,
      country: city.country,
      user_id: city.user_id,
    }));
    return cities;
  } catch (error) {
    console.error("Failed to fetch cities:", error);
    throw error;
  }
};

// export const createCity = async (cityData) => {
//   try {
//     const response = await studyapi.post('/city/create/', cityData);
//     return response.data;
//   } catch (error) {
//     console.error("Failed to create city:", error);
//     throw error;
//   }
// };

export const createCity = async (cityData) => {
  try {
    const response = await studyapi.post("city/create/", cityData);
    return response.data;
  } catch (error) {
    if (error.response && error.response.data) {
      throw error.response.data;
    } else {
      console.error("Failed to create city:", error);
      throw error;
    }
  }
};

export const uploadgeoJsonFileTerritorial = async (file, cityId) => {
  const formData = new FormData();
  formData.append("file", file);
  formData.append("city_id", cityId); 
  return await studyapi.post("upload-buildings-geojson/", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};
