// import React from 'react';
import React, { useRef, useState, useCallback, useEffect } from "react";
import { useMutation, useQueryClient } from "react-query";
import {
  Select,
  MenuItem,
  Checkbox,
  Box,
  Button,
  TextField,
  Typography,
  FormControlLabel,
  CircularProgress,
  Divider,
  useTheme,
} from "@mui/material";

import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../components/Header";
import {
  createPost,
  createPostEvalution,
  SaveAllEvaluation,
  StartTheAnalysis,
  fetchPostDetails,
  // getEtudByUserId,
  getUserFromLocalStorage,
  uploadCsvFile,
} from "../../services/etude";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { tokens } from "../../theme";
import "../home.css";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";

const Form = () => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const nom_du_cientRef = useRef();
  const nom_du_siteRef = useRef();

  const user = getUserFromLocalStorage();

  const latitudeRef = useRef(null);
  const longitudeRef = useRef(null);
  const start_yearRef = useRef();
  const end_yearRef = useRef();
  const [addresst, setAddresst] = useState();
  const hauteurRef = useRef();
  // const uniqueTimestamp = Date.now();
  const [saveImageUrls, setsaveImageUrls] = useState([]);
  const [imageUrls, setImageUrls] = useState([]);
  const [selectedOption, setSelectedOption] = useState("");

  const [id, setId] = useState(Number);
  const facade_de_batiment_01Ref = useRef();
  const facade_de_batiment_02Ref = useRef();
  const facade_de_batiment_03Ref = useRef();
  const facade_de_batiment_04Ref = useRef();

  const [bat_hghtRef, SetBat_hghtRef] = useState(36);
  const [z_zeroRefdefault_valueRef, SetZ_zeroRefdefault_valueRef] =
    useState(0.0002);

  const compnyRef = useRef(180);
  const locatnRefdefault_valueRef = 67;
  const bat_orientatnRef = useRef(-15.0);
  // eslint-disable-next-line
  const [selectedCoordinates, setSelectedCoordinates] = useState({
    lat: null,
    lng: null,
  });
  const [selected, setSelected] = useState("form"); // Initialize with 'form'
  const [isChecked, setIsChecked] = useState(false);
  // const [isUploading, setIsUploading] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [isUploading, setIsUploading] = useState(false);
  // eslint-disable-next-line
  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const [sendFacade01, setSendFacade01] = useState(true);
  // eslint-disable-next-line no-unused-vars
  const [isAsNewEtude, setIsAsNewEtude] = useState(false);
  const [selectedOptionnew, setSelectedOptionnew] = useState(""); // Initialize selectedOption state

  const [sendFacade02, setSendFacade02] = useState(false);
  const [sendFacade03, setSendFacade03] = useState(false);
  const [sendFacade04, setSendFacade04] = useState(false);

  const [sendAnnuelle, setSendAnnuelle] = useState(true);
  const [sendMensuelle, setSendMensuelle] = useState(false);
  const [sendHoraire, setSendHoraire] = useState(false);
  // const [sendHoraire, setSendHoraire] = useState(false);
  const defaultValue_coff = 1.2;
  defaultValue_coff.toLocaleString(undefined, {
    minimumFractionDigits: 4,
    maximumFractionDigits: 4,
  });

  // Traitement stait start there
  const [EvauationdDEtails, setEvauationdDEtails] = useState(false);
  const [process_year, setprocess_year] = useState(false);
  const [process_month, setprocess_month] = useState(false);
  const [process_hour, setprocess_hour] = useState(false);

  // option Staite start here
  const [simulationVitesseMinimale, setSimulationVitesseMinimale] =
    useState(3.0);
  const [simulationVitesseMaximale, setSimulationVitesseMaximale] =
    useState(25.1);
  const [Coefficient_d_accélération, setCoefficient_d_accélération] =
    useState(1.2);
  const [Resolution_des_roses_des_vents, setResolution_des_roses_des_vents] =
    useState(20);
  const [Resolution_frequentielle, SetResolution_frequentielle] = useState(2.5);
  const [Resolution_de_la_vitesse, SetResolution_de_la_vitesse] = useState(1);
  const [pourcentage, SetPourcentage] = useState(20);
  const [inculinaision, SetInculinaision] = useState(5);
  const [orientation, SetOrientation] = useState(180);
  const [
    analyse_det_horaire_mois_par_mois,
    setAnalyse_det_horaire_mois_par_mois,
  ] = useState(false);
  const [analyse_det_journaliere, setAnalyse_det_journaliere] = useState(false);
  const [
    limite_custom_frequence_rose_de_vents,
    setLimite_custom_frequence_rose_de_vents,
  ] = useState(false);
  const [Calcul_parallele_experimental, setCalcul_parallele_experimental] =
    useState(false);

  const [details_meteo_mois_annees, setDetails_meteo_mois_annees] =
    useState(false);

  const optionToValueMapping = {
    "": 1.0,
    0: 0.0002,
    1: 0.0024,
    2: 0.03,
    3: 0.055,
    4: 0.1,
    5: 0.2,
    6: 0.5,
    7: 0.75,
    8: 1.2,
  };
  const location = useLocation();
  const [formData, setFormData] = useState({});
  const [selectedAddress, setSelectedAddress] = useState(formData.adresse);
  const [loading, setLoading] = useState(true);
  const last_name = user.username;
  const firsts_name = user.email;
  const access = user.access;
  const user_id = user.id;
  const [selectedFile, setSelectedFile] = useState(null);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const etudeId = searchParams.get("etudeId");
    const fetchData = async () => {
      if (etudeId) {
        try {
          setLoading(true);
          const data = await fetchPostDetails(etudeId);
          setFormData(data.etude_data);
          setLoading(false);
          setId(data.etude_data.id);
        } catch (error) {
          console.error("Error fetching post details:", error);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchData();
  }, [location.search]);

  // Rest of the form component logic, using formData to populate form fields...
  const Item = ({ title, icon, value }) => (
    <div
      className={`item ${selected === value ? "selected" : ""}`}
      onClick={() => setSelected(value)}
    >
      <div className="icon">{icon}</div>
      <div className="title">{title}</div>
    </div>
  );

  const fetchAddress = async (address) => {
    try {
      const encodedAddress = encodeURIComponent(address);
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${encodedAddress}&key=AIzaSyBtkRt1QH4neWoFlbl_FPys-HxrDR7w1Lo`
      );
      const data = await response.json();
      if (data.results.length > 0) {
        const { lat, lng } = data.results[0].geometry.location;
        latitudeRef.current = lat;
        longitudeRef.current = lng;
        document.getElementById("latitudeInput").value = latitudeRef.current;
        document.getElementById("longitudeInput").value = longitudeRef.current;
      } else {
        toast.warning("No results found", {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    } catch (error) {}
  };

  const queryClient = useQueryClient();
  const createPostMutation = useMutation({
    mutationFn: createPost,
    onSuccess: useCallback(
      (data) => {
        queryClient.setQueryData(["posts", data.id], data);
        queryClient.invalidateQueries("posts", {
          predicate: (query) => {
            const updatedPost = query?.pages
              ?.flat()
              .find((post) => post?.id === data.id);
            return updatedPost != null;
          },
        });
      },
      [queryClient]
    ),
  });

  const createPostMutationEvalution = useMutation({
    mutationFn: createPostEvalution,
    onSuccess: useCallback(
      (data) => {
        const uniqueTimestamp = Date.now();
        const updatedImageUrls = data.image_url.map(
          (url) => `${url}?timestamp=${uniqueTimestamp}`
        );
        setImageUrls(updatedImageUrls);

        const postId = data.id;
        queryClient.setQueryData(["postsEvalution", postId], data);
        queryClient.invalidateQueries(["postsEvalution", postId]);
      },
      [queryClient]
    ),
  });
  const createPostMutationSaveEvalution = useMutation({
    mutationFn: SaveAllEvaluation,
    onSuccess: useCallback(
      (data) => {
        const uniqueTimestamp = Date.now();
        const updatedImageUrls = data.image_url.map(
          (url) => `${url}?timestamp=${uniqueTimestamp}`
        );

        setsaveImageUrls(updatedImageUrls);
        toast.success(data.message, { position: toast.POSITION.TOP_CENTER });
        const postId = data.id;
        queryClient.setQueryData(["postsEvalution", postId], data);
        queryClient.invalidateQueries(["postsEvalution", postId]);
      },
      [queryClient]
    ),
  });

  const createPostMutationStartTheAnalysis = useMutation({
    mutationFn: StartTheAnalysis,
    onSuccess: useCallback(
      (data) => {
        toast.success(data.message, { position: toast.POSITION.TOP_CENTER });
        const postId = data.id;
        queryClient.setQueryData(["postsEvalution", postId], data);
        queryClient.invalidateQueries(["postsEvalution", postId]);
      },
      [queryClient]
    ),
  });

  const handleSubmit1 = async (e) => {
    e.preventDefault();
    let postData = {};
    // Check if you have API response data (formData)
    if (formData && Object.keys(formData).length > 0) {
      postData = {
        nom_du_cient: formData?.nom_du_cient,
        nom_du_site: formData?.nom_du_site,
        adresse: formData?.adresse,
        latitude: formData?.latitude,
        longitude: formData?.longitude,
        start_year: formData?.start_year,
        end_year: formData?.end_year,
        firsts_name: firsts_name,
        last_name: last_name,
        access: access,
        user_id: user_id,
      };
    } else {
      // Populate postData with user input
      postData = {
        nom_du_cient: nom_du_cientRef?.current.value,
        nom_du_site: nom_du_siteRef.current.value,
        adresse: addresst?.description,
        latitude: latitudeRef.current,
        longitude: longitudeRef.current,
        start_year: start_yearRef.current.value,
        end_year: end_yearRef.current.value,
        firsts_name: firsts_name,
        last_name: last_name,
        access: access,
        user_id: user_id,
      };
    }
    try {
      const data = await createPostMutation.mutateAsync(postData);
      setId(data.data.id);
      toast.success(data.message, { position: toast.POSITION.TOP_CENTER });
    } catch (error) {
      if (error.response && error.response.data.message) {
        toast.warning(error.response.data.message, {
          position: toast.POSITION.TOP_CENTER,
        });
        // Check if 'data' exists in the error response
        if (error.response.data.data && error.response.data.data.id) {
          setId(error.response.data.data.id);
        }
      } else {
        toast.warning("An error occurred", {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    }
  };

  //

  const handleSubmitSaveAllEvaluation = async (e) => {
    e.preventDefault();
    const latitude =
      latitudeRef.current !== null ? latitudeRef.current : formData.latitude;

    // Check if longitudeRef.current has a value, otherwise fallback to formData.longitude
    const longitude =
      longitudeRef.current !== null ? longitudeRef.current : formData.longitude;
    const additionalParamSaveEvalution = {
      nom_du_cient: nom_du_cientRef.current.value,
      nom_du_site: nom_du_siteRef.current.value,
      adresse: addresst ? addresst?.description : formData.adresse,
      latitude: latitude,
      longitude: longitude,
      start_year: start_yearRef.current.value,
      end_year: end_yearRef.current.value,
      facade_de_batiment_01_e: +sendFacade01
        ? facade_de_batiment_01Ref.current.value
        : undefined,
      facade_de_batiment_02_e: +sendFacade02
        ? facade_de_batiment_02Ref.current.value
        : undefined,
      facade_de_batiment_03_e: +sendFacade03
        ? facade_de_batiment_03Ref.current.value
        : undefined,
      facade_de_batiment_04_e: +sendFacade04
        ? facade_de_batiment_04Ref.current.value
        : undefined,
      defaultValue_hauteurRef: +hauteurRef.current,
      etudes_id: +id,
      coeff: +defaultValue_coff,
      compny: +compnyRef.current,
      bat_hght: +bat_hghtRef,
      locatnRefdefault_valueRef: +facade_de_batiment_03Ref.current,
      locatn: +locatnRefdefault_valueRef,
      bat_orientatn: +bat_orientatnRef.current,
      z_zero: +z_zeroRefdefault_valueRef,
      isSaveEvlution: true,
      isAsNewEtude: selectedOptionnew === "0" ? true : false,
      EvauationdDEtails: details_meteo_mois_annees,
    };
    try {
      await createPostMutationSaveEvalution.mutate(
        additionalParamSaveEvalution
      );
    } catch (error) {
      toast.error(
        error.response?.data?.message,
        "veuillez remplir les chaumes pour evaluer",
        {
          position: toast.POSITION.TOP_CENTER,
        }
      );
    }
  };
  const handleSubmitfacade = async (e) => {
    e.preventDefault();
    const latitude =
      latitudeRef.current !== null ? latitudeRef.current : formData.latitude;

    // Check if longitudeRef.current has a value, otherwise fallback to formData.longitude
    const longitude =
      longitudeRef.current !== null ? longitudeRef.current : formData.longitude;
    const additionalParam = {
      firsts_name: firsts_name,
      last_name: last_name,
      access: access,
      user_id: user_id,
      nom_du_cient: nom_du_cientRef.current.value,
      nom_du_site: nom_du_siteRef.current.value,
      adresse: addresst ? addresst?.description : formData.adresse,
      latitude: latitude,
      longitude: longitude,
      start_year: start_yearRef.current.value,
      end_year: end_yearRef.current.value,
      etudes_id: +id,
      coeff: +defaultValue_coff,
      compny: +compnyRef.current,
      bat_hght: +bat_hghtRef,
      locatn: +locatnRefdefault_valueRef,
      bat_orientatn: +orientation,
      z_zero: +z_zeroRefdefault_valueRef,
      isevlution: true,
      isAsNewEtude: selectedOptionnew === "0" ? true : false, // Set isAsNewEtude based on selectedOption value
    };

    try {
      await createPostMutationEvalution.mutate(additionalParam);
    } catch (error) {
      if (
        error.response &&
        error.response.data.message &&
        error.response.data
      ) {
        toast.warning(error.response?.data?.message, {
          position: toast.POSITION.TOP_CENTER,
        });
      } else if (error.response && error.response.data) {
        toast.warning(error.response.data.message, {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    }
  };

  const handleSubmitStartTheAnalysis = async (e) => {
    e.preventDefault();
    const latitude =
      latitudeRef.current !== null ? latitudeRef.current : formData.latitude;

    // Check if longitudeRef.current has a value, otherwise fallback to formData.longitude
    const longitude =
      longitudeRef.current !== null ? longitudeRef.current : formData.longitude;
    const additionalParamSaveEvalution = {
      nom_du_cient: nom_du_cientRef.current.value,
      nom_du_site: nom_du_siteRef.current.value,
      adresse: addresst ? addresst?.description : formData.adresse,
      latitude: latitude,
      longitude: longitude,
      start_year: start_yearRef.current.value,
      end_year: end_yearRef.current.value,
      facade_de_batiment_01_e: +sendFacade01
        ? facade_de_batiment_01Ref.current.value
        : undefined,
      facade_de_batiment_02_e: +sendFacade02
        ? facade_de_batiment_02Ref.current.value
        : undefined,
      facade_de_batiment_03_e: +sendFacade03
        ? facade_de_batiment_03Ref.current.value
        : undefined,
      facade_de_batiment_04_e: +sendFacade04
        ? facade_de_batiment_04Ref.current.value
        : undefined,
      defaultValue_hauteurRef: +hauteurRef.current,
      etudes_id: +id,
      coeff: +defaultValue_coff,
      compny: +compnyRef.current,
      bat_hght: +bat_hghtRef,
      locatnRefdefault_valueRef: +facade_de_batiment_03Ref.current,
      locatn: +locatnRefdefault_valueRef,
      bat_orientatn: +bat_orientatnRef.current,
      z_zero: +z_zeroRefdefault_valueRef,
      isAnalysis: true,
      isAsNewEtude: selectedOptionnew === "0" ? true : false,
      process_year: sendAnnuelle,
      process_month: sendMensuelle,
      process_hour: sendHoraire,
    };
    try {
      await createPostMutationStartTheAnalysis.mutate(
        additionalParamSaveEvalution
      );
    } catch (error) {
      toast.error(
        error.response?.data?.message,
        "veuillez remplir les chaumes pour evaluer",
        {
          position: toast.POSITION.TOP_CENTER,
        }
      );
    }
  };

  //
  const handleInputChange = (event, setStateFunction) => {
    setStateFunction(event.target.value);
  };

  const handleOptionChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedOption(selectedValue);

    // Update z_zeroRefdefault_valueRef based on selectedValue
    if (optionToValueMapping.hasOwnProperty(selectedValue)) {
      SetZ_zeroRefdefault_valueRef(optionToValueMapping[selectedValue]);
    } else {
      // Handle invalid selectedValue here
    }
  };

  const handleOptionChangenew = (event) => {
    const value = event.target.value;

    // Handle the selected option value and set isAsNewEtude accordingly
    if (value === "0") {
      setIsAsNewEtude(true); // Set isAsNewEtude to true if "Enregistrer en tant que nouvel étudiant" is selected
      SetBat_hghtRef((prevValue) => prevValue + 7);
    } else {
      setIsAsNewEtude(false); // Set isAsNewEtude to false for other options
    }

    // Update the selected option state
    setSelectedOptionnew(value);
  };

  // const mutation = useMutation((file) => uploadCsvFile(file), {
  //   onSuccess: (data) => {
  //     // Handle successful upload
  //     toast.success("File uploaded successfully.");
  //   },
  //   onError: (error) => {
  //     // Handle upload error
  //     toast.error(`Upload failed: ${error.response.data.error}`);
  //   },
  // });
  const mutation = useMutation(
    ([file, additionalParams]) => uploadCsvFile(file, additionalParams),
    {
      onSuccess: (data) => {
        // Handle successful upload
        toast.success("File uploaded successfully.");
      },
      onError: (error) => {
        // Handle upload error
        toast.error(`Upload failed: ${error.response.data.error}`);
      },
    }
  );

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleUpload = () => {
    if (!selectedFile) {
      toast.warning("Please select a file to upload.");
      return;
    }
    const additionalParams = {
      EvauationdDEtails: EvauationdDEtails ? "true" : "false",
      process_year: process_year ? "true" : "false",
      process_month: process_month ? "true" : "false",
      process_hour: process_hour ? "true" : "false",
      firsts_name: firsts_name,
      last_name: last_name,
      access: access,
      user_id: user_id,
      // Include other relevant parameters here
    };

    // mutation.mutate(selectedFile); // was like <=
    mutation.mutate([selectedFile, additionalParams]);
  };

  return (
    <Box m="20px">
      <ToastContainer />
      <Header title="CREATE Study" subtitle="Create a New Study" />
      <Box display="flex" justifyContent="space-between" sx={{ mb: 2 }}>
        <Item
          title="Etude"
          // icon={<ReceiptOutlinedIcon />}
          value="form"
        />
        <Item
          title="Traitement par lot"
          // icon={<ReceiptOutlinedIcon />}
          value="calendar"
          color={colors.greenAccent[500]}
          // variant="h5"
          fontWeight="600"
        />
        <Item
          title="Options"
          // icon={<ReceiptOutlinedIcon />}
          value="faq"
        />
      </Box>
      {selected === "form" && (
        <Formik
          onSubmit={handleSubmit1}
          initialValues={initialValues}
          validationSchema={checkoutSchema}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
            handlePlaceSelect,
          }) => (
            <form onSubmit={handleSubmit1}>
              <Box
                display="grid"
                gap="30px"
                gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                sx={{
                  "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                }}
              >
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Entré le nom du client"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={formData ? formData.nom_du_cient : values.nom_du_cient}
                  name="nom_du_cient"
                  error={!!touched.nom_du_cient && !!errors.nom_du_cient}
                  helperText={touched.nom_du_cient && errors.nom_du_cient}
                  sx={{ gridColumn: "span 2" }}
                  inputRef={nom_du_cientRef}
                  id="nom_du_cient"
                  InputLabelProps={{ style: { marginBottom: "10px" } }} // Adjust marginBottom as needed
                  InputProps={{ style: { marginTop: "5px" } }}
                />
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Entré le nom du site à Etudier"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  // value={values.nom_du_site}
                  value={formData ? formData.nom_du_site : values.nom_du_site}
                  name="nom_du_site"
                  error={!!touched.nom_du_site && !!errors.nom_du_site}
                  helperText={touched.nom_du_site && errors.nom_du_site}
                  sx={{ gridColumn: "span 2" }}
                  inputRef={nom_du_siteRef}
                  id="nom_du_site"
                  InputLabelProps={{ style: { marginBottom: "10px" } }} // Adjust marginBottom as needed
                  InputProps={{ style: { marginTop: "5px" } }}
                />
                <Box
                  gridColumn="span 4"
                  variant="filled"
                  backgroundColor={colors.primary[400]}
                >
                  {/* Set width explicitly to match TextField */}
                  <GooglePlacesAutocomplete
                    flexGrow={1}
                    variant="filled"
                    apiKey="AIzaSyBtkRt1QH4neWoFlbl_FPys-HxrDR7w1Lo"
                    defaultValue={
                      formData.adresse ? formData.adresse : "Québec, QC, Canada"
                    }
                    selectProps={{
                      selectedAddress,
                      setSelectedAddress,
                      // value: formData.addresse,
                      onChange: (newValue) => {
                        if (
                          newValue &&
                          newValue.value &&
                          newValue.value.description
                        ) {
                          setSelectedCoordinates({
                            lat: latitudeRef.current,
                            lng: longitudeRef.current,
                          });
                          // setAddresst(newValue.value);
                          // const selectedAddress = formData.adresse || newValue.value;
                          const selectedAddress = formData.adresse
                            ? formData.adresse
                            : newValue.value;
                          setAddresst(selectedAddress);
                          fetchAddress(newValue.value.description);
                        } else {
                          setSelectedCoordinates({
                            lat: null,
                            lng: null,
                          });
                        }
                      },
                      styles: {
                        input: (provided) => ({
                          ...provided,
                          color: colors.grey[100],
                          marginTop: "25px",
                          variant: "filled",
                        }),
                        option: (provided) => ({
                          ...provided,
                          color: colors.grey[100],
                          backgroundColor: colors.primary[400],
                        }),
                        singleValue: (provided) => ({
                          ...provided,
                          color: colors.grey[100],
                        }),
                        control: (provided, state) => ({
                          ...provided,
                          color: colors.grey[100],
                          backgroundColor: "rgba(255, 255, 255, 0.09)",
                          borderColor: colors.primary[400],
                          "&:hover": {
                            borderColor: colors.primary[400],
                          },
                        }),
                      },
                    }}
                  />
                </Box>

                <TextField
                  fullWidth
                  label="latitude"
                  variant="filled"
                  type="text"
                  id="latitudeInput"
                  value={formData ? formData.latitude : latitudeRef.current}
                  disabled
                  sx={{ gridColumn: "span 2" }}
                  InputLabelProps={{ style: { marginBottom: "10px" } }}
                  InputProps={{ style: { marginTop: "5px" } }}
                />

                <TextField
                  fullWidth
                  label="longitude"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  variant="filled"
                  type="text"
                  id="longitudeInput"
                  value={formData ? formData.longitude : longitudeRef.current}
                  disabled
                  sx={{ gridColumn: "span 2" }}
                  InputProps={{ style: { marginTop: "5px" } }}
                />

                <TextField
                  fullWidth
                  variant="filled"
                  type="number"
                  label="Start Year"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  name="start_year"
                  error={!!touched.start_year && !!errors.start_year}
                  helperText={touched.start_year && errors.start_year}
                  sx={{ gridColumn: "span 2" }}
                  id="start_year"
                  inputRef={start_yearRef}
                  value={formData ? formData.start_year : values.start_year}
                  InputLabelProps={{ style: { marginBottom: "10px" } }} // Adjust marginBottom as needed
                  InputProps={{ style: { marginTop: "5px" } }}
                  inputProps={{
                    min: 0,
                    step: 1,
                    defaultValue: 2011,
                  }}
                />
                <TextField
                  fullWidth
                  variant="filled"
                  type="number"
                  id="end_year"
                  label="End Year"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  name="end_year"
                  inputRef={end_yearRef}
                  // value={values.end_year}
                  value={formData ? formData.end_year : values.end_year}
                  error={!!touched.end_year && !!errors.end_year}
                  helperText={touched.end_year && errors.end_year}
                  sx={{ gridColumn: "span 2" }}
                  InputLabelProps={{ style: { marginBottom: "10px" } }} // Adjust marginBottom as needed
                  InputProps={{ style: { marginTop: "5px" } }}
                  // inputProps={{
                  //   min: 0,
                  //   step: 1,
                  //   defaultValue: formData ? formData.end_year : 2022,

                  // }}
                />
              </Box>

              <Box
                display="flex"
                justifyContent="end"
                mt="20px"
                gap="20px"
                gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                sx={{
                  "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                }}
              >
                {/* {!createPostMutation.isLoading &&
  createPostMutation.isError && (
    <Select
      value={selectedOptionnew}
      onChange={handleOptionChangenew}
      displayEmpty
      variant="filled"
    >
      <MenuItem value="" disabled>
        Choisissez pour...
      </MenuItem>
      <MenuItem value="0">
        Enregistrer en tant que nouvel étudiant
      </MenuItem>
      <MenuItem value="1">
        Enregistrer en écrasant le résultat existant
      </MenuItem>
    </Select>
  )
} */}
                <div>
                  {!loading && (
                    <Select
                      value={selectedOptionnew}
                      onChange={handleOptionChangenew}
                      displayEmpty
                      variant="filled"
                    >
                      <MenuItem value="" disabled>
                        Choisissez pour...
                      </MenuItem>
                      <MenuItem value="0">
                        Enregistrer en tant que nouvel étudiant
                      </MenuItem>
                      <MenuItem value="1">
                        Enregistrer en écrasant le résultat existant
                      </MenuItem>
                    </Select>
                  )}
                </div>

                <Button type="submit" color="secondary" variant="contained">
                  {createPostMutation.isLoading ? (
                    <span className="loading-text">Loading... </span>
                  ) : (
                    "Télecherge"
                  )}
                </Button>

                {/* {createPostMutation.isLoading && (
                  <Button type="submit" color="secondary" variant="contained">
                    <span className="loading-text">Loading... </span>
                  </Button>
                )} */}
              </Box>

              <Typography>Paramétrage de l'etude</Typography>
              {/* RoOW */}
              <Box
                display="grid"
                gap="20px"
                gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                sx={{
                  "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                }}
              >
                <TextField
                  fullWidth
                  variant="filled"
                  type="number"
                  id="hauteur"
                  label="Hauteur (m)"
                  onBlur={handleBlur}
                  onChange={(event) => handleInputChange(event, SetBat_hghtRef)}
                  inputProps={{
                    min: 0,
                    step: 1,
                    value: bat_hghtRef,
                  }}
                  sx={{ gridColumn: "span 1" }}
                />

                <Select
                  value={selectedOption}
                  onChange={handleOptionChange}
                  displayEmpty
                  variant="filled"
                >
                  <MenuItem value="" disabled>
                    8 = z0=1.2000
                  </MenuItem>
                  <MenuItem value="0">0 = z0=0.0002</MenuItem>
                  <MenuItem value="1">1 = z0=0.0024</MenuItem>
                  <MenuItem value="2">2 = z0=0.0300</MenuItem>
                  <MenuItem value="3">3 = z0=0.0550</MenuItem>
                  <MenuItem value="4">4 = z0=0.1000</MenuItem>
                  <MenuItem value="5">5 = z0=0.2000</MenuItem>
                  <MenuItem value="6">6 = z0=0.5000</MenuItem>
                  <MenuItem value="7">7 = z0=0.7500</MenuItem>
                  <MenuItem value="8">8 = z0=1.2000</MenuItem>
                </Select>

                <TextField
                  fullWidth
                  variant="filled"
                  type="number"
                  id="hauteur"
                  label="z0(m)"
                  name="hauteur"
                  onBlur={handleBlur}
                  onChange={(event) =>
                    handleInputChange(event, SetZ_zeroRefdefault_valueRef)
                  }
                  inputProps={{
                    min: 0.0002,
                    step: 0.0002,
                    value: z_zeroRefdefault_valueRef,
                  }}
                  sx={{ gridColumn: "span 1" }}
                />
              </Box>
              {/*  */}
              <Typography> Orientation de façades</Typography>

              <Box
                display="flex"
                flexDirection={isMobile ? "column" : "row"}
                gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                gap="4px"
                sx={{
                  "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={sendFacade01}
                      onChange={() => setSendFacade01(!sendFacade01)}
                    />
                  }
                  sx={{
                    "& .MuiCheckbox-root": {
                      color: `${colors.greenAccent[200]} !important`,
                    },
                  }}
                />

                <TextField
                  fullWidth
                  variant="filled"
                  type="number"
                  id="facade_de_batiment_01"
                  label="façades 1"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  sx={{ gridColumn: "span 1" }}
                  inputProps={{
                    min: 0,
                    step: 1,
                    defaultValue: 0,
                  }}
                  inputRef={facade_de_batiment_01Ref}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={sendFacade02}
                      onChange={() => setSendFacade02(!sendFacade02)}
                    />
                  }
                  sx={{
                    "& .MuiCheckbox-root": {
                      color: `${colors.greenAccent[200]} !important`,
                    },
                  }}
                />

                <TextField
                  fullWidth
                  variant="filled"
                  type="number"
                  id="facade_de_batiment_02"
                  label="façades 2"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  name="start_year"
                  sx={{ gridColumn: "span 1" }}
                  inputProps={{
                    min: 0,
                    step: 1,
                    defaultValue: 90,
                  }}
                  inputRef={facade_de_batiment_02Ref}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={sendFacade03}
                      onChange={() => setSendFacade03(!sendFacade03)}
                    />
                  }
                  sx={{
                    "& .MuiCheckbox-root": {
                      color: `${colors.greenAccent[200]} !important`,
                    },
                  }}
                />
                <TextField
                  fullWidth
                  variant="filled"
                  type="number"
                  id="facade_de_batiment_03"
                  label="façades 3"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  sx={{ gridColumn: "span 1" }}
                  inputProps={{
                    min: 0,
                    step: 1,
                    defaultValue: 180,
                  }}
                  inputRef={facade_de_batiment_03Ref} // This is how you pass the ref
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={sendFacade04}
                      onChange={() => setSendFacade04(!sendFacade04)}
                    />
                  }
                  sx={{
                    "& .MuiCheckbox-root": {
                      color: `${colors.greenAccent[200]} !important`,
                    },
                  }}
                />
                <TextField
                  fullWidth
                  variant="filled"
                  type="number"
                  id="facade_de_batiment_04"
                  label="façades 4"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  sx={{ gridColumn: "span 1" }}
                  inputProps={{
                    min: 0,
                    step: 1,
                    defaultValue: 270,
                  }}
                  inputRef={facade_de_batiment_04Ref}
                />
              </Box>
              {/* la fin */}
              <Typography>Etude</Typography>
              <Box
                display="flex"
                flexDirection="row"
                flexWrap="wrap"
                gap="20px"
              >
                {/*  */}
                {createPostMutationSaveEvalution.isLoading ||
                createPostMutationEvalution.isLoading ? (
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    // height={420}
                    backgroundColor={colors.primary[400]}
                    flexGrow={1}
                    flexBasis="30%"
                  >
                    <Typography variant="body1">
                      Vous trouverez ici les résultats de l'évaluation :{" "}
                    </Typography>
                  </Box>
                ) : createPostMutationSaveEvalution.isSuccess ? (
                  saveImageUrls.map((imageUrl, index) => (
                    <img
                      key={index}
                      src={imageUrl}
                      alt={`Saved Rose ${index}`}
                      width="600"
                      height="420"
                    />
                  ))
                ) : createPostMutationEvalution.isSuccess ? (
                  imageUrls.map((imageUrl, index) => (
                    <img
                      key={index}
                      src={imageUrl}
                      alt={`Rose ${index}`}
                      width="600"
                      height="420"
                    />
                  ))
                ) : (
                  <span></span>
                )}

                {/* last row 2 */}
                <Box
                  gridColumn="span 8"
                  gridRow="span 2"
                  backgroundColor={colors.primary[400]}
                  flexGrow={1}
                  flexBasis="30%"
                >
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    borderBottom={`4px solid ${colors.primary[500]}`}
                    p="15px"
                  >
                    <Box>
                      <Typography
                        color={colors.greenAccent[500]}
                        variant="h5"
                        fontWeight="600"
                      >
                        Evauation
                      </Typography>

                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={details_meteo_mois_annees}
                            onChange={() =>
                              setDetails_meteo_mois_annees(
                                !details_meteo_mois_annees
                              )
                            }
                          />
                        }
                        label="Détails météo (mois, années)."
                        sx={{
                          "& .MuiCheckbox-root": {
                            color: `${colors.greenAccent[200]} !important`,
                          },
                        }}
                      />
                    </Box>

                    <Box display="flex" justifyContent="end" mt="20px">
                      <Button
                        type="submit"
                        color="secondary"
                        variant="contained"
                        onClick={handleSubmitfacade}
                      >
                        {createPostMutationEvalution.isLoading ? (
                          <span className="loading-text">Loading... </span>
                        ) : (
                          "Evaluer"
                        )}
                      </Button>
                    </Box>

                    <Box display="flex" justifyContent="end" mt="20px">
                      {/* {!createPostMutation.isLoading &&
  (createPostMutation.isError || createPostMutation.isSuccess) && ( */}
                      <Button
                        type="submit"
                        color="secondary"
                        variant="contained"
                        disabled={createPostMutationSaveEvalution.isLoading}
                        onClick={handleSubmitSaveAllEvaluation}
                      >
                        {createPostMutationSaveEvalution.isLoading ? (
                          <span className="loading-text">Loading... </span>
                        ) : (
                          "    Enregistré"
                        )}
                      </Button>
                      {/* ) } */}
                    </Box>
                  </Box>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    borderBottom={`4px solid ${colors.primary[500]}`}
                    p="15px"
                  >
                    <Box>
                      <Typography
                        color={colors.greenAccent[500]}
                        variant="h5"
                        fontWeight="600"
                      >
                        Analyse détaillée
                      </Typography>

                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={sendAnnuelle}
                            onChange={() => setSendAnnuelle(!sendAnnuelle)}
                          />
                        }
                        label="Annuelle"
                        sx={{
                          "& .MuiCheckbox-root": {
                            color: `${colors.greenAccent[200]} !important`,
                          },
                        }}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={sendMensuelle}
                            onChange={() => setSendMensuelle(!sendMensuelle)}
                          />
                        }
                        label="Mensuelle"
                        sx={{
                          "& .MuiCheckbox-root": {
                            color: `${colors.greenAccent[200]} !important`,
                          },
                        }}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={sendHoraire}
                            onChange={() => setSendHoraire(!sendHoraire)}
                          />
                        }
                        label="Horaire"
                        sx={{
                          "& .MuiCheckbox-root": {
                            color: `${colors.greenAccent[200]} !important`,
                          },
                        }}
                      />

                      <Box display="flex" justifyContent="end" mt="20px">
                        {/* {!createPostMutation.isLoading &&
                      (createPostMutation.isError || createPostMutation.isSuccess) && ( */}
                        <Button
                          type="submit"
                          color="secondary"
                          variant="contained"
                          disabled={
                            createPostMutationStartTheAnalysis.isLoading
                          }
                          onClick={handleSubmitStartTheAnalysis}
                        >
                          {createPostMutationStartTheAnalysis.isLoading ? (
                            <span className="loading-text">Loading... </span>
                          ) : (
                            "Lancer l'analise"
                          )}
                        </Button>
                        {/* )
  } */}
                      </Box>
                    </Box>
                  </Box>
                  <Box>
                    <Typography
                      color={colors.greenAccent[500]}
                      variant="h5"
                      fontWeight="600"
                    >
                      Ouverture des résultats
                    </Typography>

                    {createPostMutationStartTheAnalysis.data ? (
                      // If there is data in createPostMutationEvalution
                      <Box display="flex" justifyContent="center">
                        {createPostMutationStartTheAnalysis.isSuccess ? (
                          // If the mutation was successful
                          <Box
                            display="flex"
                            justifyContent="center"
                            to={`/post/${createPostMutationStartTheAnalysis.data.postId}`}
                          >
                            <Button
                              type="submit"
                              color="secondary"
                              variant="contained"
                            >
                              <Link
                                to={`/details/${id}`}
                                style={{
                                  textDecoration: "none",
                                  variant: "contained",
                                }}
                              >
                                {" "}
                                Aller au dossier
                              </Link>
                            </Button>
                          </Box>
                        ) : (
                          // If the mutation was not successful
                          <CircularProgress />
                        )}
                      </Box>
                    ) : (
                      <Box display="flex" justifyContent="center">
                        <Typography variant="body1">
                          Vous trouverez ici les résultats de l'analise
                        </Typography>
                      </Box>
                    )}
                  </Box>
                </Box>
              </Box>
            </form>
          )}
        </Formik>
      )}
      {/* TRAITEMENT*/}
      {selected === "calendar" && (
        <Box m="2px">
          <Formik
            onSubmit={handleSubmit1}
            initialValues={initialValues}
            validationSchema={checkoutSchema}
          >
            {({
              values,
              errors,
              touched,
              handleBlur,
              handleChange,
              handleSubmit,
              handlePlaceSelect,
            }) => (
              <form onSubmit={handleSubmit1}>
                <Box
                  height={420}
                  backgroundColor={colors.primary[400]}
                  flexGrow={1}
                  display="grid"
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={EvauationdDEtails}
                        onChange={() =>
                          setEvauationdDEtails(!EvauationdDEtails)
                        }
                      />
                    }
                    label=" Export des roses des vents annuelles, mensuelles et de
                    l'hisorigramme."
                    sx={{
                      "& .MuiCheckbox-root": {
                        color: `${colors.greenAccent[200]} !important`,
                        ml: 5,
                      },
                    }}
                  />
                  {/*  */}
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={process_year}
                        onChange={() => setprocess_year(!process_year)}
                      />
                    }
                    label="Export CSV de la production annuelle."
                    sx={{
                      "& .MuiCheckbox-root": {
                        color: `${colors.greenAccent[200]} !important`,
                        ml: 5,
                      },
                    }}
                  />
                  {/*  */}
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={process_month}
                        onChange={() => setprocess_month(!process_month)}
                      />
                    }
                    label="Export CSV de la production mensuelle"
                    sx={{
                      "& .MuiCheckbox-root": {
                        color: `${colors.greenAccent[200]} !important`,
                        ml: 5,
                      },
                    }}
                  />
                  {/*  */}
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={process_hour}
                        onChange={() => setprocess_hour(!process_hour)}
                      />
                    }
                    label="Export CSV de la production horaire. :"
                    sx={{
                      "& .MuiCheckbox-root": {
                        color: `${colors.greenAccent[200]} !important`,
                        ml: 5,
                        mt: 2,
                      },
                    }}
                  />

                  {/* <div mt={2}>
                    <input type="file" onChange={handleFileChange} />
                    <button
                      onClick={handleUpload}
                      disabled={mutation.isLoading}
                    >
                      {mutation.isLoading ? "Uploading..." : "Upload File"}
                    </button>
                  </div> */}

                  <Box
                    mt={2}
                    flexGrow={1}
                    style={{ display: "flex", alignItems: "flex-end" }}
                    ml={5}
                  >
                    <input
                      type="file"
                      onChange={handleFileChange}
                      style={{ display: "none" }}
                      id="file-input"
                    />
                    <label htmlFor="file-input">
                      <Button variant="contained" component="span">
                        Select File
                      </Button>
                    </label>
                    <Box mt={2} ml={7}>
                      <Button
                        variant="contained"
                        onClick={handleUpload}
                        disabled={!selectedFile || isUploading}
                        startIcon={
                          isUploading ? <CircularProgress size={20} /> : null
                        }
                      >
                        {isUploading ? "Uploading..." : "Upload File"}
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </form>
            )}
          </Formik>
        </Box>
      )}
      {/* OPTION */}
      {selected === "faq" && (
        <Box m="2px">
          <Formik
            onSubmit={handleSubmit1}
            initialValues={initialValues}
            validationSchema={checkoutSchema}
          >
            {({
              values,
              errors,
              touched,
              handleBlur,
              handleChange,
              handleSubmit,
              handlePlaceSelect,
            }) => (
              <form onSubmit={handleSubmit1}>
                <Box
                  display="flex"
                  flexDirection={isMobile ? "column" : "row"}
                  gap="20px"
                  gridTemplateColumns="repeat(6, minmax(0, 1fr))"
                  sx={{
                    "& > div": {
                      gridColumn: isNonMobile ? undefined : "span 4",
                    },
                  }}
                >
                  <Box
                    p="10px"
                    height={420}
                    backgroundColor={colors.primary[400]}
                    flexGrow={1}
                    flexBasis="30%"
                    gridColumn="span 4"
                    gridRow="span 2"
                  >
                    <Typography
                      color={colors.greenAccent[500]}
                      variant="h5"
                      fontWeight="600"
                    >
                      Paramétrage de simulation
                    </Typography>
                    <Box sx={{ mt: 2 }}>
                      <TextField
                        fullWidth
                        variant="filled"
                        type="number"
                        id="Vitesse_minimale"
                        label="Vitesse minimale(m/s)"
                        onBlur={handleBlur}
                        onChange={(event) =>
                          handleInputChange(event, setSimulationVitesseMinimale)
                        }
                        sx={{ gridColumn: "span 1", mt: 2 }}
                        inputProps={{
                          min: 0,
                          step: 1,
                          value: simulationVitesseMinimale,
                        }}
                      />
                      <TextField
                        fullWidth
                        variant="filled"
                        type="number"
                        id="Vitesse-minimale"
                        label="Vitesse maximale (m/s)"
                        onBlur={handleBlur}
                        onChange={(event) =>
                          handleInputChange(event, setSimulationVitesseMaximale)
                        }
                        sx={{ gridColumn: "span 1", mt: 2 }}
                        inputProps={{
                          min: 0,
                          step: 1,
                          value: simulationVitesseMaximale,
                        }}
                      />
                      <Divider sx={{ my: 2 }} />

                      <TextField
                        fullWidth
                        variant="filled"
                        type="number"
                        id="Coefficien"
                        label="Coefficient d'accélération en roiture"
                        onBlur={handleBlur}
                        onChange={(event) =>
                          handleInputChange(
                            event,
                            setCoefficient_d_accélération
                          )
                        }
                        sx={{ gridColumn: "span 1" }}
                        inputProps={{
                          min: 0,
                          step: 1,

                          value: Coefficient_d_accélération,
                        }}
                      />
                      <Divider sx={{ my: 2 }} />
                      <Typography
                        color={colors.greenAccent[500]}
                        variant="h5"
                        fontWeight="600"
                      >
                        Paramétrage de calcul
                      </Typography>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={Calcul_parallele_experimental}
                            onChange={() =>
                              setCalcul_parallele_experimental(
                                !Calcul_parallele_experimental
                              )
                            }
                          />
                        }
                        label="Calcul parallèle (expérimental)"
                        sx={{
                          "& .MuiCheckbox-root": {
                            color: `${colors.greenAccent[200]} !important`,
                          },
                        }}
                      />
                    </Box>
                  </Box>

                  <Box
                    height={420}
                    backgroundColor={colors.primary[400]}
                    flexGrow={1}
                    flexBasis="30%"
                    gridColumn="span 4"
                    gridRow="span 2"
                  >
                    <Typography
                      color={colors.greenAccent[500]}
                      variant="h5"
                      fontWeight="600"
                      sx={{ mt: 2 }}
                    >
                      Paramétre de sortie
                    </Typography>
                    <Box
                      backgroundColor={colors.primary[400]}
                      flexGrow={1}
                      flexBasis="30%"
                      gridRow="span 2"
                      p="10px"
                      display="grid"
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={analyse_det_horaire_mois_par_mois}
                            onChange={() =>
                              setAnalyse_det_horaire_mois_par_mois(
                                !analyse_det_horaire_mois_par_mois
                              )
                            }
                          />
                        }
                        label="Analyse détaillée horaire mois par mois."
                        sx={{
                          "& .MuiCheckbox-root": {
                            color: `${colors.greenAccent[200]} !important`,
                          },
                        }}
                      />
                      {/*  */}
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={analyse_det_journaliere}
                            onChange={() =>
                              setAnalyse_det_journaliere(
                                !analyse_det_journaliere
                              )
                            }
                          />
                        }
                        label="Analyse détaillée journalière"
                        sx={{
                          "& .MuiCheckbox-root": {
                            color: `${colors.greenAccent[200]} !important`,
                          },
                        }}
                      />
                      <Divider sx={{ my: 2 }} />
                      {/*  */}
                      <TextField
                        fullWidth
                        variant="filled"
                        type="number"
                        id="hauteur"
                        label="Résolution des roses des vents.(°)"
                        onBlur={handleBlur}
                        onChange={(event) =>
                          handleInputChange(
                            event,
                            setResolution_des_roses_des_vents
                          )
                        }
                        sx={{ gridColumn: "span 1" }}
                        inputProps={{
                          min: 0,
                          step: 1,
                          value: Resolution_des_roses_des_vents,
                        }}
                      />
                      <TextField
                        fullWidth
                        variant="filled"
                        type="number"
                        id="hauteur"
                        label="Résolution fréquentielle (%)"
                        onBlur={handleBlur}
                        onChange={(event) =>
                          handleInputChange(event, SetResolution_frequentielle)
                        }
                        sx={{ gridColumn: "span 1", mt: 2 }}
                        inputProps={{
                          min: 0,
                          step: 1,
                          value: Resolution_frequentielle,
                        }}
                      />
                      <TextField
                        fullWidth
                        variant="filled"
                        type="number"
                        id="hauteur"
                        label="Résolution de la vitesse.(m/s)"
                        onBlur={handleBlur}
                        onChange={(event) =>
                          handleInputChange(event, SetResolution_de_la_vitesse)
                        }
                        sx={{ gridColumn: "span 1", mt: 2 }}
                        inputProps={{
                          min: 0.0,
                          step: 1.5,
                          value: Resolution_de_la_vitesse,
                        }}
                      />
                      <Divider sx={{ my: 2 }} />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={limite_custom_frequence_rose_de_vents}
                            onChange={() =>
                              setLimite_custom_frequence_rose_de_vents(
                                !limite_custom_frequence_rose_de_vents
                              )
                            }
                          />
                        }
                        label="Limite custom fréquence rose de vents"
                        sx={{
                          "& .MuiCheckbox-root": {
                            color: `${colors.greenAccent[200]} !important`,
                          },
                        }}
                      />
                      <TextField
                        fullWidth
                        variant="filled"
                        type="number"
                        id="hauteur"
                        label="pourcentage [%]"
                        onBlur={handleBlur}
                        onChange={(event) =>
                          handleInputChange(event, SetPourcentage)
                        }
                        sx={{ gridColumn: "span 1" }}
                        inputProps={{
                          min: 0,
                          step: 1,

                          value: pourcentage,
                        }}
                      />
                      {/*  */}
                      <Divider sx={{ my: 2 }} />

                      <TextField
                        fullWidth
                        variant="filled"
                        type="number"
                        id="Inculinaision"
                        label="Inculinaision PV(°)"
                        onBlur={handleBlur}
                        onChange={(event) =>
                          handleInputChange(event, SetInculinaision)
                        }
                        sx={{ gridColumn: "span 1" }}
                        inputProps={{
                          min: 0,
                          step: 1,
                          value: inculinaision,
                        }}
                      />
                      <TextField
                        fullWidth
                        variant="filled"
                        type="number"
                        id="Orientation"
                        label="Orientation (°)"
                        onBlur={handleBlur}
                        onChange={(event) =>
                          handleInputChange(event, SetOrientation)
                        }
                        sx={{ gridColumn: "span 1", mt: 2 }}
                        inputProps={{
                          min: 0,
                          step: 1,

                          value: orientation,
                        }}
                      />
                    </Box>
                  </Box>
                </Box>
              </form>
            )}
          </Formik>
        </Box>
      )}
    </Box>
  );
};

const phoneRegExp =
  /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

const checkoutSchema = yup.object().shape({
  nom_du_cient: yup.string().required("required"),
  nom_du_site: yup.string().required("required"),
  email: yup.string().email("invalid email").required("required"),
  start_year: yup
    .date()
    .min("1999")
    .required("invalid start_year")
    .required("required"),
  end_year: yup.date().min("1999").required("required"),
  contact: yup
    .string()
    .matches(phoneRegExp, "Phone number is not valid")
    .required("required"),
  address1: yup.string().required("required"),
  address2: yup.string().required("required"),
});
const initialValues = {
  nom_du_cient: "",
  nom_du_site: "",
  addresse: "",
  latitude: "",
  longitude: "",
  start_year: "",
  end_year: "",
};
export default Form;

// import React, { useState, useEffect, useRef } from "react";
// import { Box, useTheme, useMediaQuery } from "@mui/material";
// import { useMutation, useQueryClient } from "react-query";
// import { fetchPostDetails, getUserFromLocalStorage } from "../../services/etude";
// import { useLocation } from "react-router-dom";
// import { tokens } from "../../theme";
// import { Formik } from "formik";
// import * as yup from "yup";
// import { toast, ToastContainer } from "react-toastify";

// import FormHeader from "../../components/Form/FormHeader";
// import FormFields from "../../components/Form/FormFields";
// import FormAddress from "../../components/Form/FormAddress";
// import FormButtons from "../../components/Form/FormButtons";
// import FormParametrage from "../../components/Form/FormParametrage";
// import FormAnalyse from "../../components/Form/FormAnalyse";

// const Form = () => {
//   const isNonMobile = useMediaQuery("(min-width:600px)");
//   const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
//   const theme = useTheme();
//   const colors = tokens(theme.palette.mode);
//   const user = getUserFromLocalStorage();
//   const location = useLocation();
//   const [formData, setFormData] = useState({});
//   const [loading, setLoading] = useState(true);
//   const [selectedOption, setSelectedOption] = useState("");

//   useEffect(() => {
//     const searchParams = new URLSearchParams(location.search);
//     const etudeId = searchParams.get("etudeId");
//     const fetchData = async () => {
//       if (etudeId) {
//         try {
//           setLoading(true);
//           const data = await fetchPostDetails(etudeId);
//           setFormData(data.etude_data);
//           setLoading(false);
//         } catch (error) {
//           console.error("Error fetching post details:", error);
//         } finally {
//           setLoading(false);
//         }
//       }
//     };

//     fetchData();
//   }, [location.search]);

//   const handleSubmit1 = async (e) => {
//     e.preventDefault();
//     // Handle the form submission logic
//   };

//   const handlePlaceSelect = (place) => {
//     // Handle place selection logic
//   };

//   const handleChange = (event) => {
//     // Handle change logic
//     setSelectedOption(event.target.value);
//   };

//   const phoneRegExp = /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

//   return (
//     <Box m="20px">
//       <ToastContainer />
//       <FormHeader title="CREATE Study" subtitle="Create a New Study" />
//       <Formik initialValues={initialValues} validationSchema={checkoutSchema} onSubmit={handleSubmit1}>
//         {({ handleSubmit }) => (
//           <form onSubmit={handleSubmit}>
//             <FormFields formData={formData} initialValues={initialValues} isNonMobile={isNonMobile} />
//             <FormAddress formData={formData} handlePlaceSelect={handlePlaceSelect} />
//             <FormButtons handleSubmit={handleSubmit} handleChange={handleChange} selectedOption={selectedOption} loading={loading} />
//             <FormParametrage isNonMobile={isNonMobile} isMobile={isMobile} />
//             <FormAnalyse />
//           </form>
//         )}
//       </Formik>
//     </Box>
//   );
// };

// const initialValues = {
//   nom_du_cient: "",
//   nom_du_site: "",
//   addresse: "",
//   latitude: "",
//   longitude: "",
//   start_year: "",
//   end_year: "",
// };
// const phoneRegExp =
//   /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

// const checkoutSchema = yup.object().shape({
//   nom_du_cient: yup.string().required("required"),
//   nom_du_site: yup.string().required("required"),
//   email: yup.string().email("invalid email").required("required"),
//   start_year: yup.date().min("1999").required("invalid start_year").required("required"),
//   end_year: yup.date().min("1999").required("required"),
//   contact: yup.string().matches(phoneRegExp, "Phone number is not valid").required("required"),
//   address1: yup.string().required("required"),
//   address2: yup.string().required("required"),
// });

// export default Form;
