// // import React from 'react';
// import React, { useState } from "react";
// import { Box, Button, TextField, Typography, Container, useTheme } from "@mui/material";
// import Avatar from "@mui/material/Avatar";
// import AddLocationIcon from "@mui/icons-material/AddLocation";
// import { toast, ToastContainer } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
// import { createCity } from "../services/cityService"; // Ensure this import points to your createCity function
// import { tokens } from "../theme";

// const AddCity = ({ onClose, refetch }) => {
//   const theme = useTheme();
//   const colors = tokens(theme.palette.mode);

//   const [formData, setFormData] = useState({
//     city_name: "",
//     region: "",
//     country: "",
//   });

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({ ...formData, [name]: value });
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     try {
//       const response =  await createCity(formData);
//       console.log(response.data.city_name, 'success');
//       toast.success("City added successfully", {
//         position:  { position: "top-center" },
//       });
//       setFormData({ city_name: "", region: "", country: "" });
//       refetch(); // Refresh the city data
//       onClose(); // Close the modal
//     } catch (error) {
//       console.log(error);
//       toast.error(error, {
//         position:  { position: "top-center" },
//       });
//     }
//   };

//   return (
//     <Container component="main" maxWidth="xs">
//       <ToastContainer />
//       <Box
//         sx={{
//           marginTop: 8,
//           display: "flex",
//           flexDirection: "column",
//           alignItems: "center",
//           backgroundColor: colors.primary[400],
//           padding: theme.spacing(4),
//           borderRadius: 2,
//           boxShadow: 3,
//         }}
//       >
//         <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
//           <AddLocationIcon />
//         </Avatar>
//         <Typography component="h1" variant="h5" color={colors.grey[100]}>
//           Add City
//         </Typography>
//         <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
//           <TextField
//             margin="normal"
//             required
//             fullWidth
//             id="city_name"
//             label="City Name"
//             name="city_name"
//             autoFocus
//             value={formData.city_name}
//             onChange={handleChange}
//             InputLabelProps={{
//               style: { color: colors.grey[100] },
//             }}
//             InputProps={{
//               style: { color: colors.grey[100] },
//             }}
//           />
//           <TextField
//             margin="normal"
//             required
//             fullWidth
//             id="region"
//             label="Region"
//             name="region"
//             value={formData.region}
//             onChange={handleChange}
//             InputLabelProps={{
//               style: { color: colors.grey[100] },
//             }}
//             InputProps={{
//               style: { color: colors.grey[100] },
//             }}
//           />
//           <TextField
//             margin="normal"
//             required
//             fullWidth
//             id="country"
//             label="Country"
//             name="country"
//             value={formData.country}
//             onChange={handleChange}
//             InputLabelProps={{
//               style: { color: colors.grey[100] },
//             }}
//             InputProps={{
//               style: { color: colors.grey[100] },
//             }}
//           />
//           <Button
//             type="submit"
//             fullWidth
//             variant="contained"
//             sx={{
//               mt: 3,
//               mb: 2,
//               backgroundColor: colors.blueAccent[700],
//               color: colors.grey[100],
//             }}
//           >
//             Add City
//           </Button>
//         </Box>
//       </Box>
//     </Container>
//   );
// };

// export default AddCity;
import React, { useState } from "react";
import { Box, Button, TextField, Typography, Container, useTheme } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import AddLocationIcon from "@mui/icons-material/AddLocation";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { createCity } from "../services/cityService";
import { tokens } from "../theme";

const AddCity = ({ onClose }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [formData, setFormData] = useState({
    city_name: "",
    region: "",
    country: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await createCity(formData);
      if (response.message) {
        toast.success(response.message, {
          position: "top-center",
        });
      } else {
        toast.error("Unexpected success response format", {
          position: "top-center",
        });
      }
      // Reset the form, refresh the data, and close the modal
      setFormData({ city_name: "", region: "", country: "" });
      // refetch(); // Refresh the city data
      onClose(); // Close the modal
    } catch (error) {
      if (error.detail) {
        toast.error(error.detail, {
          position: "top-center",
        });
      } else if (error.city_name || error.region || error.country) {
        // Collect all the field-specific error messages
        const messages = [];
        if (error.city_name) messages.push(`City Name: ${error.city_name.join(', ')}`);
        if (error.region) messages.push(`Region: ${error.region.join(', ')}`);
        if (error.country) messages.push(`Country: ${error.country.join(', ')}`);
        toast.error(messages.join(' | '), {
          position: "top-center",
        });
      } else {
        toast.error("Failed to create city", {
          position: "top-center",
        });
      }
    }
  };
  

  return (
    <Container component="main" maxWidth="xs">
      <ToastContainer /> {/* Ensure ToastContainer is included */}
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          backgroundColor: colors.primary[400],
          padding: theme.spacing(4),
          borderRadius: 2,
          boxShadow: 3,
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
          <AddLocationIcon />
        </Avatar>
        <Typography component="h1" variant="h5" color={colors.grey[100]}>
          Add City
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="city_name"
            label="City Name"
            name="city_name"
            autoFocus
            value={formData.city_name}
            onChange={handleChange}
            InputLabelProps={{
              style: { color: colors.grey[100] },
            }}
            InputProps={{
              style: { color: colors.grey[100] },
            }}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="region"
            label="Region"
            name="region"
            value={formData.region}
            onChange={handleChange}
            InputLabelProps={{
              style: { color: colors.grey[100] },
            }}
            InputProps={{
              style: { color: colors.grey[100] },
            }}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="country"
            label="Country"
            name="country"
            value={formData.country}
            onChange={handleChange}
            InputLabelProps={{
              style: { color: colors.grey[100] },
            }}
            InputProps={{
              style: { color: colors.grey[100] },
            }}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="secondary"
            sx={{
              mt: 3,
              mb: 2,
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
            }}
          >
            Add City
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default AddCity;



