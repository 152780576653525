import React, { useState, useEffect } from "react";
import { Box, Button, Typography, useTheme, Modal } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import SecurityOutlinedIcon from "@mui/icons-material/SecurityOutlined";
import Header from "../../components/Header";
import { useInfiniteQuery } from "react-query";
import { getAllUsers } from "../../services/user";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import Register from "../../components/Register"; // Ensure this import points to your Register component

const Users = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [open, setOpen] = useState(false);

  const {
    status,
    error,
    data,
    refetch,
  } = useInfiniteQuery({
    queryKey: ["users", "infinite"],
    getNextPageParam: (lastPage) => lastPage.nextPage ?? false,
    queryFn: ({ pageParam = 1 }) => getAllUsers(pageParam),
  });

  useEffect(() => {
    refetch();
  }, [refetch]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  if (status === "loading") return <h1>Loading...</h1>;
  if (status === "error") return <h1>{JSON.stringify(error)}</h1>;
  
  
  const rows = data.pages.flatMap((page) =>
    page.posts.map((post) => ({
      id: post.id,
      firsts_name: post.firsts_name,
      last_name: post.last_name,
      etudes_count: post.etudes_count,
      access: post.access,
      createdAt: post.createdAt,
      updatedAt: post.updatedAt,
    }))
  );

  const columns = [
    { field: "id", headerName: "ID" },
    {
      field: "firsts_name",
      headerName: "First Name",
      cellClassName: "name-column--cell",
    },
    {
      field: "etudes_count",
      headerName: "Etudes Count",
      type: "number",
      flex: 1,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "last_name",
      headerName: "Last Name",
      flex: 1,
    },
    // {
    //   field: "createdAt",
    //   headerName: "Created At",
    //   type: "date",
    //   headerAlign: "left",
    //   align: "left",
    //   valueGetter: ({ value }) => value && new Date(value),  // Transform the string to a Date object
    // },
    {
      field: "access",
      headerName: "Access",
      flex: 1,
      renderCell: ({ row: { access } }) => {
        return (
          <Box
            width="60%"
            m="0 auto"
            p="5px"
            display="flex"
            justifyContent="center"
            backgroundColor={
              access === "admin"
                ? colors.greenAccent[600]
                : colors.greenAccent[700]
            }
            borderRadius="4px"
          >
            {access === "admin" && <AdminPanelSettingsOutlinedIcon />}
            {access === "manager" && <SecurityOutlinedIcon />}
            {access === "user" && <LockOpenOutlinedIcon />}
            <Typography color={colors.grey[100]} sx={{ ml: "5px" }}>
              {access}
            </Typography>
          </Box>
        );
      },
    },
  ];

  return (
    <Box m="20px">
      <Header title="TEAM" subtitle="Managing the Team Members" />

      <Box>
        <Button
          sx={{
            backgroundColor: colors.blueAccent[700],
            color: colors.grey[100],
            fontSize: "14px",
            fontWeight: "bold",
            padding: "10px 20px",
          }}
          onClick={handleOpen}
        >
          <PersonAddIcon sx={{ mr: "10px" }} />
          Add users
        </Button>
      </Box>

      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4,
          }}
        >
          <Register />
        </Box>
      </Modal>

      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >
        <DataGrid checkboxSelection rows={rows} columns={columns} />
      </Box>
    </Box>
  );
};

export default Users;
