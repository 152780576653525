// import React from 'react';
import React, { useState } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { fetchPostDetails } from "../../services/etude";
import { Button, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { Box } from "@mui/material";
import { ResponsiveLine } from "@nivo/line";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import ImagePopup from "../../components/ImagePopup";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { useNavigate } from "react-router-dom";

function FACADE04({ isCustomLineColors = false, isDashboard = true }) {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [selectedImageUrl, setSelectedImageUrl] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { etudeId } = useParams();
  const navigate = useNavigate();
  const { data, isLoading, isError, error } = useQuery(
    ["postDetails", etudeId],
    () => fetchPostDetails(etudeId)
  );
  const openImageModal = (imageUrl) => {
    setSelectedImageUrl(imageUrl);
    setIsModalOpen(true);
  };

  const closeImageModal = () => {
    setIsModalOpen(false);
  };

  const handleGoBack = () => {
    navigate(-1); // Navigate back to the previous page
  };

  if (isLoading) {
    return <p>Loading...</p>;
  }

  if (isError) {
    return <p>Error: {error.message}</p>;
  }

  if (!data) {
    return null;
  }

  const facadeId1MonthlyResults = data.monthly_results.filter(
    (result) => result.facade_id === 4
  );

  const facadeId1AnnualResults = data.annual_results.filter(
    (result) => result.facade_id === 4
  );

  const facadeId1HourlyResults = data.hourly_results.filter(
    (result) => result.facade_id === 4
  );
  const imageKey = "image_facade_de_batiment_04";
  const filteredImageUrls = data.image_urls[imageKey];

  const convertDataToCSV = (data) => {
    return data.map((row) => Object.values(row).join(",")).join("\n");
  };
  const handleDownloadCSV = (data, fileName) => {
    const csvData = convertDataToCSV(data);
    const blob = new Blob([csvData], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = fileName;
    link.click();
    window.URL.revokeObjectURL(url);
  };

  // Filter and extract relevant data points

  const facadeData = facadeId1AnnualResults[0];

  const dataPoints = facadeData ?

    Object.keys(facadeData)
      .filter((key) => key.startsWith("year"))
      .map((key) => ({
        x: key, // Extract the year number
        y: facadeData[key], // Get the corresponding value
      }))
      .filter((dataPoint) => dataPoint.y !== null && !isNaN(dataPoint.y))
    : [];
  const facadeDataMonthly = facadeId1MonthlyResults[0]; // Get the object from the array
  const dataPointsMonthly = facadeDataMonthly ? Object.keys(facadeDataMonthly)
    .filter(
      (key) =>
        key !== "id" &&
        key !== "facade_id" &&
        key !== "etudes" &&
        key !== "createdAt" &&
        key !== "updatedAt"
    ) // Exclude unwanted keys
    .map((key) => ({
      x: key, // Use the month as the x value
      y: facadeDataMonthly[key], // Get the corresponding value
    }))
    .filter((dataPoint) => !isNaN(dataPoint.y)) // Filter out data points with NaN values
    : [];
  const facadeDataHourly = facadeId1HourlyResults[0]; // Get the object from the array
  const dataPointsHourly = facadeDataHourly ? Object.keys(facadeDataHourly)
    .filter(
      (key) =>
        key !== "id" &&
        key !== "facade_id" &&
        key !== "etudes" &&
        key !== "createdAt" &&
        key !== "updatedAt"
    ) // Exclude unwanted keys
    .map((key) => ({
      x: key, // Use the hour as the x value
      y: facadeDataHourly[key], // Get the corresponding value
    }))
    .filter((dataPoint) => !isNaN(dataPoint.y)) // Filter out data points with NaN values
    : [];
  return (
    <Box m="20px">
      <Header title="Etude Details" subtitle="Details" />
      <div>facade04</div>
      <Box className="three_element">
        <Box
          sx={{ display: "flex", gap: "10px" }}
        >

          <img
            className="image02"
            src={filteredImageUrls}
            alt={imageKey}
            onClick={() => openImageModal(filteredImageUrls)}
          />

          <ImagePopup
            isOpen={isModalOpen}
            imageUrl={selectedImageUrl}
            closeModal={closeImageModal}
          />

          <Button
            sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
            }}
            onClick={() =>
              handleDownloadCSV(facadeId1MonthlyResults, "monthly_results.csv")
            }
          >
            <DownloadOutlinedIcon sx={{ mr: "9px" }} />
            CSV
          </Button>


        </Box>

        <Box className="image-container close-button">
          <Button
            sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],

            }}
            onClick={handleGoBack}
          >
            <ArrowBackIosNewIcon />
          </Button>
        </Box>
      </Box>
      {data?.annual_results && dataPoints.length > 0 && (

        <Box height="40vh">
          <ResponsiveLine
            data={[
              {
                id: "Yearly Data",
                color: "#00a7d3",
                data: dataPoints,
              },
            ]}
            theme={{
              axis: {
                domain: {
                  line: {
                    stroke: colors.grey[100],
                  },
                },
                legend: {
                  text: {
                    fill: colors.grey[100],
                  },
                },
                ticks: {
                  line: {
                    stroke: colors.grey[100],
                    strokeWidth: 1,
                  },
                  text: {
                    fill: colors.grey[100],
                  },
                },
              },
              legends: {
                text: {
                  fill: colors.grey[100],
                },
              },
              tooltip: {
                container: {
                  color: colors.primary[500],
                },
              },
            }}
            colors={isDashboard ? { datum: "color" } : { scheme: "nivo" }}
            margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
            xScale={{ type: "point" }}
            yScale={{
              type: "linear",
              min: "auto",
              max: "auto",
              stacked: true,
              reverse: false,
            }}
            yFormat=" >-.2f"
            curve="catmullRom"
            axisTop={null}
            axisRight={null}
            axisBottom={{
              orient: "bottom",
              tickSize: 0,
              tickPadding: 5,
              tickRotation: 0,
              legend: isDashboard ? undefined : "x",
              legendOffset: 36,
              legendPosition: "middle",
            }}
            axisLeft={{
              orient: "left",
              tickValues: 5,
              tickSize: 3,
              tickPadding: 5,
              tickRotation: 0,
              legend: isDashboard ? undefined : "y",
              legendOffset: -40,
              legendPosition: "middle",
            }}
            enableGridX={false}
            enableGridY={false}
            pointSize={8}
            pointColor={{ theme: "background" }}
            pointBorderWidth={2}
            pointBorderColor={{ from: "serieColor" }}
            pointLabelYOffset={-12}
            useMesh={true}
            legends={[
              {
                anchor: "bottom-right",
                direction: "column",
                justify: false,
                translateX: 100,
                translateY: 0,
                itemsSpacing: 0,
                itemDirection: "left-to-right",
                itemWidth: 80,
                itemHeight: 20,
                itemOpacity: 0.75,
                symbolSize: 12,
                symbolShape: "circle",
                symbolBorderColor: "rgba(0, 0, 0, .5)",
                effects: [
                  {
                    on: "hover",
                    style: {
                      itemBackground: "rgba(0, 0, 0, .03)",
                      itemOpacity: 1,
                    },
                  },
                ],
              },
            ]}
          />
        </Box>
      )}
      {/*  */}
      {data?.monthly_results && dataPointsMonthly.length > 0 && (

        <Box height="40vh">
          <ResponsiveLine
            data={[
              {
                id: "Monthly Data",
                color: "#00a8ac",

                data: dataPointsMonthly,
              },
            ]}
            theme={{
              axis: {
                domain: {
                  line: {
                    stroke: colors.grey[100],
                  },
                },
                legend: {
                  text: {
                    fill: colors.grey[100],
                  },
                },
                ticks: {
                  line: {
                    stroke: colors.grey[100],
                    strokeWidth: 1,
                  },
                  text: {
                    fill: colors.grey[100],
                  },
                },
              },
              legends: {
                text: {
                  fill: colors.grey[100],
                },
              },
              tooltip: {
                container: {
                  color: colors.primary[500],
                },
              },
            }}
            colors={isDashboard ? { datum: "color" } : { scheme: "nivo" }}
            margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
            xScale={{ type: "point" }}
            yScale={{
              type: "linear",
              min: "auto",
              max: "auto",
              stacked: true,
              reverse: false,
            }}
            yFormat=" >-.2f"
            curve="catmullRom"
            axisTop={null}
            axisRight={null}
            axisBottom={{
              orient: "bottom",
              tickSize: 0,
              tickPadding: 5,
              tickRotation: 0,
              legend: isDashboard ? undefined : "month",
              legendOffset: 36,
              legendPosition: "middle",
            }}
            axisLeft={{
              orient: "left",
              tickValues: 5,
              tickSize: 3,
              tickPadding: 5,
              tickRotation: 0,
              legend: isDashboard ? undefined : "value",
              legendOffset: -40,
              legendPosition: "middle",
            }}
            enableGridX={false}
            enableGridY={false}
            pointSize={8}
            pointColor={{ theme: "background" }}
            pointBorderWidth={2}
            pointBorderColor={{ from: "serieColor" }}
            pointLabelYOffset={-12}
            useMesh={true}
            legends={[
              {
                anchor: "bottom-right",
                direction: "column",
                justify: false,
                translateX: 100,
                translateY: 0,
                itemsSpacing: 0,
                itemDirection: "left-to-right",
                itemWidth: 80,
                itemHeight: 20,
                itemOpacity: 0.75,
                symbolSize: 12,
                symbolShape: "circle",
                symbolBorderColor: "rgba(0, 0, 0, .5)",
                effects: [
                  {
                    on: "hover",
                    style: {
                      itemBackground: "rgba(0, 0, 0, .03)",
                      itemOpacity: 1,
                    },
                  },
                ],
              },
            ]}
          />
        </Box>
      )}
      {/*  */}
      {data?.hourly_results && dataPointsHourly.length > 0 && (

        <Box height="40vh">
          <ResponsiveLine
            data={[
              {
                id: "Hourly Data",
                color: "#2559a5",

                data: dataPointsHourly,
              },
            ]}
            theme={{
              axis: {
                domain: {
                  line: {
                    stroke: colors.grey[100],
                  },
                },
                legend: {
                  text: {
                    fill: colors.grey[100],
                  },
                },
                ticks: {
                  line: {
                    stroke: colors.grey[100],
                    strokeWidth: 1,
                  },
                  text: {
                    fill: colors.grey[100],
                  },
                },
              },
              legends: {
                text: {
                  fill: colors.grey[100],
                },
              },
              tooltip: {
                container: {
                  color: colors.primary[500],
                },
              },
            }}
            colors={isDashboard ? { datum: "color" } : { scheme: "nivo" }}
            margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
            xScale={{ type: "point" }}
            yScale={{
              type: "linear",
              min: "auto",
              max: "auto",
              stacked: true,
              reverse: false,
            }}
            yFormat=" >-.2f"
            curve="catmullRom"
            axisTop={null}
            axisRight={null}
            axisBottom={{
              orient: "bottom",
              tickSize: 0,
              tickPadding: 5,
              tickRotation: 0,
              legend: isDashboard ? undefined : "month",
              legendOffset: 36,
              legendPosition: "middle",
            }}
            axisLeft={{
              orient: "left",
              tickValues: 5,
              tickSize: 3,
              tickPadding: 5,
              tickRotation: 0,
              legend: isDashboard ? undefined : "value",
              legendOffset: -40,
              legendPosition: "middle",
            }}
            enableGridX={false}
            enableGridY={false}
            pointSize={8}
            pointColor={{ theme: "background" }}
            pointBorderWidth={2}
            pointBorderColor={{ from: "serieColor" }}
            pointLabelYOffset={-12}
            useMesh={true}
            legends={[
              {
                anchor: "bottom-right",
                direction: "column",
                justify: false,
                translateX: 100,
                translateY: 0,
                itemsSpacing: 0,
                itemDirection: "left-to-right",
                itemWidth: 80,
                itemHeight: 20,
                itemOpacity: 0.75,
                symbolSize: 12,
                symbolShape: "circle",
                symbolBorderColor: "rgba(0, 0, 0, .5)",
                effects: [
                  {
                    on: "hover",
                    style: {
                      itemBackground: "rgba(0, 0, 0, .03)",
                      itemOpacity: 1,
                    },
                  },
                ],
              },
            ]}
          />
        </Box>
      )}
    </Box>
  );
}

export default FACADE04;
