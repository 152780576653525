import React from 'react';
// import React from 'react'
import { Box, Typography,useTheme } from '@mui/material';
import { tokens } from "../theme";
import ProgressCircle from "./ProgressCircle";
const AdditionalDatas = ({ title, subtitle, progress, increase, icon, aggregateValue, customElement }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Box width="100%" m="0 30px">
      <Box display="flex" justifyContent="space-between">
        <Box>
          {icon}
          <Typography variant="h4" fontWeight="bold" sx={{ color: colors.grey[100] }}>
            {aggregateValue}
          </Typography>
          <Typography variant="h5" sx={{ color: colors.greenAccent[500] }}>
            {subtitle}
          </Typography>
        </Box>
        <Box>
          <ProgressCircle progress={progress} aggregateValue={aggregateValue} />
        </Box>
      </Box>
    </Box>
  );
};

export default AdditionalDatas
