// import { toast } from "react-toastify";
// const API_BASE = process.env.REACT_APP_API_URL_PROD || "https://backendstudy.windmyroof.com";
import api from "./api.jsx";

// const api = axios.create({
//   baseURL: API_BASE,
// });
// const userServices = {
  
export const getAllUsers =  async (page) => {
  // get all 
  try {
    const response = await api.get("users/", {
      params: { _page: page, _sort: "title", _limit: 2 },
    });

    const hasNext = page * 2 <= parseInt(response.headers["x-total-count"]);
    return {
      nextPage: hasNext ? page + 1 : undefined,
      previousPage: page > 1 ? page - 1 : undefined,
      posts: response.data.data,
    };
  } catch (error) {
    throw error;
  }
};

export const transferEtudes = async (fromUserId, toUserId) => {
  try {
    const response = await api.post(`transfer_etudes/${fromUserId}/${toUserId}/`);
    return response.data;

  } catch (error) {
    throw error;
  }
};


export const getUserFromLocalStorage = async () => {
  const userString = localStorage.getItem("user");
  return userString ? JSON.parse(userString) : null;
};

export const getEtudByUserId = async (userId) => {
  try {
    // Assuming the correct API endpoint is in the format: /users/{userId}/etudes/
    const response = await api.get(`/users/${userId}/etudes/`);
    return response.data;
  } catch (error) {
    throw error;
  }
};


