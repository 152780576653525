// this is the AuthServices.js in the service
import isAuthapi from "./isAuthapi.jsx";

const userService = {
  login: async (email, password) => {

    const user = { email, password };
    return await isAuthapi.post("/login", user);
    
  },

  getAllUsers: async () => {
    return await isAuthapi.get("/users");
  },

  logout: async () => {
    try {
      await isAuthapi.post("/logout/access").catch((error) => {
      });
      // Clear local storage and update state regardless of backend response 
      localStorage.removeItem("access_token");
      localStorage.removeItem("refresh_token");
    } catch (error) {
      console.error("Unexpected error during logout:", error);
    }
  },


 registerUser : async (formData) => {
    try {
      const response = await isAuthapi.post("/registration", formData, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      return response.data;
    } catch (error) {
      console.error("Registration failed", error);
      throw error;
    }
  },
  getUsers : async () => {
    try {
      const response = await isAuthapi.get('/users');
      return response.data;
    } catch (error) {
      console.error('Error fetching users:', error);
      throw error;
    }
  },
  updateUserCity :  async (userId, cityId) => {
    try {
      const response = await isAuthapi.put('/user/update', {
        user_id: userId,
        city_id: cityId
      });
      return response.data;
    } catch (error) {
      console.error('Error updating user city:', error);
      throw error;
    }
  },
  
};
export default userService;
