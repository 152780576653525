// import React from 'react';
// import { Box, Button, MenuItem, Select, useTheme } from "@mui/material";
// import { DataGrid, GridToolbar } from "@mui/x-data-grid";
// import { tokens } from "../../theme";

// import Header from "../../components/Header";
// import {
//   useInfiniteQuery,
//   useQuery,
//   useMutation,
//   useQueryClient,
// } from "react-query";
// import {
//   getPostsPaginated,
//   getEtudByUserId,
//   getUserFromLocalStorage,
//   updateArchivageStatus,
// } from "../../services/etude";
// import { getAllUsers, transferEtudes } from "../../services/user";

// import DeleteEtudes from "../../components/DeleteEtudes";
// import { Link } from "react-router-dom";
// import { useEffect, useState } from "react";
// import { ToastContainer, toast } from "react-toastify";
// import useMediaQuery from "@mui/material/useMediaQuery";
// const Etude = () => {
//   const theme = useTheme();
//   const colors = tokens(theme.palette.mode);
//   const user = getUserFromLocalStorage();
//   const queryClient = useQueryClient();

//   const [fromUserId, setFromUserId] = useState(null);
//   const [toUserId, setToUserId] = useState(null);
//   const [selectedUserId, setSelectedUserId] = useState(null);
//   const isNonMobile = useMediaQuery("(min-width:600px)");
//   const userrole = JSON.parse(localStorage.getItem("user"));

//   const hasAdminRole = (userrole) => userrole && user.access === "Admin" && "admin";

//   const {
//     status,
//     error,
//     // data,
//     fetchNextPage,
//     hasNextPage,
//     isFetchingNextPage,
//     refetch: refetchPosts,
//   } = useInfiniteQuery({
//     queryKey: ["data", "datainfinite"],
//     getNextPageParam: (prevData) => prevData.nextPage,
//     queryFn: ({ pageParam = 1 }) => getPostsPaginated(pageParam),
//   });

//   const { data: etudes, refetch } = useQuery(
//     ["etudesByUserId", selectedUserId || user?.id],
//     () => getEtudByUserId(selectedUserId || user?.id),
//     { enabled: !!selectedUserId || !!user?.id }
//   );

//   const handleUserChange = async (event) => {
//     const newUserId = event.target.value;
//     setSelectedUserId(newUserId);
//     // Trigger a refetch of data for the new user
//     refetch({
//       queryKey: ["etudesByUserId", newUserId],
//       queryFn: () => getEtudByUserId(newUserId),
//     });
//   };

//   const mutation = useMutation(updateArchivageStatus, {
//     onSuccess: () => {
//       queryClient.invalidateQueries("etudes");
//     },
//     onError: () => {
//       const errorMessage =
//         error.response?.data?.error || "Unknown error occurred";
//       toast.error(errorMessage);
//     },
//   });

//   const handleArchiverClick = (etudeId) => {
//     mutation.mutate({ etudeId, archivageValue: true });
//   };

//   const { refetch: refetchEtudes, data: usersatache } = useInfiniteQuery({
//     queryKey: ["posts", "infinite"],
//     getNextPageParam: (prevData) => prevData.nextPage,
//     queryFn: ({ pageParam = 1 }) => getAllUsers(pageParam),
//   });

//   useEffect(() => {
//     refetch();
//     refetchPosts();
//     refetchEtudes();
//   }, [refetch, refetchEtudes, refetchPosts]);

//   if (status === "loading") return <h1>Loading...</h1>;
//   if (status === "error") return <h1>{JSON.stringify(error)}</h1>;
//   const users = usersatache?.pages[0]?.posts || [];

//   const handleTransferClick = async (fromUserId, toUserId) => {
//     try {
//       const response = await transferEtudes(fromUserId, toUserId);
//       toast.success(response.message, { position: toast.POSITION.TOP_CENTER });
//       refetchEtudes();
//     } catch (error) {
//       toast.warning(error.response.data, {
//         position: toast.POSITION.TOP_CENTER,
//       });
//     }
//   };

//   const handleFromUserChange = (event) => {
//     setFromUserId(event.target.value);
//   };

//   const handleToUserChange = (event) => {
//     setToUserId(event.target.value);
//   };
//   ////////////////////////////:
//   const rows = etudes?.data
//     ? etudes.data.map((etude) => ({
//         id: etude.id,
//         nom_du_site: etude.nom_du_site,
//         nom_du_cient: etude.nom_du_cient,
//         latitude: etude.latitude,
//         longitude: etude.longitude,
//         start_year: etude.start_year,
//         end_year: etude.end_year,
//         adresse: etude.adresse,
//         z_zero: etude.z_zero,
//         bat_hght: etude.bat_hght,
//         has_associated_data: etude.has_associated_data,
//         archivage: etude.archivage,
//       }))
//     : [];

//   const columns = [
//     { field: "id", headerName: "ID", flex: 1 },
//     { field: "nom_du_site", headerName: "Site Name", flex: 1 },
//     { field: "nom_du_cient", headerName: "Client Name", flex: 1 },
//     { field: "latitude", headerName: "Latitude", flex: 1 },
//     { field: "longitude", headerName: "Longitude", flex: 1 },
//     // { field: "start_year", headerName: "start_year", flex: 1 },
//     // { field: "end_year", headerName: "end_year", flex: 1 },
//     { field: "adresse", headerName: "adresse", flex: 1 },
//     { field: "z_zero", headerName: "z_zero", flex: 1 },
//     { field: "bat_hght", headerName: "bat_hght", flex: 1 },
//     {
//       field: "archivage",
//       headerName: "is archivage",
//       flex: 1,
//       renderCell: ({ row }) => (
//         <Box
//           width="100%"
//           color={
//             row.archivage ? colors.greenAccent[500] : colors.redAccent[700]
//           }
//         >
//           {row.archivage ? "Yes" : "No"}
//         </Box>
//       ),
//     },
//     {
//       field: "has_associated_data",
//       headerName: "Has Data",
//       flex: 1,
//       renderCell: ({ row }) => (
//         <Box
//           width="100%"
//           color={
//             row.has_associated_data
//               ? colors.greenAccent[500]
//               : colors.redAccent[700]
//           }
//         >
//           {row.has_associated_data ? "Yes" : "No"}
//         </Box>
//       ),
//     },

//     {
//       field: " Faire l'étude",
//       headerName: " Reaire l'étude",

//       renderCell: ({ row }) => (
//         <Box
//         width="60%"
//         m="0 auto"
//         p="5px"
//         display="flex"
//         justifyContent="center"
//           backgroundColor={colors.blueAccent[600]}
//           borderRadius="4px"
//           cursor="pointer"
//           transition="background-color 0.3s"
//           _hover={{
//             backgroundColor: colors.blueAccent[700],
//           }}
//         >
//           <Link
//             to={`/form?etudeId=${row.id}`}
//             style={{ textDecoration: "none", color: colors.grey[100] }}
//           >
//             redo
//           </Link>
//         </Box>
//       ),
//     },

//     {
//       field: "detail",
//       headerName: "Detail",
//       renderCell: ({ row }) => (
//         <Box
//           // m="0 auto"
//           // p="5px"
//           // display="flex"
//           // justifyContent="center"
//           width="60%"
//           m="0 auto"
//           p="5px"
//           display="flex"
//           justifyContent="center"
//           backgroundColor={colors.blueAccent[600]}
//           // borderRadius="4px"
//           cursor="pointer"
//           transition="background-color 0.3s"
//           _hover={{
//             backgroundColor: colors.blueAccent[700],
//           }}
//         >
//           <Link
//             to={`/details/${row.id}`}
//             style={{ textDecoration: "none", color: colors.grey[100] }}
//           >
//             Details
//           </Link>
//         </Box>
//       ),
//     },

//     {
//       field: "archiver",
//       headerName: "Archiver",
//       renderCell: (params) => (
//         <Box
//           //  width="100%" textAlign="center"
//           width="60%"
//           m="0 auto"
//           p="5px"
//           display="flex"
//           justifyContent="center"
//         >
//           <Button
//             variant="contained"
//             color="primary"
//             onClick={() => {
//               handleArchiverClick(params.row.id, () => {
//                 refetch(); // Refresh the list after successful archiving
//               });
//             }}
//             disabled={mutation.isLoading}
//           >
//             Archiver
//           </Button>
//         </Box>
//       ),
//     },
//     {
//       field: "delete",
//       headerName: "Delete",
//       renderCell: ({ row }) => (
//         <Box
//           width="60%"
//           m="0 auto"
//           p="5px"
//           display="flex"
//           justifyContent="center"
//         >
//           <DeleteEtudes
//             variant="contained"
//             etudeId={row.id}
//             onDeleteSuccess={() => {
//               refetch();
//             }}
//           />
//         </Box>
//       ),
//     },
//   ];

//   return (
//     <Box m="20px">
//       <ToastContainer />

//       <Header title="ETUDES" subtitle="Gérer Les Etudes " />
//       {/*  */}
//       {hasAdminRole(userrole) && (
//         <Box m="5px">
//           <Box
//             display="grid"
//             gap="10px"
//             gridTemplateColumns="repeat(4, minmax(0, 1fr))"
//             sx={{
//               "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
//             }}
//           >
//             <Select
//               value={fromUserId}
//               onChange={handleFromUserChange}
//               displayEmpty
//               variant="filled"
//             >
//               <MenuItem value={null} disabled>
//                 Transférer des études de l'utilisateur sélectionné
//               </MenuItem>
//               {users.map((user) => (
//                 <MenuItem key={user.id} value={user.id}>
//                   {user.last_name} {user.etudes_count}
//                 </MenuItem>
//               ))}
//             </Select>

//             <Select
//               value={toUserId}
//               onChange={handleToUserChange}
//               displayEmpty
//               variant="filled"
//             >
//               <MenuItem value={null} disabled>
//                 Transférer des études à l'utilisateur sélectionné
//               </MenuItem>
//               {users.map((user) => (
//                 <MenuItem key={user.id} value={user.id}>
//                   {user.last_name} {user.etudes_count}
//                 </MenuItem>
//               ))}
//             </Select>

//             <Button
//               variant="contained"
//               color="secondary"
//               disabled={!fromUserId || !toUserId}
//               onClick={() => handleTransferClick(fromUserId, toUserId)}
//             >
//               Transfer Etudes
//             </Button>

//             <Select
//               value={selectedUserId}
//               onChange={handleUserChange}
//               displayEmpty
//               variant="filled"
//             >
//               <MenuItem value={null}>
//                 visionner l'étude d'un utilisateur
//               </MenuItem>
//               {users.map((user) => (
//                 <MenuItem key={user.id} value={user.id}>
//                   {user.last_name}
//                 </MenuItem>
//               ))}
//             </Select>
//           </Box>

//           {hasNextPage && (
//             <Box mt={3}>
//               <Button
//                 variant="contained"
//                 color="primary"
//                 onClick={() => fetchNextPage()}
//                 disabled={isFetchingNextPage}
//               >
//                 {isFetchingNextPage ? "Loading..." : "See More"}
//               </Button>
//             </Box>
//           )}
//         </Box>
//       )}
//       <table className="table table-bordered table-striped"></table>
//       <Box m="20px">
//         {hasNextPage && (
//           <Box mt={3}>
//             <Button
//               variant="contained"
//               color="primary"
//               onClick={() => fetchNextPage()}
//               disabled={isFetchingNextPage}
//             >
//               {isFetchingNextPage ? "Loading..." : "See More"}
//             </Button>
//           </Box>
//         )}
//       </Box>

//       {/*  */}
//       <Box
//         m="40px 0 0 0"
//         height="68vh"
//         sx={{
//           "& .MuiDataGrid-root": {
//             border: "none",
//           },
//           "& .MuiDataGrid-cell": {
//             borderBottom: "none",
//           },
//           "& .name-column--cell": {
//             color: colors.greenAccent[300],
//           },
//           "& .MuiDataGrid-columnHeaders": {
//             backgroundColor: colors.blueAccent[700],
//             borderBottom: "none",
//           },
//           "& .MuiDataGrid-virtualScroller": {
//             backgroundColor: colors.primary[400],
//           },
//           "& .MuiDataGrid-footerContainer": {
//             borderTop: "none",
//             backgroundColor: colors.blueAccent[700],
//           },
//           "& .MuiCheckbox-root": {
//             color: `${colors.greenAccent[200]} !important`,
//           },
//           "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
//             color: `${colors.grey[100]} !important`,
//           },
//         }}
//       >
//         <DataGrid
//           checkboxSelection
//           rows={rows}
//           columns={columns}
//           components={{ Toolbar: GridToolbar }}
//         />
//       </Box>

//       {hasNextPage && (
//         <button
//           className="btn btn-primary"
//           onClick={() => fetchNextPage()}
//           disabled={isFetchingNextPage}
//         >
//           {isFetchingNextPage ? "Loading..." : "See More"}
//         </button>
//       )}
//     </Box>
//   );
// };

// export default Etude;

import React from 'react';
import { Box, Button, MenuItem, Select, useTheme } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";

import Header from "../../components/Header";
import {
  useInfiniteQuery,
  useQuery,
  useMutation,
  useQueryClient,
} from "react-query";
import {
  getPostsPaginated,
  getEtudByUserId,
  getUserFromLocalStorage,
  updateArchivageStatus,
} from "../../services/etude";
import { getAllUsers, transferEtudes } from "../../services/user";

import DeleteEtudes from "../../components/DeleteEtudes";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import useMediaQuery from "@mui/material/useMediaQuery";
const Etude = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const user = getUserFromLocalStorage();
  const queryClient = useQueryClient();

  const [fromUserId, setFromUserId] = useState(null);
  const [toUserId, setToUserId] = useState(null);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const userrole = JSON.parse(localStorage.getItem("user"));

  const hasAdminRole = (userrole) => userrole && user.access === "Admin" && "admin";

  const {
    status,
    error,
    // data,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    refetch: refetchPosts,
  } = useInfiniteQuery({
    queryKey: ["data", "datainfinite"],
    getNextPageParam: (prevData) => prevData.nextPage,
    queryFn: ({ pageParam = 1 }) => getPostsPaginated(pageParam),
  });

  const { data: etudes, refetch } = useQuery(
    ["etudesByUserId", selectedUserId || user?.id],
    () => getEtudByUserId(selectedUserId || user?.id),
    { enabled: !!selectedUserId || !!user?.id }
  );

  const handleUserChange = async (event) => {
    const newUserId = event.target.value;
    setSelectedUserId(newUserId);
    // Trigger a refetch of data for the new user
    refetch({
      queryKey: ["etudesByUserId", newUserId],
      queryFn: () => getEtudByUserId(newUserId),
    });
  };

  const mutation = useMutation(updateArchivageStatus, {
    onSuccess: () => {
      queryClient.invalidateQueries("etudes");
    },
    onError: () => {
      const errorMessage =
        error.response?.data?.error || "Unknown error occurred";
      toast.error(errorMessage);
    },
  });

  const handleArchiverClick = (etudeId) => {
    mutation.mutate({ etudeId, archivageValue: true });
  };

  const { refetch: refetchEtudes, data: usersatache } = useInfiniteQuery({
    queryKey: ["posts", "infinite"],
    getNextPageParam: (prevData) => prevData.nextPage,
    queryFn: ({ pageParam = 1 }) => getAllUsers(pageParam),
  });

  useEffect(() => {
    refetch();
    refetchPosts();
    refetchEtudes();
  }, [refetch, refetchEtudes, refetchPosts]);

  if (status === "loading") return <h1>Loading...</h1>;
  if (status === "error") return <h1>{JSON.stringify(error)}</h1>;
  const users = usersatache?.pages[0]?.posts || [];

  const handleTransferClick = async (fromUserId, toUserId) => {
    try {
      const response = await transferEtudes(fromUserId, toUserId);
      toast.success(response.message, { position: toast.POSITION.TOP_CENTER });
      refetchEtudes();
    } catch (error) {
      toast.warning(error.response.data, {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  const handleFromUserChange = (event) => {
    setFromUserId(event.target.value);
  };

  const handleToUserChange = (event) => {
    setToUserId(event.target.value);
  };
  ////////////////////////////:
  const rows = etudes?.data
    ? etudes.data.map((etude) => ({
        id: etude.id,
        nom_du_site: etude.nom_du_site,
        nom_du_cient: etude.nom_du_cient,
        latitude: etude.latitude,
        longitude: etude.longitude,
        start_year: etude.start_year,
        end_year: etude.end_year,
        adresse: etude.adresse,
        z_zero: etude.z_zero,
        bat_hght: etude.bat_hght,
        has_associated_data: etude.has_associated_data,
        archivage: etude.archivage,
      }))
    : [];

  const columns = [
    { field: "id", headerName: "ID", flex: 1 },
    { field: "nom_du_site", headerName: "Site Name", flex: 1 },
    { field: "nom_du_cient", headerName: "Client Name", flex: 1 },
    { field: "latitude", headerName: "Latitude", flex: 1 },
    { field: "longitude", headerName: "Longitude", flex: 1 },
    // { field: "start_year", headerName: "start_year", flex: 1 },
    // { field: "end_year", headerName: "end_year", flex: 1 },
    { field: "adresse", headerName: "adresse", flex: 1 },
    { field: "z_zero", headerName: "z_zero", flex: 1 },
    { field: "bat_hght", headerName: "bat_hght", flex: 1 },
    {
      field: "archivage",
      headerName: "is archivage",
      flex: 1,
      renderCell: ({ row }) => (
        <Box
          width="100%"
          color={
            row.archivage ? colors.greenAccent[500] : colors.redAccent[700]
          }
        >
          {row.archivage ? "Yes" : "No"}
        </Box>
      ),
    },
    {
      field: "has_associated_data",
      headerName: "Has Data",
      flex: 1,
      renderCell: ({ row }) => (
        <Box
          width="100%"
          color={
            row.has_associated_data
              ? colors.greenAccent[500]
              : colors.redAccent[700]
          }
        >
          {row.has_associated_data ? "Yes" : "No"}
        </Box>
      ),
    },

    {
      field: " Faire l'étude",
      headerName: " Reaire l'étude",

      renderCell: ({ row }) => (
        <Box
          width="60%"
          m="0 auto"
          p="5px"
          display="flex"
          justifyContent="center"
        >
          <Button
            variant="contained"
            style={{
              backgroundColor: colors.blueAccent[600],
              color: colors.grey[100],
              width: '100%',
              textAlign: 'center',
              borderRadius: '4px'
            }}
          >
            <Link
              to={`/form?etudeId=${row.id}`}
              style={{ textDecoration: "none", color: colors.grey[100] }}
            >
              redo
            </Link>
          </Button>
        </Box>
      ),
    },

    {
      field: "detail",
      headerName: "Detail",
      renderCell: ({ row }) => (
        <Box
          width="60%"
          m="0 auto"
          p="5px"
          display="flex"
          justifyContent="center"
        >
          <Button
            variant="contained"
            style={{
              backgroundColor: colors.blueAccent[600],
              color: colors.grey[100],
              width: '100%',
              textAlign: 'center',
              borderRadius: '4px'
            }}
          >
            <Link
              to={`/details/${row.id}`}
              style={{ textDecoration: "none", color: colors.grey[100] }}
            >
              Details
            </Link>
          </Button>
        </Box>
      ),
    },

    {
      field: "archiver",
      headerName: "Archiver",
      renderCell: (params) => (
        <Box
          width="60%"
          m="0 auto"
          p="5px"
          display="flex"
          justifyContent="center"
        >
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              handleArchiverClick(params.row.id, () => {
                refetch(); // Refresh the list after successful archiving
              });
            }}
            disabled={mutation.isLoading}
            style={{
              width: '100%',
              textAlign: 'center',
            }}
          >
            Archiver
          </Button>
        </Box>
      ),
    },
    {
      field: "delete",
      headerName: "Delete",
      renderCell: ({ row }) => (
        <Box
          width="60%"
          m="0 auto"
          p="5px"
          display="flex"
          justifyContent="center"
        >
          <DeleteEtudes
            variant="contained"
            etudeId={row.id}
            onDeleteSuccess={() => {
              refetch();
            }}
            style={{
              width: '100%',
              textAlign: 'center',
            }}
          />
        </Box>
      ),
    },
  ];

  return (
    <Box m="20px">
      <ToastContainer />

      <Header title="ETUDES" subtitle="Gérer Les Etudes " />
      {/*  */}
      {hasAdminRole(userrole) && (
        <Box m="5px">
          <Box
            display="grid"
            gap="10px"
            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
            sx={{
              "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
            }}
          >
            <Select
              value={fromUserId}
              onChange={handleFromUserChange}
              displayEmpty
              variant="filled"
            >
              <MenuItem value={null} disabled>
                Transférer des études de l'utilisateur sélectionné
              </MenuItem>
              {users.map((user) => (
                <MenuItem key={user.id} value={user.id}>
                  {user.last_name} {user.etudes_count}
                </MenuItem>
              ))}
            </Select>

            <Select
              value={toUserId}
              onChange={handleToUserChange}
              displayEmpty
              variant="filled"
            >
              <MenuItem value={null} disabled>
                Transférer des études à l'utilisateur sélectionné
              </MenuItem>
              {users.map((user) => (
                <MenuItem key={user.id} value={user.id}>
                  {user.last_name} {user.etudes_count}
                </MenuItem>
              ))}
            </Select>

            <Button
              variant="contained"
              color="secondary"
              disabled={!fromUserId || !toUserId}
              onClick={() => handleTransferClick(fromUserId, toUserId)}
            >
              Transfer Etudes
            </Button>

            <Select
              value={selectedUserId}
              onChange={handleUserChange}
              displayEmpty
              variant="filled"
            >
              <MenuItem value={null}>
                visionner l'étude d'un utilisateur
              </MenuItem>
              {users.map((user) => (
                <MenuItem key={user.id} value={user.id}>
                  {user.last_name}
                </MenuItem>
              ))}
            </Select>
          </Box>

          {hasNextPage && (
            <Box mt={3}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => fetchNextPage()}
                disabled={isFetchingNextPage}
              >
                {isFetchingNextPage ? "Loading..." : "See More"}
              </Button>
            </Box>
          )}
        </Box>
      )}
      <table className="table table-bordered table-striped"></table>
      <Box m="20px">
        {hasNextPage && (
          <Box mt={3}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => fetchNextPage()}
              disabled={isFetchingNextPage}
            >
              {isFetchingNextPage ? "Loading..." : "See More"}
            </Button>
          </Box>
        )}
      </Box>

      {/*  */}
      <Box
        m="40px 0 0 0"
        height="68vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <DataGrid
          checkboxSelection
          rows={rows}
          columns={columns}
          components={{ Toolbar: GridToolbar }}
        />
      </Box>

      {hasNextPage && (
        <button
          className="btn btn-primary"
          onClick={() => fetchNextPage()}
          disabled={isFetchingNextPage}
        >
          {isFetchingNextPage ? "Loading..." : "See More"}
        </button>
      )}
    </Box>
  );
};

export default Etude;

