// import React from 'react';
// import { useMutation, useQueryClient } from 'react-query';
// import { deleteEtude } from '../services/etude';
// import { Box, useTheme } from '@mui/material';
// import { tokens } from '../theme';

// const DeleteEtudes = ({ etudeId,onDeleteSuccess }) => {
//   const theme = useTheme();
//   const colors = tokens(theme.palette.mode);
//   const queryClient = useQueryClient();

//   const deleteMutation = useMutation(() => deleteEtude(etudeId), {
//     onSuccess: () => {
//       // Call onDeleteSuccess to refetch data
//       if (onDeleteSuccess) {
//         onDeleteSuccess();
//       }
//       // Refetch the data
//       queryClient.invalidateQueries(['data', 'datainfinite']);
//     },
//   });
  

//   const handleDelete = () => {
//     // Add a confirmation before deleting
//     if (window.confirm('Are you sure you want to delete this etude?')) {
//         deleteMutation.mutate(etudeId, {
//             onSuccess: () => {
//                 // Refetch the data
//                 queryClient.invalidateQueries(['posts', 'infinite']);
//             },
//             onError: (error) => {
//                 // Handle the error case
//                 alert('Error deleting etude: ' + error.message);
//             },
//         });
//     }
//   };

// //   return (
// //     <Box
// //       // ... other props
// //       onClick={handleDelete}
// //       // ... other props
// //     >
// //       {deleteMutation.isLoading ? 'Deleting...' : 'Delete'}
// //     </Box>
// //   );
// // };



//   return (
//     <Box
//       className="btn btn-danger"
//       onClick={handleDelete}
//       disabled={deleteMutation.isLoading}
//       // width="100%"
//       // m="0 auto"
//       // p="5px"
//       // display="flex"
//       justifyContent="center"
//       backgroundColor={colors.redAccent[600]}
//       // borderRadius="4px"
//       cursor="pointer"
//       transition="background-color 0.3s"
//       _hover={{
//         backgroundColor: colors.redAccent[700],
//       }}
//     >
//       {deleteMutation.isLoading ? 'Deleting...' : 'Delete'}
//     </Box>
//   );
// };

// export default DeleteEtudes;
import React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { deleteEtude } from '../services/etude';
import { Box, useTheme } from '@mui/material';
import { tokens } from '../theme';

const DeleteEtudes = ({ etudeId, onDeleteSuccess }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const queryClient = useQueryClient();

  const deleteMutation = useMutation(() => deleteEtude(etudeId), {
    onSuccess: () => {
      // Call onDeleteSuccess to refetch data
      if (onDeleteSuccess) {
        onDeleteSuccess();
      }
      // Refetch the data
      queryClient.invalidateQueries(['data', 'datainfinite']);
    },
  });

  const handleDelete = () => {
    // Add a confirmation before deleting
    if (window.confirm('Are you sure you want to delete this etude?')) {
      deleteMutation.mutate(etudeId, {
        onSuccess: () => {
          // Refetch the data
          queryClient.invalidateQueries(['posts', 'infinite']);
        },
        onError: (error) => {
          // Handle the error case
          alert('Error deleting etude: ' + error.message);
        },
      });
    }
  };

  return (
    <Box
      onClick={handleDelete}
      disabled={deleteMutation.isLoading}
      className="btn btn-danger"
      justifyContent="center"
      backgroundColor={colors.redAccent[600]}
      cursor="pointer"
      transition="background-color 0.3s"
      _hover={{
        backgroundColor: colors.redAccent[700],
      }}
    >
      {deleteMutation.isLoading ? 'Deleting...' : 'Delete'}
    </Box>
  );
};

export default DeleteEtudes;
