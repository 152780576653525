import React from "react";
import { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Topbar from "./components/global/Topbar";
import Sidebars from "./components/global/Sidebar";
import Dashboard from "./pages/dashboard";
import Etude from "./pages/etudes";
import Invoices from "./pages/invoices";
import Users from "./pages/users";
import Bar from "./pages/bar";
import Form from "./pages/form";
import Line from "./pages/line";
import Pie from "./pages/pie";
import FAQ from "./pages/faq";
import FACADE01 from "./pages/facade_de_batiment_01";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import Calendar from "./pages/calendar/calendar";
import SignIn from "./pages/login/SignIn";
import FACADE02 from "./pages/facade_de_batiment_02";
import FACADE03 from "./pages/facade_de_batiment_03";
import FACADE04 from "./pages/facade_de_batiment_04";
import { AuthProvider } from "./services/AuthContext";
import { isTokenExpired } from "./utils/auth";
import ContactForm from "./pages/ContactForm/ContactForm";
import Territorial from "./pages/territorial";
import CENTRAL from "./pages/central";
import 'react-toastify/dist/ReactToastify.css';
import "./assets/toastStyles.css"; 
function App() {
  const [theme, colorMode] = useMode();
  const [isSidebar, setIsSidebar] = useState(false);
  const [isConnected, setIsConnected] = useState(false);

  // const isConnected = true;
  // const [isLightTheme, setIsLightTheme] = useState(true); // Initialize isLightTheme state
  // useEffect(() => {
  //   const token = localStorage.getItem("access_token");
  //   setIsConnected(!!token); // !! converts truthy/falsy value to boolean

  // }, []);
  useEffect(() => {
    const token = localStorage.getItem("access_token");
    const expired = isTokenExpired(token);
    setIsConnected(token && !expired);
  }, []);

  return (
    <AuthProvider>
      <ColorModeContext.Provider value={colorMode}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <div className="app">
            <ToastContainer />
            {isConnected && <Sidebars isSidebar={isSidebar} />}
            <main className="content">
              {isConnected && <Topbar setIsSidebar={setIsSidebar} />}
              <Routes>
                <Route
                  path="/"
                  element={isConnected ? <Dashboard /> : <SignIn />}
                />
                <Route
                  path="/form"
                  element={isConnected ? <Form /> : <SignIn />}
                />
                <Route
                  path="/etude"
                  element={isConnected ? <Etude /> : <SignIn />}
                />
                <Route
                  path="/calendar"
                  element={isConnected ? <Calendar /> : <SignIn />}
                />
                <Route
                  path="/users"
                  element={isConnected ? <Users /> : <SignIn />}
                />
                <Route
                  path="/faq"
                  element={isConnected ? <FAQ /> : <SignIn />}
                />
                <Route
                  path="/line"
                  element={isConnected ? <Line /> : <SignIn />}
                />
                <Route
                  path="/bar"
                  element={isConnected ? <Bar /> : <SignIn />}
                />
                <Route
                  path="/pie"
                  element={isConnected ? <Pie /> : <SignIn />}
                />
                <Route
                  path="/pie"
                  element={isConnected ? <Pie /> : <SignIn />}
                />
                <Route
                  path="/facade01/:etudeId"
                  element={isConnected ? <FACADE01 /> : <SignIn />}
                />
                <Route
                  path="/facade02/:etudeId"
                  element={isConnected ? <FACADE02 /> : <SignIn />}
                />
                <Route
                  path="/facade03/:etudeId"
                  element={isConnected ? <FACADE03 /> : <SignIn />}
                />
                <Route
                  path="/facade04/:etudeId"
                  element={isConnected ? <FACADE04 /> : <SignIn />}
                />
                <Route
                  path="/details/:etudeId"
                  element={isConnected ? <Invoices /> : <SignIn />}
                />
                <Route
                  path="/territorial"
                  element={isConnected ? <Territorial /> : <SignIn />}
                />
                <Route path="/contact" element={<ContactForm />} />
                <Route
                  path="/applications"
                  element={isConnected ? <CENTRAL /> : <SignIn />}
                />
              </Routes>
            </main>
          </div>
        </ThemeProvider>
      </ColorModeContext.Provider>
    </AuthProvider>
  );
}

export default App;
