// import React from 'react';
import React, { useState, useEffect } from 'react';
import authService from '../services/AuthService';
import { getCitiesUpdateUserCity } from '../services/cityService';
import { Box, Button, Container, Select, MenuItem, Typography, useTheme, InputLabel, FormControl, Grid } from '@mui/material';
import { tokens } from '../theme';
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const UpdateUserCity = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [users, setUsers] = useState([]);
  const [cities, setCities] = useState([]);
  const [selectedUser, setSelectedUser] = useState('');
  const [selectedCity, setSelectedCity] = useState('');

  useEffect(() => {
    const fetchUsersAndCities = async () => {
      try {
        const usersResponse = await authService.getUsers();
        const usersData = usersResponse.users || [];
        setUsers(Array.isArray(usersData) ? usersData : []);

        const citiesData = await getCitiesUpdateUserCity();
        setCities(Array.isArray(citiesData) ? citiesData : []);
      } catch (error) {
        console.error('Error fetching users or cities:', error);
      }
    };

    fetchUsersAndCities();
  }, []);

  const handleUpdate = async () => {
    if (selectedUser && selectedCity) {
      try {
        const response = await authService.updateUserCity(selectedUser, selectedCity);
        toast.success(response.message, { position: "top-center" });
      } catch (error) {
        // console.error('Error updating user city:', error);
        toast.error(error.message, { position: "top-center" });
      }
    }
  };

  return (
    <Container component="main" maxWidth="lg">
       <ToastContainer />
      <Box
        sx={{
          marginTop: 3,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          backgroundColor: colors.primary[400],
          padding: theme.spacing(4),
          borderRadius: 2,
          boxShadow: 3,
          width: '100%',
        }}
      >
        <Typography component="h1" variant="h5" color={colors.grey[100]} mb={2}>
          Update User City
        </Typography>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} sm={5}>
            <FormControl fullWidth>
              <InputLabel sx={{ color: colors.grey[100] }}>Select User</InputLabel>
              <Select
                value={selectedUser}
                onChange={(e) => setSelectedUser(e.target.value)}
                sx={{ color: colors.grey[100], '.MuiOutlinedInput-notchedOutline': { borderColor: colors.grey[100] } }}
              >
                <MenuItem value="">
                  <em>Select a user</em>
                </MenuItem>
                {users.map(user => (
                  <MenuItem key={user.id} value={user.id}>{user.username}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={5}>
            <FormControl fullWidth>
              <InputLabel sx={{ color: colors.grey[100] }}>Select City</InputLabel>
              <Select
                value={selectedCity}
                onChange={(e) => setSelectedCity(e.target.value)}
                sx={{ color: colors.grey[100], '.MuiOutlinedInput-notchedOutline': { borderColor: colors.grey[100] } }}
              >
                <MenuItem value="">
                  <em>Select a city</em>
                </MenuItem>
                {cities.map(city => (
                  <MenuItem key={city.id} value={city.id}>{city.city_name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Button
              onClick={handleUpdate}
              variant="contained"
              color="secondary"
              sx={{
                backgroundColor: colors.blueAccent[700],
                color: colors.grey[100],
                height: '100%',
                width: '100%',
              }}
            >
              Update
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default UpdateUserCity;
