import React from 'react';
import { Box, useTheme } from "@mui/material";
import { tokens } from "../theme";

const ProgressCircle = ({ progress = "0", size = "40", aggregateValue }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  // Normalize progress value to be a fraction of 1
  // Assuming progress is received as a percentage out of 100
  // If your progress is already between 0 and 1, you can skip this division
  const normalizedProgress = Number(progress) / 100; // Ensure this is a number and normalize

  // Clamp the angle to not exceed 360 degrees
  const angle = Math.min(normalizedProgress * 360, 360);


  return (
    <Box
      sx={{
        background: `radial-gradient(${colors.primary[400]} 55%, transparent 56%),
            conic-gradient(transparent 0deg ${angle}deg, ${colors.blueAccent[500]} ${angle}deg 360deg),
            ${colors.greenAccent[500]}`,
        borderRadius: "50%",
        width: `${size}px`,
        height: `${size}px`,
      }}
    />
  );
};

export default ProgressCircle;
