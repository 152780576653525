import React from 'react';
import { Box } from "@mui/material";
import Header from "../../components/Header";
import LineChart from "../../components/LineChart";
import { useInfiniteQuery } from "react-query";
import { getPostsPaginated } from "../../services/etude";


const Line = () => {
  const { status, error, data } = useInfiniteQuery({
    queryKey: ["data", "datainfinite"],
    getNextPageParam: (prevData) => prevData.nextPage,
    queryFn: ({ pageParam = 1 }) => getPostsPaginated(pageParam),
  });


  let monthlyCounts = {};
  let yearlyCounts = {};

  if (status === "success" && data && data.pages) {

    data.pages.flat().forEach((etude) => {
      etude.posts.forEach(post => {
        if (post.createdAt) {
          const createdAtDate = new Date(post.createdAt);
          const monthYear = `${createdAtDate.getMonth() + 1}/${createdAtDate.getFullYear()}`;
          const year = createdAtDate.getFullYear();
          monthlyCounts[monthYear] = (monthlyCounts[monthYear] || 0) + 1;

          yearlyCounts[year] = (yearlyCounts[year] || 0) + 1;
        } else {
          console.error("Invalid createdAt value in post:", post);
        }
      });
    });
  }

  if (status === "loading") return <h1>Loading...</h1>;
  if (status === "error") return <h1>{JSON.stringify(error)}</h1>;
  return (
    <Box m="20px">
      <Header title="Line Chart" subtitle="Totale Etudes Effectué Line Chart" />
      <Box
        display='flex'
        height="70vh"

      >
        <LineChart monthlyData={monthlyCounts} yearlyData={yearlyCounts} />
      </Box>
    </Box>
  );
};

export default Line;
