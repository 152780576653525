import React from 'react';
import Modal from 'react-modal';
import './ImagePopup.css'; // Import a CSS file for custom styles

const modalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    transform: 'translate(-50%, -50%)',
    padding: '0',
    border: 'none',
    background: 'transparent',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center', // Center items horizontally
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
};

const ImagePopup = ({ isOpen, imageUrl, closeModal }) => {
 

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      contentLabel="Image Modal"
      style={modalStyles}
    >
      <button className="close-button" onClick={closeModal}
       style={{
        // backgroundColor: colors.blueAccent[700],
        // color: colors.grey[100],
        fontSize: "14px",
        fontWeight: "bold",
        padding: "10px 20px",
        alignSelf: "flex-end", // Align the button to the right
        marginRight: "10px", // Add margin to separate it from the edge
      }} >
        X
      </button>
      <img
        src={imageUrl}
        alt="Images"
        style={{ maxWidth: '100%', maxHeight: '80vh', objectFit: 'contain' }}
      />
    </Modal>
  );
};

export default ImagePopup;
