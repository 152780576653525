// import React from 'react';
import React from "react";
import { useQuery } from "react-query";
import { fetchPostDetails } from "../services/etude"; // Import your fetchPostDetails function
import { Box, useTheme, Typography } from "@mui/material";
import ProgressCircle from "./ProgressCircle";
import { tokens } from "../theme";


const PostDetails = ({ etudeId, icon, progress }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { data, isLoading, isError, error } = useQuery(
    ["postDetails", etudeId],
    () => fetchPostDetails(etudeId)
  );
  if (isLoading) {
    return <p>Loading..</p>;
  }

  if (isError) {
    return <p>Error: {error.message}</p>;
  }

  if (!data) {
    return null; 

  }
  const filterNecessaryProperties = (obj) => {
    const necessaryProperties = [
      "nom_du_site",
      "nom_du_cient",
      "latitude",
      "longitude",
      "start_year",
      "end_year",
      "adresse",
    ];
    return Object.fromEntries(
      Object.entries(obj).filter(([key]) => necessaryProperties.includes(key))
    );
  };
  const necessaryEtudeData = filterNecessaryProperties(data.etude_data);


  return (
    <Box width="100%" m="0 30px">
      <Box display="flex" 
      justifyContent="space-between"
      >
        <Box>
          {icon}
          <Typography
            variant="h4"
            fontWeight="bold"
            sx={{ color: colors.grey[100] }}
          >
            {data.etudeId}
          </Typography>
        </Box>
        <Box>
          <ProgressCircle progress={progress} />
        </Box>
      </Box>
      <Box display="flex" justifyContent="space-between" mt="2px">
        <Typography variant="h5" sx={{ color: colors.greenAccent[500] }}>
          {necessaryEtudeData.nom_du_site}
        </Typography>
        <Typography
          variant="h5"
          fontStyle="italic"
          sx={{ color: colors.greenAccent[600] }}
        >
          {necessaryEtudeData.adresse}
        </Typography>
      </Box>      
    </Box>
  );
};

export default PostDetails;
