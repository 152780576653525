// import React from 'react';
import React from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";

const StudiesMap = ({ boundingBoxes }) => {

  // Définir une position de carte initiale
  const position = [48.8566, 2.3522]; // Coordonnées de Paris, exemple par défaut

  return (
    <MapContainer
      center={position}
      zoom={5}
      scrollWheelZoom={false}
      attributionControl={false}
      style={{ height: "615px", width: "204%" }}
    >
      <TileLayer   attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors' url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
      {boundingBoxes.map((box) => (
        <Marker key={box.id} position={[box.data.lat_min, box.data.lon_min]}>
          <Popup>
            Total études:  {box.data.total_etudes}
            <br />
            Adresse:  {box.data.adresse}
            <br />
            Latitude: {box.data.lat_min}, Longitude: {box.data.lon_min}
          </Popup>
        </Marker>
      ))}
    </MapContainer>
  );
};

export default StudiesMap;
